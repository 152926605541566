import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRole } from '../../../features/role/role.slice';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import Checkbox from '../../ui/forms/fields/checkbox';
import Select from '../../ui/forms/fields/select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { Paper } from '@mui/material';
import { useTitle } from '../../../hooks/titleProvider';

//Create Role Page
const CreateRole = () => {
  const dispatch = useDispatch();
  const { setTitle } = useTitle();

  setTitle('Create Role');

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
    level: yup.number().required('Level is required'),
    force2FA: yup.boolean(),
    hidden: yup.boolean(),
    });

  const { register, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    dispatch(createRole(data));
  };

  return (
    <Paper sx={{p:3}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
                label="Name"
                error={errors.name}
                {...register('name')}
            />
            <Input
                label="Description"
                error={errors.description}
                {...register('description')}
            />
            <Select name="level" label="Level" control={control} error={errors.level} >
                <MenuItem value={1}>System Level</MenuItem>
                <MenuItem value={2}>Entity Level</MenuItem>
                <MenuItem value={3}>Advisor Level</MenuItem>
                <MenuItem value={4}>User Level</MenuItem>
            </Select>
            <Checkbox
                name="force2FA"
                label="Force 2FA"
                error={errors.force2FA}
                control={control}
            />
            <Checkbox
                name="hidden"
                label="Hidden"
                error={errors.hidden}
                control={control}
            />

            <PrimaryButton>Create Role</PrimaryButton>
        </Form>
    </Paper>
  );
};

export default CreateRole;