import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fundFilterService } from './fundFilter.service';

const initialState = {};

export const createFundFilterAsync = createAsyncThunk(
  '/fundFilter/createAsync',
  async (fundFilterData, thunkAPI) => {
    try {
      const response = await fundFilterService.create(fundFilterData);
      return response.data.fundFilterer;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateFundFilterAsync = createAsyncThunk(
  '/fundFilter/updateAsync',
  async (fundFilterData, thunkAPI) => {
    try {
      const response = await fundFilterService.update(fundFilterData);
      return response.data.fundFilterer;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadPdf = createAsyncThunk('/fundFilter/downloadPdf', async (id, thunkAPI) => {
  try {
    await fundFilterService.downloadPdf(id);
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data || 'Error downloading PDF');
  }
});

export const fundFilterSlice = createSlice({
  name: 'fundFilter',
  initialState,
  reducers: {
    createFundFilter: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateFundFilter: (state, action) => {
      return { ...state, ...action.payload, updating: false };
    },
    resetFundFilter: (state) => {
      return {};
    }
  },
  extraReducers: {
    [createFundFilterAsync.fulfilled]: (state, action) => {
      return { loading: false, ...action.payload };
    },
    [createFundFilterAsync.pending]: (state) => {
      return { loading: true };
    },
    [createFundFilterAsync.rejected]: (state, action) => {
      return { loading: false, error: action.payload };
    },

    [downloadPdf.fulfilled]: (state) => {
      return { ...state, loading: false };
    },
    [downloadPdf.pending]: (state) => {
      return { ...state, loading: true };
    },
    [downloadPdf.rejected]: (state, action) => {
      return { ...state, loading: false, error: action.payload };
    },

    [updateFundFilterAsync.fulfilled]: (state, action) => {
      return { loading: false, updating: false, ...action.payload };
    },
    [updateFundFilterAsync.pending]: (state) => {
      return { updating: true, loading: true };
    },
    [updateFundFilterAsync.rejected]: (state, action) => {
      return { loading: false, updating: false, error: action.payload };
    }
  }
});

export const { createFundFilter, updateFundFilter, resetFundFilter } = fundFilterSlice.actions;

export default fundFilterSlice.reducer;
