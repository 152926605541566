import React, { Fragment, useState } from 'react';
import FDialog from '../../ui/fairtreeComponents/dialog/fDialog';
import { DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import OrangeCircularProgress from '../../ui/controls/circularProgress';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import PrimarySelect from '../../ui/forms/fields/select';
import PrimaryButton from '../../ui/buttons/primaryButton';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../redux/actions';
import { Box } from '@mui/system';

const schema = yup.object().shape({
  name: yup.string().required('Please provide a company name.'),
  registeredName: yup.string().required('Please provide a the registered name.'),
  registrationNumber: yup.string().required('Please provide the registration number.'),
  address1: yup.string().required('Please provide a address.'),
  address2: yup.string(),
  suburb: yup.string(),
  city: yup.string().required('Please provide a city/town name.'),
  postalCode: yup.string().required('Please provide a postal code.'),
  country: yup.string().required('Please provide a country.'),
  contactPrimaryNumber: yup
    .string()
    .required("Please provide the primary contact person's number."),
  contactSecondaryNumber: yup.string(),
  contactEmailAddress: yup
    .string()
    .required("Please provide the primary contact person's email address."),
  entityType: yup.string().required('Select a entity type.'),
  entitySystemType: yup.string().required('Select a system entity type.')
});

const AddUpdateDialog = ({ entity }) => {
  const loading = useSelector((state) => state.entity.loading);
  const [formType, setFormType] = useState(entity ? 'Update' : 'Add');
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    data.physicalAddress = {
      address1: data.address1,
      address2: data.address2,
      suburb: data.suburb,
      city: data.city,
      country: data.country,
      postalCode: data.postalCode
    };

    if (formType === 'Add') {
      dispatch(entityActions.create(data));
    } else {
      dispatch(entityActions.update(entity._id, data));
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <PrimaryButton fullWidth={false} onClick={handleOpen}>
        {formType === 'Add' ? 'Add new entity' : 'Update'}
      </PrimaryButton>

      <FDialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{formType + ' Entity'}</DialogTitle>
        <DialogContent>
          {loading ? (
            <OrangeCircularProgress caption="Loading..." />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Input
                    id="name"
                    label="Name"
                    {...register('name')}
                    autoComplete="name"
                    defaultValue={entity?.name}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                  />
                  <Input
                    id="registeredName"
                    label="Registered Name"
                    {...register('registeredName')}
                    autoComplete="registeredName"
                    defaultValue={entity?.registeredName}
                    error={!!errors.registeredName}
                    helperText={errors?.registeredName?.message}
                  />
                  <Input
                    id="registrationNumber"
                    label="Registration Number"
                    {...register('registrationNumber')}
                    autoComplete="registrationNumber"
                    defaultValue={entity?.registrationNumber}
                    error={!!errors.registrationNumber}
                    helperText={errors?.registrationNumber?.message}
                  />

                  <Input
                    id="contactPrimaryNumber"
                    label="Primary Contact Number"
                    {...register('contactPrimaryNumber')}
                    autoComplete="contactPrimaryNumber"
                    defaultValue={entity?.contactPrimaryNumber}
                    error={!!errors.contactPrimaryNumber}
                    helperText={errors?.contactPrimaryNumber?.message}
                  />

                  <Input
                    id="contactSecondaryNumber"
                    label="Secondary Contact Number"
                    {...register('contactSecondaryNumber')}
                    autoComplete="contactSecondaryNumber"
                    defaultValue={entity?.contactSecondaryNumber}
                    error={!!errors.contactSecondaryNumber}
                    helperText={errors?.contactSecondaryNumber?.message}
                  />

                  <Input
                    id="contactEmailAddress"
                    label="Contact Email Address"
                    {...register('contactEmailAddress')}
                    autoComplete="contactEmailAddress"
                    defaultValue={entity?.contactEmailAddress}
                    error={!!errors.contactEmailAddress}
                    helperText={errors?.contactEmailAddress?.message}
                  />
                  <PrimarySelect
                    id="entityType"
                    name="entityType"
                    label="Entity Type"
                    control={control}
                    defaultValue={entity?.entityType}
                    error={!!errors.entityType}>
                    <MenuItem key="CC" value="Close Corporation (CC)">
                      Close Corporation (CC)
                    </MenuItem>
                    <MenuItem key="LLC" value="Limited Liability Company (LLC)">
                      Limited Liability Company (LLC)
                    </MenuItem>
                    <MenuItem key="NonProfit" value="Non-Profit Organization">
                      Non-Profit Organization
                    </MenuItem>
                    <MenuItem key="Partnership" value="Partnership">
                      Partnership
                    </MenuItem>
                    <MenuItem key="PtyLtd" value="Proprietary Limited Company (Pty Ltd)">
                      Proprietary Limited Company (Pty Ltd)
                    </MenuItem>
                    <MenuItem key="PublicCompany" value="Public Company">
                      Public Company
                    </MenuItem>
                  </PrimarySelect>
                  <br />
                  <PrimarySelect
                    id="entitySystemType"
                    name="entitySystemType"
                    label="System Type"
                    control={control}
                    defaultValue={entity?.entitySystemType}
                    error={!!errors.entitySystemType}>
                    <MenuItem key="company" value="Company/Organization">
                      Company/Organization
                    </MenuItem>
                    <MenuItem key="broker" value="Broker">
                      Broker
                    </MenuItem>
                  </PrimarySelect>
                </Grid>
                <Grid item md={6}>
                  <Input
                    id="address1"
                    label="Pysical Address Line 1"
                    {...register('address1')}
                    autoComplete="address1"
                    defaultValue={entity?.physicalAddress?.address1}
                    error={!!errors.address1}
                    helperText={errors?.address1?.message}
                  />
                  <Input
                    id="address2"
                    label="Pysical Address Line 2"
                    {...register('address2')}
                    autoComplete="address2"
                    defaultValue={entity?.physicalAddress?.address2}
                    error={!!errors.address2}
                    helperText={errors?.address2?.message}
                  />
                  <Input
                    id="suburb"
                    label="Suburb"
                    {...register('suburb')}
                    autoComplete="suburb"
                    defaultValue={entity?.physicalAddress?.suburb}
                    error={!!errors.suburb}
                    helperText={errors?.suburb?.message}
                  />
                  <Input
                    id="city"
                    label="City"
                    {...register('city')}
                    autoComplete="city"
                    defaultValue={entity?.physicalAddress?.city}
                    error={!!errors.city}
                    helperText={errors?.city?.message}
                  />
                  <Input
                    id="country"
                    label="Country"
                    {...register('country')}
                    autoComplete="country"
                    defaultValue={entity?.physicalAddress?.country}
                    error={!!errors.country}
                    helperText={errors?.country?.message}
                  />
                  <Input
                    id="postalCode"
                    label="Postal Code"
                    {...register('postalCode')}
                    autoComplete="postalCode"
                    defaultValue={entity?.physicalAddress?.postalCode}
                    error={!!errors.postalCode}
                    helperText={errors?.postalCode?.message}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                  variant="outlined"
                  fullWidth={false}
                  onClick={handleClose}
                  type="button">
                  Close
                </PrimaryButton>
                <PrimaryButton fullWidth={false}>Submit</PrimaryButton>
              </Box>
            </Form>
          )}
        </DialogContent>
      </FDialog>
    </Fragment>
  );
};

export default AddUpdateDialog;
