import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Box } from '@mui/system';
import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import NotificationIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// const Item = styled(Paper)(({ theme }) => ({
//     ...theme.typography.body2,
//     color: theme.palette.text.secondary,
//     maxWidth: 500,
//     padding: theme.spacing(1),
//     margin: theme.spacing(1),
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     width: '100%' // Ensure the width is consistent
//   }));

const NotificationCard = ({ notification }) => {
  const [expanded, setExpanded] = useState(false);
  const [more, setMore] = useState(false);
  const [anchorElMore, setAnchorElMore] = useState({});

  let timeAgo = null;

  const createdAt = new Date(notification.createdAt);
  if (!isNaN(createdAt)) {
    timeAgo = formatDistanceToNow(createdAt, { addSuffix: true });
  }

  const shouldShowToggle = notification?.subject?.length > 50;

  const onHideNotification = (id) => {
    console.log('Hide notification', id);
  };

  return (
    <>
      <Box
        display={'flex'}
        gap={2}
        direction="row"
        alignItems="center"
        p={2}
        sx={{ width: '100%' }}>
        <Avatar sx={{ alignSelf: 'flex-start' }}>
          {notification.notificationType === 'system' ? <SettingsIcon /> : <NotificationIcon />}
        </Avatar>
        <Box display="flex" flexDirection="column" sx={{ overflow: 'hidden', flexGrow: 1 }}>
          <Typography noWrap={!expanded}>
            {expanded ? notification.subject : `${notification?.subject?.slice(0, 50)}...`}
          </Typography>
          {shouldShowToggle && (
            <Typography
              variant="body2"
              color="primary"
              onClick={() => setExpanded(!expanded)}
              sx={{ cursor: 'pointer', flexShrink: 0 }}>
              {expanded ? 'Show Less' : 'Show More'}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {timeAgo}
          </Typography>
        </Box>
        {notification.notificationType !== 'system' && (
          <IconButton edge="end" onClick={(event) => setMore(!more)}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      {/* <Menu
                  open={Boolean(anchorElMore[index])}
                  onClose={handleCloseMenus}>
                  <MenuItem onClick={() => onHideNotification(notification._id)}>
                    Hide Notification
                  </MenuItem>
                </Menu> */}
    </>
  );
};

export default NotificationCard;
