export const communicationConstants = {
  GET_CONTACTS_REQUEST: "GET_CONTACTS_COMMUNICATION_REQUEST",
  GET_CONTACTS_SUCCESS: "GET_CONTACTS_COMMUNICATION_SUCCESS",
  GET_CONTACTS_FAILURE: "GET_CONTACTS_COMMUNICATION_FAILURE",

  GET_LISTS_REQUEST: "GET_LISTS_COMMUNICATION_REQUEST",
  GET_LISTS_SUCCESS: "GET_LISTS_COMMUNICATION_SUCCESS",
  GET_LISTS_FAILURE: "GET_LISTS_COMMUNICATION_FAILURE",

  CREATE_LIST_REQUEST: "CREATE_LIST_COMMUNICATION_REQUEST",
  CREATE_LIST_SUCCESS: "CREATE_LIST_COMMUNICATION_SUCCESS",
  CREATE_LIST_FAILURE: "CREATE_LIST_COMMUNICATION_FAILURE",

  GET_EMAILS_REQUEST: "GET_EMAILS_COMMUNICATION_REQUEST",
  GET_EMAILS_SUCCESS: "GET_EMAILS_COMMUNICATION_SUCCESS",
  GET_EMAILS_FAILURE: "GET_EMAILS_COMMUNICATION_FAILURE",

  CREATE_EMAIL_REQUEST: "CREATE_EMAIL_COMMUNICATION_REQUEST",
  CREATE_EMAIL_SUCCESS: "CREATE_EMAIL_COMMUNICATION_SUCCESS",
  CREATE_EMAIL_FAILURE: "CREATE_EMAIL_COMMUNICATION_FAILURE",
};
