import axiosInstance from '../axiosInstance';
import { config } from '../config';
import { authHeader } from '../helpers';

const getAll = () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.user_server}/all`, requestOptions).then((responce) => {
    return responce.data;
  });
};

const get = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.user_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
};

const update = (id, data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.put(`${config.user_server}/${id}`, data, requestOptions).then((responce) => {
    return responce.data;
  });
};

const updatePassword = (id, data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.user_server}/${id}/password`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const updateEmail = (id, data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.user_server}/${id}/email`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const updateDetails = (id, data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.user_server}/${id}/details`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const create = (data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.post(`${config.user_server}`, data, requestOptions).then((responce) => {
    return responce.data;
  });
};

const remove = (id) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.delete(`${config.user_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
};

const deleteClient = (clientId) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.user_server}/client/${clientId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const assignRole = (id, role) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.user_server}/${id}/role`, { role }, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const revokeRole = (id, role) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.user_server}/${id}/revokerole`, { role }, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

export const userService = {
  getAll,
  get,
  update,
  create,
  remove,
  updatePassword,
  updateEmail,
  updateDetails,
  deleteClient,
  assignRole,
  revokeRole
};
