import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  boxShadow: 'none',
  '& .MuiDialog-paper': {
    boxShadow: 'none'
  }
}));

export default function FDialog(props) {
  return <StyledDialog {...props}>{props.children}</StyledDialog>;
}
