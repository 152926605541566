export const transactionTypeConstants = {
  GET_ALL_REQUEST: "GET_ALL_TRANSACTIONTYPES_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_TRANSACTIONTYPES_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_TRANSACTIONTYPES_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_TRANSACTIONTYPES_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_TRANSACTIONTYPES_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_TRANSACTIONTYPES_FAILURE",

  CREATE_REQUEST: "CREATE_TRANSACTIONTYPES_REQUEST",
  CREATE_SUCCESS: "CREATE_TRANSACTIONTYPES_SUCCESS",
  CREATE_FAILURE: "CREATE_TRANSACTIONTYPES_FAILURE",

  UPDATE_REQUEST: "UPDATE_TRANSACTIONTYPES_REQUEST",
  UPDATE_SUCCESS: "UPDATE_TRANSACTIONTYPES_SUCCESS",
  UPDATE_FAILURE: "UPDATE_TRANSACTIONTYPES_FAILURE",

  DELETE_REQUEST: "DELETE_TRANSACTIONTYPES_REQUEST",
  DELETE_SUCCESS: "DELETE_TRANSACTIONTYPES_SUCCESS",
  DELETE_FAILURE: "DELETE_TRANSACTIONTYPES_FAILURE",

  GET_MAPPING_REQUEST: "GET_MAPPING_TRANSACTIONTYPES_REQUEST",
  GET_MAPPING_SUCCESS: "GET_MAPPING_TRANSACTIONTYPES_SUCCESS",
  GET_MAPPING_FAILURE: "GET_MAPPING_TRANSACTIONTYPES_FAILURE",

  UPDATE_MAPPING_REQUEST: "UPDATE_MAPPING_TRANSACTIONTYPES_REQUEST",
  UPDATE_MAPPING_SUCCESS: "UPDATE_MAPPING_TRANSACTIONTYPES_SUCCESS",
  UPDATE_MAPPING_FAILURE: "UPDATE_MAPPING_TRANSACTIONTYPES_FAILURE",
};
