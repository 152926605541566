import { Box } from "@mui/system";
import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from "../ui/buttons/primaryButton";
import { logout } from "../../features/auth/auth.slice";
import { Typography } from "@mui/material";

export default function Unauthorized() {
    const dispatch = useDispatch();
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant="h6">You are not authorized to view this page.</Typography>
        <PrimaryButton onClick={() => dispatch(logout())}>Logout</PrimaryButton>
    </Box>
  );
}
