export const accountConstants = {
  SUMMARY_REQUEST: "GET_SUMMARY_ACCOUNT_REQUEST",
  SUMMARY_SUCCESS: "GET_SUMMARY_ACCOUNT_SUCCESS",
  SUMMARY_FAILURE: "GET_SUMMARY_ACCOUNT_FAILURE",

  DAILY_REQUEST: "GET_DAILY_ACCOUNT_REQUEST",
  DAILY_SUCCESS: "GET_DAILY_ACCOUNT_SUCCESS",
  DAILY_FAILURE: "GET_DAILY_ACCOUNT_FAILURE",

  DAILY_LIST_REQUEST: "GET_DAILY_LIST_ACCOUNT_REQUEST",
  DAILY_LIST_SUCCESS: "GET_DAILY_LIST_ACCOUNT_SUCCESS",
  DAILY_LIST_FAILURE: "GET_DAILY_LIST_ACCOUNT_FAILURE",

  TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_ACCOUNT_REQUEST",
  TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_ACCOUNT_SUCCESS",
  TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_ACCOUNT_FAILURE",

  STATEMENTS_REQUEST: "GET_STATEMENTS_ACCOUNT_REQUEST",
  STATEMENTS_SUCCESS: "GET_STATEMENTS_ACCOUNT_SUCCESS",
  STATEMENTS_FAILURE: "GET_STATEMENTS_ACCOUNT_FAILURE",

  CLEAR_REQUEST: "GET_CLEAR_ACCOUNT_REQUEST",
  CLEAR_SUCCESS: "GET_CLEAR_ACCOUNT_SUCCESS",
  CLEAR_FAILURE: "GET_CLEAR_ACCOUNT_FAILURE",
};
