import { config } from '../config';
import axiosInstance from '../axiosInstance';

export const reportService = {
  exportCsv,
  exportProgressCsv
};

function exportCsv(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return axiosInstance
    .post(`${config.reports}/date-range`, data, requestOptions)
    .then((responce) => {
      return responce;
    });
}

function exportProgressCsv(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params: data
  };

  return axiosInstance.get(`${config.reports}/progress`, requestOptions).then((response) => {
    return response;
  });
}
