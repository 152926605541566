import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const houseModelService = {
  getAll,
  getById,
  create,
  update,
  remove,
  getDestinctModelsByServiceProvider,
  getModelMappings,
  updateModelMapping
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.housemodel_server}/`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.housemodel_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.housemodel_server}/`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function update(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.housemodel_server}/${id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.housemodel_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getDestinctModelsByServiceProvider(serviceProvider) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.housemodel_server}/serviceprovider/${serviceProvider}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getModelMappings(serviceProvider) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.housemodel_server}/mapping/${serviceProvider}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function updateModelMapping(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.housemodel_server}/mapping`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
