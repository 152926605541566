import { transactionTypeConstants } from "../constants";
import { transactionTypeService } from "../services";
import { alertActions } from "./";

export const transactionTypeActions = {
  getAll,
  getById,
  create,
  update,
  remove,
  getTransactionMappings,
  updateTransactionMapping,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.getAll().then(
      (transactionTypes) => {
        dispatch(success(transactionTypes));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.GET_ALL_REQUEST };
  }
  function success(transactionTypes) {
    return { type: transactionTypeConstants.GET_ALL_SUCCESS, transactionTypes };
  }
  function failure(error) {
    return { type: transactionTypeConstants.GET_ALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.getById(id).then(
      (transactionType) => {
        dispatch(success(transactionType));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.GET_BYID_REQUEST };
  }
  function success(transactionType) {
    return { type: transactionTypeConstants.GET_BYID_SUCCESS, transactionType };
  }
  function failure(error) {
    return { type: transactionTypeConstants.GET_BYID_FAILURE, error };
  }
}

function create(newTransactionType) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.create(newTransactionType).then(
      (transactionType) => {
        dispatch(success(transactionType));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.CREATE_REQUEST };
  }
  function success(transactionType) {
    return { type: transactionTypeConstants.CREATE_SUCCESS, transactionType };
  }
  function failure(error) {
    return { type: transactionTypeConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.update(id, data).then(
      (transactionType) => {
        dispatch(success(transactionType));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.UPDATE_REQUEST };
  }
  function success(transactionType) {
    return { type: transactionTypeConstants.UPDATE_SUCCESS, transactionType };
  }
  function failure(error) {
    return { type: transactionTypeConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.remove(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.DELETE_REQUEST };
  }
  function success(response) {
    return { type: transactionTypeConstants.DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: transactionTypeConstants.UPDATE_FAILURE, error };
  }
}

function getTransactionMappings(serviceProvider) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.getTransactionMappings(serviceProvider).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.GET_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: transactionTypeConstants.GET_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: transactionTypeConstants.GET_MAPPING_FAILURE, error };
  }
}

function updateTransactionMapping(data) {
  return (dispatch) => {
    dispatch(request());

    return transactionTypeService.updateTransactionMapping(data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: transactionTypeConstants.UPDATE_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: transactionTypeConstants.UPDATE_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: transactionTypeConstants.UPDATE_MAPPING_FAILURE, error };
  }
}
