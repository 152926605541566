import React from 'react';
import { Button } from '@mui/material';

const TableButton = ({ children, ...props }) => {
  return (
    <Button variant="text" color="primary" sx={{ borderRadius: '20px', my: 2 }} {...props}>
      {children}
    </Button>
  );
};

export default TableButton;
