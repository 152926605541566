import React, { Fragment, useEffect } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';
import {
  addClientUser,
  getById,
  getClientUsers,
  removeClientUser,
  updateClient
} from '../../../../features/client/client.slice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';

import Input from '../../../ui/forms/fields/input';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';

import DeleteIcon from '@mui/icons-material/Delete';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address')
});

const AccountLinking = ({ client }) => {
  const dispatch = useDispatch();
  const [openConfirmLink, setOpenConfirmLink] = React.useState(false);
  const [openRemoveLink, setOpenRemoveLink] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const users = useSelector((state) => state.client.client?.users);
  const loading = useSelector((state) => state.client.client?.loading);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (client) {
      dispatch(getClientUsers(client._id));
    }
  }, [dispatch]);

  const handleClickLinkAccountOpen = () => {
    setOpenConfirmLink(true);
  };

  const handleLinkAccountClose = () => {
    setOpenConfirmLink(false);
  };

  const handleLinkAccountConfirm = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data) => {
    dispatch(addClientUser(client._id, { email: data.email }));
    setOpenConfirmLink(false);
    reset();
  };

  const handleClickRemoveLinkOpen = (userId) => {
    setSelectedUser(userId);
    setOpenRemoveLink(true);
  };

  const handleRemoveLinkClose = () => {
    setOpenRemoveLink(false);
  };

  const handleLinkAccountRemove = () => {
    dispatch(removeClientUser({ clientId: client._id, userId: selectedUser }));
    setOpenRemoveLink(false);
  };

  return (
    <Fragment>
      <Typography variant="body2">
        Linking a user to a client will allow the user to view the client's account. <br />
        <br />
        Please make sure the user is is registered with the correct email.
      </Typography>
      <Box display={'flex'} jutifyContent={'flex-start'} alignItems={'center'} gap={1}>
        <Input
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          sx={{ width: '350px' }}
          {...register('email')}
        />
        <PrimaryButton onClick={() => handleClickLinkAccountOpen()}>Link User</PrimaryButton>
      </Box>

      <Divider />

      <Typography variant="h4" mb={2} mt={2}>
        Linked Users
      </Typography>

      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        gap={4}
        flexWrap={'wrap'}>
        {loading ? (
          <OrangeCircularProgress caption="Fetching users." />
        ) : users ? (
          users.map((user) => (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start '}
              alignItems={'center'}
              minWidth={'200px'}>
              <IconButton onClick={() => handleClickRemoveLinkOpen(user._id)}>
                <DeleteIcon />
              </IconButton>
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <Typography variant="h5">
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
                <Typography variant="body2">{user.phoneNumber || ''}</Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Typography variant="body2">No users linked to this client.</Typography>
        )}
      </Box>

      <Dialog
        open={openConfirmLink}
        onClose={handleLinkAccountClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Link user account'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to connect this user to this client account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleLinkAccountClose}>No, cancel request.</PrimaryButton>
          <PrimaryButton
            onClick={handleLinkAccountConfirm}
            autoFocus
            variant="contained"
            color="accent"
            sx={{ color: 'white' }}>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveLink}
        onClose={handleRemoveLinkClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Remove linked user'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleRemoveLinkClose}>No, cancel request.</PrimaryButton>
          <PrimaryButton
            onClick={handleLinkAccountRemove}
            autoFocus
            variant="contained"
            color="accent"
            sx={{ color: 'white' }}>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AccountLinking;
