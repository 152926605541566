import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { CardContent, Typography } from '@mui/material';
import ProductCard from '../../../ui/cards/productCard';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useNavigate } from 'react-router-dom';
import FCard from '../../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../../ui/fairtreeComponents/card/fCardHeader';

const Products = ({ account, admin }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box display={'flex'} justifyContent={'start'} alignSelf={'start'}>
        <CategoryOutlinedIcon />
        <Typography ml={1} mb={1} variant="h5">
          Products
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={3}>
        {account.balances.map((row, index) =>
          row.administratorMaintenance ? (
            <FCard>
              <FCardHeader title={row.administrator + ' Undergoing Maintenance'} />
              <CardContent>
                <Typography variant="body1">
                  {row.administrator} is currently undergoing maintenance. Please check back later.
                </Typography>
              </CardContent>
            </FCard>
          ) : (
            <ProductCard
              accountNumber={row.accountNumber}
              administrator={row.administrator}
              model={row.model}
              product={row.product}
              onClick={() =>
                navigate(
                  !admin
                    ? `/account/${row.accountNumber}`
                    : `/admin/client/account/${row.accountNumber}`
                )
              }
              closingDate={row.funds[0].closingDate}
              key={index}
            />
          )
        )}
      </Box>
    </Fragment>
  );
};

Products.propTypes = {
  account: PropTypes.object.isRequired,
  admin: PropTypes.bool
};

export default Products;
