import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHouseModels,
  getDistinctHouseModel,
  getModelMappings,
  updateModelMapping
} from '../../../../features/houseModel/houseModel.slice';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  Checkbox,
  MenuItem,
  Box
} from '@mui/material';
import { useParams } from 'react-router';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';

function MappingRow({ serviceProvider, accountModel, ...props }) {
  const [selectedItem, setSelectedItem] = useState('None');
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (props.savedValues) {
      setSelectedItem(props.savedValues.houseModel);
      setIsHidden(props.savedValues.isHidden);
    }
  }, [props.savedValues]);

  const houseModels = useSelector((state) => state.houseModel.houseModels);

  const handleSelect = (event) => {
    setSelectedItem(event.target.value);
    props.typeChange(event);
  };

  const handleHidden = (event) => {
    setIsHidden(event.target.checked);
    props.hiddenChange(event);
  };

  return (
    <TableRow>
      <TableCell>{accountModel.name} </TableCell>
      <TableCell>
        <Select
          name="mapToType"
          label="Select model"
          style={{ width: '250px', marginRight: '60px' }}
          onChange={handleSelect}
          value={selectedItem ? selectedItem : 'None'}>
          <MenuItem value={'None'}>None</MenuItem>
          {houseModels &&
            houseModels.map((tt) => (
              <MenuItem key={tt._id} value={tt._id}>
                {tt.name}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
      <TableCell>
        <Checkbox
          name="hide"
          label="Hide"
          color="primary"
          checked={isHidden}
          onChange={handleHidden}
        />
      </TableCell>
    </TableRow>
  );
}

function HouseModelMapping() {
  const dispatch = useDispatch();
  const params = useParams();

  const accountModels = useSelector((state) => state.houseModel.accountModels);
  const loadingModels = useSelector((state) => state.houseModel.loadingModels);

  const mappings = useSelector((state) => state.houseModel.modelMappings);
  const loadingMappingModels = useSelector((state) => state.houseModel.loadingMappingModels);

  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if (mappings) {
      setUpdatedData(mappings);
    }
  }, [mappings]);

  useEffect(() => {
    dispatch(getHouseModels());
    dispatch(getModelMappings(params.id));
    dispatch(getDistinctHouseModel(params.id));
  }, []);

  const update = (data) => {
    if (data.houseModel != null) {
      const foundIndex = updatedData.findIndex(
        (x) => x.serviceProvider === data.serviceProvider && x.accountModel === data.accountModel
      );

      if (foundIndex !== -1) {
        //Old Item
        const item = updatedData[foundIndex];
        //Updated Item
        const updatedItem = {
          ...item,
          houseModel: data.houseModel === 'None' ? null : data.houseModel
        };

        var mappingList = [...updatedData];
        mappingList[foundIndex] = updatedItem;

        dispatch(updateModelMapping(updatedItem));
        setUpdatedData(mappingList);
      } else {
        data.houseModel = data.houseModel === 'None' ? null : data.houseModel;
        var mappingList = [...updatedData, data];
        dispatch(updateModelMapping(data));
        setUpdatedData(mappingList);
      }
    } else if (data.isHidden != null) {
      const foundIndex = updatedData.findIndex(
        (x) => x.serviceProvider === data.serviceProvider && x.accountModel === data.accountModel
      );

      if (foundIndex !== -1) {
        console.log('Index Found', foundIndex);
        //Old Item
        const item = updatedData[foundIndex];
        //Updated Item
        const updatedItem = {
          ...item,
          isHidden: data.isHidden
        };

        var mappingList = [...updatedData];
        mappingList[foundIndex] = updatedItem;

        dispatch(updateModelMapping(updatedItem));
        setUpdatedData(mappingList);
      } else {
        var mapping = [...updatedData, data];
        dispatch(updateModelMapping(data));
        setUpdatedData(mapping);
      }
    }
  };

  const getSavedValue = (serviceProvider, accountModel) => {
    if (updatedData.length > 0) {
      const foundIndex = updatedData.findIndex(
        (x) => x.serviceProvider === serviceProvider && x.accountModel === accountModel._id
      );

      if (foundIndex !== -1) {
        return updatedData[foundIndex];
      }
    }
  };

  const hanldeTypeChange = (event, serviceProvider, accountModel) => {
    const newHouseModel = event.target.value;

    update({
      houseModel: newHouseModel,
      serviceProvider,
      accountModel: accountModel._id
    });
  };

  const hanldeHiddenChange = (event, serviceProvider, accountModel) => {
    const isHidden = event.target.checked;

    update({ isHidden, serviceProvider, accountModel: accountModel._id });
  };

  return (
    <Box>
      {loadingModels || loadingMappingModels ? (
        <OrangeCircularProgress caption="Loading Model Mappings..." />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Map to</TableCell>
              <TableCell>Hidden</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountModels
              ? accountModels.map((model, index) => (
                  <MappingRow
                    key={index}
                    accountModel={model}
                    serviceProvider={params.id}
                    typeChange={(event) => hanldeTypeChange(event, params.id, model)}
                    hiddenChange={(event) => hanldeHiddenChange(event, params.id, model)}
                    savedValues={getSavedValue(params.id, model)}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

export default HouseModelMapping;
