import React from 'react';
import { Button, useTheme } from '@mui/material';

const PrimaryButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      //type="submit"
      variant="outlined"
      sx={{
        my: 2,
        px: 3,
        py: 1,
        borderRadius: '10px',
        border: '1.5px solid',
        textTransform: 'none'
      }}
      {...props}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
