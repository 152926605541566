import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import PrimarySelect from '../../ui/forms/fields/select';
import {
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { clearOTP, verifyId } from '../../../features/auth/auth.slice';
import OTPForm from '../identityOTPForm';
import { Box } from '@mui/system';

const schema = yup.object().shape({
  idNumber: yup.string().required('This is a required field.'),
  entityType: yup.string().required('Please select a type.')
});

const ConnectAccountDialog = (props) => {
  //   const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const userInfo = useSelector((state) => state.auth.userInfo);

  const onIDSubmit = (data) => {
    dispatch(verifyId(data));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearOTP());
  };

  const otpDone = () => {
    setOpen(false);
    dispatch(clearOTP());
    props.done();
  };

  const idInputForm = () => (
    <Form onSubmit={handleSubmit(onIDSubmit)}>
      <Typography variant="body1">
        For security purposes we need to verify yout profile. Please provide us with your ID
        Number/Passport or Entity Registration Number.
      </Typography>

      <PrimarySelect
        defaultValue={'Person'}
        id="entityType"
        name="entityType"
        label="Identity Type"
        control={control}
        error={errors?.entityType}>
        <MenuItem key="Person" value="Person">
          ID Number / Passport
        </MenuItem>
        <MenuItem key="Company" value="Company">
          Company Registration Number
        </MenuItem>
        <MenuItem key="Organization" value="Organization">
          Organization Registration Number
        </MenuItem>
        <MenuItem key="Trust" value="Trust">
          Trust Number
        </MenuItem>
        <MenuItem key="Other" value="Other">
          Other
        </MenuItem>
      </PrimarySelect>

      <Input
        id="idNumber"
        label="Identification Number"
        {...register('idNumber')}
        error={!!errors.idNumber}
        helperText={errors?.idNumber?.message}
        autoFocus
      />

      <Box display={'flex'} justifyContent={'space-between'}>
        <PrimaryButton variant={'outlined'} onClick={handleClose}>
          Cancel
        </PrimaryButton>
        <PrimaryButton>Next</PrimaryButton>
      </Box>
    </Form>
  );

  return (
    <>
      <PrimaryButton onClick={handleOpen}>Authenticate Account</PrimaryButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Connect Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To connect your account, please enter your ID number or passport number here.
          </DialogContentText>
          {!userInfo ? (
            idInputForm()
          ) : (
            <OTPForm
              phoneNumber={userInfo.phoneNumber}
              idNumber={userInfo.idNumber}
              email={userInfo.email}
              done={() => otpDone()}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectAccountDialog;
