import { useContext, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { logout } from '../features/auth/auth.slice';
import { useDispatch } from 'react-redux';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime, promptBeforeIdle }) => {
  const [isIdle, setIdle] = useState(false);
  const dispatch = useDispatch();

  const handleIdle = () => {
    setIdle(true);
    dispatch(logout());
  };

  const idleTimer = useIdleTimer({
    timeout: idleTime * 1000,
    promptBeforeIdle: promptBeforeIdle * 1000,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500
  });
  return {
    isIdle,
    setIdle,
    idleTimer
  };
};
export default useIdleTimeout;
