import React, { useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, useMediaQuery, Typography, Link } from '@mui/material';
import PrimaryButton from '../../../../components/ui/buttons/primaryButton';
import IconButton from '@mui/material/IconButton';

import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function PDFView(props) {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      {props.buttonType && props.buttonType === 'Icon' ? (
        <IconButton color="primary" onClick={handleClickOpen} size="large">
          <props.icon />
        </IconButton>
      ) : (
        <PrimaryButton onClick={handleClickOpen}>
          {props.title || 'View more information'}
        </PrimaryButton>
      )}

      <Dialog maxWidth={'lg'} open={open} onClose={handleClose} fullScreen={true}>
        <DialogContent>
          <PrimaryButton onClick={handleClose}>Close Document</PrimaryButton>
          <DialogTitle sx={{ width: '1190px', margin: 'auto', pl: 0, pb: 1 }}>
            {props.title || 'View more information'}
          </DialogTitle>
          <Box display="flex" justifyContent="center">
            <Document
              file={{
                url: props.file
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              externalLinkRel={'_self'}
              externalLinkTarget={'_blank'}>
              {Array.from(new Array(numPages), (el, index) => (
                <Box key={`page_${index + 1}`}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={isMobile ? 0.45 : 2}
                    renderAnnotationLayer={true}
                    renderInteractiveForms={true}
                    renderMode={'canvas'}
                  />

                  {index + 1 !== numPages && <Divider sx={{ margin: 1 }} />}
                </Box>
              ))}
            </Document>
          </Box>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Typography>
            Having trouble viewing this document?{' '}
            <Link href={props.file} target="_blank">
              Click here
            </Link>
          </Typography>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PDFView;
