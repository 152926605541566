import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import PrimaryButton from './buttons/primaryButton';
import { useEffect, useState } from 'react';

const ActivityCountdownDialog = ({
  open,
  remainingTime,
  timeLimit,
  handleRefresh,
  handleLogOut
}) => {
  const [expiresIn, setExpiresIn] = useState(Math.round(remainingTime / 1000));
  const [expiresInPercentage, setExpiresInPercentage] = useState(100);

  useEffect(() => {
    if (timeLimit) {
      let timeRemainder = Math.round(remainingTime / 1000);
      let secondsLeft = timeRemainder;
      const intervalId = setInterval(() => {
        const percentage = (secondsLeft / timeRemainder) * 100;
        setExpiresInPercentage(percentage);

        secondsLeft = secondsLeft - 1;
        setExpiresIn(secondsLeft);

        if (secondsLeft < 1) {
          return handleLogOut();
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [remainingTime, timeLimit, handleLogOut]);

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={open}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <DialogTitle>Session Expiring</DialogTitle>
      <DialogContent>
        <Typography variant="body2">Seconds Left: </Typography>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
            width: '250px'
          }}>
          <CircularProgress size={'4rem'} variant="determinate" value={expiresInPercentage} />
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              top: 0
            }}>
            <Typography variant="h3" color="text.secondary">
              {expiresIn}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
        <PrimaryButton onClick={handleLogOut}>Log Out</PrimaryButton>
        <PrimaryButton onClick={handleRefresh} variant="contained" color="accent">
          Continue
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityCountdownDialog;
