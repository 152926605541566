import React , { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import GeneralButton from "../../ui/buttons/generalButton";

import Input from "../../ui/forms/fields/input";
import Form from "../../ui/forms";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateUserPassword } from "../../../features/user/user.slice";
import PrimaryButton from "../../ui/buttons/primaryButton";

const schema = yup.object().shape({
    currentPassword: yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Current password is required.'),
    newPassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.'),
    confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const PasswordResetDialog = (props) => {
    const dispatch = useDispatch();
    const [openPassword, setOpenPassword] = React.useState(false);

    const userId = useSelector((state) => state.auth.user._id);
    const updatingPassword = useSelector((state) => state.user.updatingPassword);
    ///const passwordUpdated = useSelector((state) => state.user.success);

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),  
    });

    const handlePasswordOpen = () => {
        setOpenPassword(true);
    };

    const handlePasswordClose = () => {
        setOpenPassword(false);
    };

    const handlePasswordReset = () => {
        handleSubmit(onSubmit)(); 
    };

    const onSubmit = (data) => {
        dispatch(updateUserPassword({id: userId, ...data})).unwrap().then(() => {
            setOpenPassword(false);
            reset();
        });
    };

    return (
        <>
            <PrimaryButton onClick={handlePasswordOpen} color="primary">Reset Password</PrimaryButton>

            <Dialog
                open={openPassword}
                onClose={handlePasswordClose}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
                <Form> 
                    <Input disabled={updatingPassword} autoFocus label="Current Password" type="password" error={errors.currentPassword} helpText={errors.currentPassword?.message} {...register('currentPassword')} />
                    <Input disabled={updatingPassword} label="New Password" type="password" error={errors.newPassword} helpText={errors.newPassword?.message} {...register('newPassword')} />
                    <Input disabled={updatingPassword} label="Confirm Password" type="password" error={errors.confirmPassword} helpText={errors.confirmPassword?.message} {...register('confirmPassword')} />
                </Form>
            </DialogContent>
            <DialogActions>
            <PrimaryButton disabled={updatingPassword} onClick={handlePasswordClose} color="primary" variant="outlined">
                Cancel
            </PrimaryButton>
            <PrimaryButton disabled={updatingPassword} onClick={handlePasswordReset} color="primary">
                Reset
            </PrimaryButton>
            </DialogActions>
        </Dialog>
      </>
    )
}

export default PasswordResetDialog;