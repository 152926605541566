import { houseModelConstants } from "../constants";
import { houseModelService } from "../services";
import { alertActions } from "./";

export const houseModelActions = {
  getAll,
  getById,
  create,
  update,
  remove,
  getDestinctModelsByServiceProvider,
  getModelMappings,
  updateModelMapping,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.getAll().then(
      (houseModels) => {
        dispatch(success(houseModels));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.GET_ALL_REQUEST };
  }
  function success(houseModels) {
    return { type: houseModelConstants.GET_ALL_SUCCESS, houseModels };
  }
  function failure(error) {
    return { type: houseModelConstants.GET_ALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.getById(id).then(
      (houseModel) => {
        dispatch(success(houseModel));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.GET_BYID_REQUEST };
  }
  function success(houseModel) {
    return { type: houseModelConstants.GET_BYID_SUCCESS, houseModel };
  }
  function failure(error) {
    return { type: houseModelConstants.GET_BYID_FAILURE, error };
  }
}

function create(newHouseModel) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.create(newHouseModel).then(
      (houseModel) => {
        dispatch(success(houseModel));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.CREATE_REQUEST };
  }
  function success(houseModel) {
    return { type: houseModelConstants.CREATE_SUCCESS, houseModel };
  }
  function failure(error) {
    return { type: houseModelConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.update(id, data).then(
      (houseModel) => {
        dispatch(success(houseModel));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.UPDATE_REQUEST };
  }
  function success(houseModel) {
    return { type: houseModelConstants.UPDATE_SUCCESS, houseModel };
  }
  function failure(error) {
    return { type: houseModelConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.remove(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.DELETE_REQUEST };
  }
  function success(response) {
    return { type: houseModelConstants.DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: houseModelConstants.UPDATE_FAILURE, error };
  }
}

function getDestinctModelsByServiceProvider(serviceprovider) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService
      .getDestinctModelsByServiceProvider(serviceprovider)
      .then(
        (result) => {
          dispatch(success(result));
        },
        (error) => {
          const err = error.response.data;

          dispatch(failure(err.msg));
          dispatch(alertActions.error(err.msg));
        }
      );
  };

  function request() {
    return { type: houseModelConstants.GET_BYSERVICEPROVIDER_REQUEST };
  }
  function success(result) {
    return {
      type: houseModelConstants.GET_BYSERVICEPROVIDER_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: houseModelConstants.GET_BYSERVICEPROVIDER_FAILURE, error };
  }
}

function getModelMappings(serviceProvider) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.getModelMappings(serviceProvider).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.GET_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: houseModelConstants.GET_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: houseModelConstants.GET_MAPPING_FAILURE, error };
  }
}

function updateModelMapping(data) {
  return (dispatch) => {
    dispatch(request());

    return houseModelService.updateModelMapping(data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: houseModelConstants.UPDATE_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: houseModelConstants.UPDATE_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: houseModelConstants.UPDATE_MAPPING_FAILURE, error };
  }
}
