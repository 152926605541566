import { fileStorageConstants } from "../constants";

export function fileStorage(state = {}, action) {
  switch (action.type) {
    case fileStorageConstants.UPLOAD_REQUEST:
      return {
        ...state,
      };
    case fileStorageConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadedFile: action.result.file,
        success: action.result.success,
      };
    case fileStorageConstants.UPLOAD_FAILURE:
      return { ...state, error: action.error };

    case fileStorageConstants.DELETE_REQUEST:
      return {
        ...state,
        success: false,
      };
    case fileStorageConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.result.success,
      };
    case fileStorageConstants.DELETE_FAILURE:
      return { ...state, error: action.error, success: false };

    default:
      return state;
  }
}
