import axios from 'axios';
import { config } from '../config';

const apiClient = axios.create({
  baseURL: '',
  timeout: 30000,
  timeoutErrorMessage: 'time out',
  header: {
    'Content-Type': 'application/json'
  }
});

const create = async (data) => {
  return apiClient.post(`${config.fundFiltererServer}`, data);
};

const update = async (data) => {
  return apiClient.put(`${config.fundFiltererServer}/${data._id}`, data);
};

const downloadPdf = async (id) => {
  try {
    const response = await apiClient.post(`${config.fundFiltererServer}/download-pdf/${id}`, null, {
      responseType: 'blob'
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `file-${id}.pdf`;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
};

export const fundFilterService = {
  create,
  update,
  downloadPdf
};
