import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const roleService = {
  getAll,
  getById,
  create,
  update
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.role_server}/`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.role_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function create(role) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.post(`${config.role_server}/`, role, requestOptions).then((responce) => {
    return responce.data;
  });
}

function update(role) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.role_server}/${role.id}`, role, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.delete(`${config.role_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}
