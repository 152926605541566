import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountSummaryCard from '../../account/accountView/components/accountSummaryCard';
import AccountTransactionsCard from '../../account/accountView/components/accountTransactionsCard';
import Statements from '../../account/accountView/components/statements';
import AccountTabs from '../../account/accountView/components/accountTabs';
import AccountInfoCard from '../../account/accountView/components/accountInfoCard';
import { useTitle } from '../../../hooks/titleProvider';
import PrimeSummaryCard from '../../account/accountView/components/primeSummaryCard';
import { getStatementsCount } from '../../../features/account/account.slice';

const ClientAccount = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const balances = useSelector((state) => state.client.account.balances);
  const statementCount = useSelector((state) => state.account.statementsCount);
  const loadingStatementsCount = useSelector((state) => state.account.loadingStatementsCount);
  const [account, setAccount] = React.useState(null);
  const fees = useSelector((state) => state.transaction.fees);

  const { setTitle } = useTitle();

  const summaryComponent = useCallback(() => {
    if (account) {
      switch (account.administrator) {
        default:
          return <AccountSummaryCard account={account} accountNumber={id} />;
      }
    }
    return null;
  }, [account, id]);

  const tabs = useMemo(() => {
    const tabsArray = [
      {
        title: 'Summary',
        content: summaryComponent()
      }
      // {
      //   title: 'Transactions',
      //   content: (
      //     <AccountTransactionsCard account={account} accountNumber={accountNumber} fees={fees} />
      //   )
      // }
    ];

    // If statements are available, add the Statements tab
    if (account && account.hasStatements) {
      tabsArray.push({
        title: 'Statements',
        content: <Statements account={account} accountNumber={id} />
      });
    }

    return tabsArray;
  }, [account, id, statementCount, summaryComponent, loadingStatementsCount]);

  useEffect(() => {
    dispatch(getStatementsCount(id));
  }, [dispatch, id, statementCount]);

  useEffect(() => {
    if (balances && id) {
      setAccount(balances.find((balance) => balance.accountNumber === id));
    }
  }, [balances, id]);

  useEffect(() => {
    if (account) {
      setTitle(account.product);
    }
  }, [account, setTitle]);

  return (
    account && (
      <AccountTabs tabs={tabs}>
        <AccountInfoCard account={account} />
      </AccountTabs>
    )
  );
};

export default ClientAccount;
