// features/guidance/stepper.slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0,
  steps: ['Select Product', 'Select Fund', 'Confirm', 'Complete']
};

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    nextStep: (state) => {
      if (state.activeStep < state.steps.length - 1) {
        state.activeStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep -= 1;
      }
    },
    resetStepper: (state) => {
      state.activeStep = 0;
    },
    setActiveStep: (state, action) => {
      const newActiveStep = action.payload;
      if (newActiveStep >= 0 && newActiveStep < state.steps.length) {
        state.activeStep = newActiveStep;
      }
    }
  }
});

export const { nextStep, prevStep, resetStepper, setActiveStep } = stepperSlice.actions;

export default stepperSlice.reducer;
