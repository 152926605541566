import React from 'react';
import PropTypes from 'prop-types';
import FCard from '../../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../../ui/fairtreeComponents/card/fCardHeader';
import { Box, useTheme } from '@mui/system';
import { CardActions, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import FCardTypography from '../../../ui/fairtreeComponents/card/fCardTypography';
import FCardCurrencyTypography from '../../../ui/fairtreeComponents/card/fCardCurrencyTypography';

const AccountSummaryCard = ({ account, accountNumber }) => {
  const theme = useTheme();

  return (
    <FCard>
      <FCardHeader
        title={
          <Box display="flex" justifyContent={'space-between'}>
            {account.model.name}
          </Box>
        }
        subheader={
          <Typography variant="p" component="small">
            {accountNumber}
          </Typography>
        }
      />
      <CardContent>
        <Box display="flex" flexDirection={'column'}>
          <Typography
            variant="p"
            component="small"
            fontWeight={500}
            color={theme.palette.accent.main}
            textAlign="right">
            {account.funds.length > 0 && moment(account.funds[0].closingDate).format('MMMM YYYY')}{' '}
            Closing
          </Typography>
          {account.funds.map((fundRow) =>
            fundRow.type !== 'Fees' ? (
              <Box
                key={fundRow.name}
                display="flex"
                flexGrow={1}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                sx={{ marginBottom: '5px' }}>
                <FCardTypography sx={{ textTransform: 'capitalize' }}>
                  {fundRow.name}
                </FCardTypography>
                <FCardCurrencyTypography>{fundRow.closing}</FCardCurrencyTypography>
              </Box>
            ) : null
          )}
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ width: '100%' }}>
        <Box display="flex" flexGrow={1} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="body1" sx={{ fontSize: '8px' }}>
            Administered by {account.administrator}
          </Typography>
        </Box>
      </CardActions>
    </FCard>
  );
};

export default AccountSummaryCard;

AccountSummaryCard.propTypes = {
  account: PropTypes.object.isRequired,
  accountNumber: PropTypes.string.isRequired
};
