export const config = {
  auth_server: '/api/user',
  user_server: '/api/user',
  client_server: '/api/client',
  account_server: '/api/account',
  serviceprovider_server: '/api/serviceproviders',
  fund_server: '/api/fund',
  vehicle_server: '/api/vehicle',
  taxfreedata_server: '/api/taxfreedata',
  transaction_server: '/api/transaction',
  transactiontype_server: '/api/transactiontype',
  housemodel_server: '/api/housemodel',
  product_server: '/api/product',
  role_server: '/api/role',
  userFunction_server: '/api/userfunction',
  entity_server: '/api/entity',
  calculation_server: '/api/calculations',
  communication_server: '/api/communication',
  file_server: '/api/files',
  mdd: '/api/mdd',
  advisorProfile: '/api/advisorprofile',
  advisor: '/api/advisor',
  reports: '/api/export',
  form_server: '/api/form',
  notification_server: '/api/notification',
  job_server: '/api/job',
  guidanceToolData_server: '/api/guidancetooldata',
  fundFiltererServer: '/api/fundfilterer'
};
