import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from './layout';

const PublicRoute = (props) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  return user ? (
    props.redirectAdmin === false ? (
      <Layout>
        <Outlet />
      </Layout>
    ) : (
      <Navigate to={'/home'} />
    )
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
