import { Box, CardActions, CardContent, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../features/user/user.slice";
// import FilterTable from './filterTable';
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../ui/buttons/primaryButton";
import FCard from "../../ui/fairtreeComponents/card/fCard";
import FCardHeader from "../../ui/fairtreeComponents/card/fCardHeader";

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "phoneNumeber",
    numeric: false,
    disablePadding: true,
    label: "Phone Number",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    disableSorting: true,
  },
];

function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleView = (userId) => {
    navigate(`/admin/users/details/${userId}`);
  };

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        {users &&
          users.map((user) => (
            <FCard sx={{ width: "100%", maxWidth: "700px" }}>
              <FCardHeader
                title={`${user.firstName} ${user.lastName}`}
                subheader={`${user.email}`}
              />
              <CardContent>
                <Box></Box>
              </CardContent>

              <Box
                display="flex"
                flexDirection="column"
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                mr={2}
              >
                <PrimaryButton onClick={() => handleView(user._id)}>
                  View
                </PrimaryButton>
              </Box>

              <CardActions>
                {" "}
                <Box display="flex" flexGrow={1} alignItems={"center"}>
                  {user.roles.map((role, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{ fontSize: "8px", ml: "5px" }}
                    >
                      {`${role.name}${
                        index < user.roles.length - 1 ? "," : ""
                      }`}
                    </Typography>
                  ))}
                </Box>
              </CardActions>
            </FCard>
          ))}
      </Box>
    </Fragment>
  );
}

export default Users;
