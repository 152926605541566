import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  height: '30px',
  minHeight: '0px',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent'
  }
}));

export default function FTabs(props) {
  return <StyledTabs {...props}>{props.children}</StyledTabs>;
}
