export const mddConstants = {
  GET_ALL_REQUEST: 'GET_ALL_MDD_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_MDD_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_MDD_FAILURE',

  GET_BYID_REQUEST: 'GET_BYID_MDD_REQUEST',
  GET_BYID_SUCCESS: 'GET_BYID_MDD_SUCCESS',
  GET_BYID_FAILURE: 'GET_BYID_MDD_FAILURE',

  CREATE_REQUEST: 'CREATE_MDD_REQUEST',
  CREATE_SUCCESS: 'CREATE_MDD_SUCCESS',
  CREATE_FAILURE: 'CREATE_MDD_FAILURE',

  UPLOAD_REQUEST: 'UPLOAD_MDD_REQUEST',
  UPLOAD_SUCCESS: 'UPLOAD_MDD_SUCCESS',
  UPLOAD_FAILURE: 'UPLOAD_MDD_FAILURE',

  UPDATE_REQUEST: 'UPDATE_MDD_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_MDD_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_MDD_FAILURE',

  DELETE_REQUEST: 'DELETE_MDD_REQUEST',
  DELETE_SUCCESS: 'DELETE_MDD_SUCCESS',
  DELETE_FAILURE: 'DELETE_MDD_FAILURE',

  RESET_REQUEST: 'RESET_MDD_REQUEST',
  RESET_SUCCESS: 'RESET_MDD_SUCCESS',
  RESET_FAILURE: 'RESET_MDD_FAILURE'
};
