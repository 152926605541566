import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';
import FileDownload from 'js-file-download';

export const jobService = {
  getAll,
  getAllByCategory,
  getJobStatus,
  downloadJobFile
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.job_server}/jobs`, requestOptions).then((response) => {
    return response.data;
  });
}

function getAllByCategory(category) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.job_server}/jobs/${category}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function getJobStatus(jobId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.job_server}/job-status/${jobId}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function downloadJobFile(id, filename) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    responseType: 'blob'
  };

  return axiosInstance
    .get(`${config.job_server}/download/${id}`, requestOptions)
    .then((response) => {
      FileDownload(response.data, filename);
    });
}
