import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import PrimaryButton from '../ui/buttons/primaryButton';
import { Fragment } from 'react';
import OrangeCircularProgress from '../ui/controls/circularProgress';

import { getStatements } from '../../features/account/account.slice';

import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

import { fileStorageService } from '../../features/fileStorage/fileStorage.service';

const Statements = () => {
  const dispatch = useDispatch();
  const clientStatements = useSelector((state) => state.account.statements);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStatements(params.accountNumber));
  }, [dispatch, params.accountNumber]);

  const handleDownload = (id, fileName) => {
    fileStorageService.downloadFile(id, fileName);
  };

  return clientStatements ? (
    <Fragment>
      <PrimaryButton sx={{ m: 1, width: '200px' }} onClick={() => navigate(-1)}>
        Back
      </PrimaryButton>
      <Paper sx={{ p: 1, m: 2 }}>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientStatements.map((statement, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{moment(statement.fileDate).format('DD MMMM YYYY')}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography>{statement.fileName}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      onClick={() => handleDownload(statement._id, statement.fileName)}>
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  ) : (
    <OrangeCircularProgress caption="Loading account statements..." />
  );
};

export default Statements;
