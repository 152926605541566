import { accountConstants } from "../constants";
import { accountService } from "../services";
import { alertActions } from "./";

export const accountActions = {
  getSummary,
  getTransactions,
  getDaily,
  listDailyAccounts,
  clear,
  getStatements,
};

function getSummary() {
  return (dispatch) => {
    dispatch(request());

    return accountService.getSummary().then(
      (summary) => {
        dispatch(success(summary));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: accountConstants.SUMMARY_REQUEST };
  }
  function success(summary) {
    return { type: accountConstants.SUMMARY_SUCCESS, summary };
  }
  function failure(error) {
    return { type: accountConstants.SUMMARY_FAILURE, error };
  }
}

function getDaily(accountId) {
  return (dispatch) => {
    dispatch(request());

    return accountService.getDaily(accountId).then(
      (daily) => {
        dispatch(success(daily));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: accountConstants.DAILY_REQUEST };
  }
  function success(daily) {
    return { type: accountConstants.DAILY_SUCCESS, daily };
  }
  function failure(error) {
    return { type: accountConstants.DAILY_FAILURE, error };
  }
}

function listDailyAccounts() {
  return (dispatch) => {
    dispatch(request());

    return accountService.listDailyAccounts().then(
      (dailyList) => {
        dispatch(success(dailyList));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: accountConstants.DAILY_LIST_REQUEST };
  }
  function success(dailyList) {
    return { type: accountConstants.DAILY_LIST_SUCCESS, dailyList };
  }
  function failure(error) {
    return { type: accountConstants.DAILY_LIST_FAILURE, error };
  }
}

function getTransactions() {
  return (dispatch) => {
    dispatch(request());

    return accountService.getTransactions().then(
      (transactions) => {
        dispatch(success(transactions));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: accountConstants.TRANSACTIONS_REQUEST };
  }
  function success(transactions) {
    return { type: accountConstants.TRANSACTIONS_SUCCESS, transactions };
  }
  function failure(error) {
    return { type: accountConstants.TRANSACTIONS_FAILURE, error };
  }
}

function getStatements(accountId) {
  return (dispatch) => {
    dispatch(request());

    return accountService.getStatements(accountId).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: accountConstants.STATEMENTS_REQUEST };
  }
  function success(result) {
    return { type: accountConstants.STATEMENTS_SUCCESS, result };
  }
  function failure(error) {
    return { type: accountConstants.STATEMENTS_FAILURE, error };
  }
}

function clear() {
  return (dispatch) => {
    dispatch(request());
    dispatch(success());
  };

  function request() {
    return { type: accountConstants.CLEAR_REQUEST };
  }
  function success() {
    return { type: accountConstants.CLEAR_SUCCESS };
  }
}
