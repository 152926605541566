import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userFunctionActions, roleActions } from '../../../../redux/actions';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box } from '@mui/material';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';

function Row(props) {
  const [accessValue, setAccessValue] = useState(props.accessMapValue);

  const onChange = (checked, value) => {
    let currentValue = accessValue;

    if (checked && value) {
      currentValue = currentValue + value;
    } else {
      currentValue = currentValue - value;
    }

    setAccessValue(currentValue);
    props.onValueChange(currentValue);
  };

  const hasAccess = (value) => {
    if (accessValue) {
      if (binSplit(accessValue).indexOf(value) > -1) return true;
    }
    return false;
  };

  const binSplit = (number, bits = 5) => {
    let unit = 1 << bits;
    const result = new Array(number >> bits).fill(unit);
    while (unit >= 1) {
      unit >>= 1;
      if (number & unit) result.push(unit);
    }
    return result;
  };

  return (
    <TableRow key={props.userFunction._id}>
      <TableCell>{props.userFunction.name}</TableCell>
      <TableCell>{props.userFunction.description}</TableCell>
      <TableCell>
        <Checkbox
          name="create"
          checked={hasAccess(1)}
          onChange={(e) => onChange(e.target.checked, 1)}
          color="primary"
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="read"
          checked={hasAccess(2)}
          onChange={(e) => onChange(e.target.checked, 2)}
          color="primary"
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="update"
          checked={hasAccess(4)}
          onChange={(e) => onChange(e.target.checked, 4)}
          color="primary"
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="delete"
          checked={hasAccess(8)}
          onChange={(e) => onChange(e.target.checked, 8)}
          color="primary"
        />
      </TableCell>
      <TableCell>
        {props.userFunction.hasExecute && (
          <Checkbox
            name="execute"
            checked={hasAccess(16)}
            onChange={(e) => onChange(e.target.checked, 16)}
            color="primary"
          />
        )}
      </TableCell>
    </TableRow>
  );
}

function AssignUserFunctions(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.userFunction.loading);
  const [accessMap, setAccessMap] = useState(
    props.role.userFunctions ? props.role.userFunctions : []
  );
  const userFunctions = useSelector((state) => state.userFunction.userFunctions);

  useEffect(() => {
    dispatch(userFunctionActions.getAll());
  }, [dispatch]);

  const getAccessMapValue = (uf_id) => {
    if (props.role && props.role.userFunctions) {
      for (let f = 0; f < props.role.userFunctions.length; f++) {
        const uf = props.role.userFunctions[f];
        if (uf_id === uf.uf) {
          return uf.value;
        }
      }
      return null;
    }
  };

  const handleValueChange = (value, uf) => {
    let currentAccessMap = accessMap ? [...accessMap] : []; // Create a shallow copy of accessMap
    let currentIndex = currentAccessMap.findIndex((item) => item.uf === uf);

    if (currentIndex !== -1) {
      currentAccessMap[currentIndex] = {
        ...currentAccessMap[currentIndex],
        value
      };
    } else {
      const newValue = {
        uf,
        value
      };

      currentAccessMap.push(newValue);
    }

    setAccessMap(currentAccessMap);
  };

  const submitChanges = () => {
    console.log(accessMap);
    if (accessMap.length > 0) {
      //Dispatch Udpate access maps

      dispatch(roleActions.update(props.role._id, { userFunctions: accessMap }));
      props.onClose();
    }
  };

  return !loading ? (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <PrimaryButton fullWidth={false} onClick={() => submitChanges()}>
          Save Changes
        </PrimaryButton>
        <PrimaryButton fullWidth={false} onClick={() => props.onClose()}>
          Close
        </PrimaryButton>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Create</TableCell>
            <TableCell>Read</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Delete</TableCell>
            <TableCell>Execute</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userFunctions
            ? userFunctions.map((uf) => (
                <Row
                  userFunction={uf}
                  accessMapValue={getAccessMapValue(uf._id)}
                  onValueChange={(value) => handleValueChange(value, uf._id)}
                />
              ))
            : null}
        </TableBody>
      </Table>

      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <PrimaryButton fullWidth={false} onClick={() => submitChanges()}>
          Save Changes
        </PrimaryButton>
        <PrimaryButton fullWidth={false} onClick={() => props.onClose()}>
          Close
        </PrimaryButton>
      </Box>
    </Box>
  ) : (
    <OrangeCircularProgress caption="Loading User Functions..." />
  );
}

export default AssignUserFunctions;
