import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAlertError, setAlertSuccess } from "../alert/alert.slice";
import { reportService } from "./report.service";

const initialState = {};

export const getReport = createAsyncThunk(
  "report/exportCsv",
  async (data, thunkAPI) => {
    try {
      const response = await reportService.exportCsv(data);
      const csvData = response.data;

      const blob = new Blob([csvData], { type: "text/csv" });

      const headers = response.headers;
      const contentDisposition = headers["content-disposition"];
      const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
      const filename = filenameMatch ? filenameMatch[1] : "report.csv";

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { csvData, filename };
    } catch (error) {
      const err = error.response?.data || error.message;
      thunkAPI.dispatch(setAlertError(err));
      throw error;
    }
  }
);

export const getProgressReport = createAsyncThunk(
  "report/exportCsv",
  async (data, thunkAPI) => {
    try {
      const response = await reportService.exportProgressCsv(data);
      const csvData = response.data;

      const blob = new Blob([csvData], { type: "text/csv" });

      const filename = "progress_report.csv";

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { csvData, filename };
    } catch (error) {
      const err = error.response?.data || error.message;
      thunkAPI.dispatch(setAlertError(err));
      throw error;
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReport.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getReport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProgressReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProgressReport.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProgressReport.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default reportSlice.reducer;
