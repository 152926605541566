import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

import PrimaryButton from '../../../../components/ui/buttons/primaryButton';
import Form from '../../../../components/ui/forms';
import Checkbox from '../../../../components/ui/forms/fields/checkbox';
import CurrencyInput from '../../../../components/ui/forms/fields/currencyInput';
import RadioGroup from '../../../../components/ui/forms/fields/radioGroup';
import Input from '../../../../components/ui/forms/fields/input';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Collapse,
  DialogContentText,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { numberToCurrency } from '../../../utils/currency';

import PDFView from './pdfView';
import { fundFilterCalculationTFSA } from '../../../utils/calculations';
import { selectGuidanceToolData } from '../../../../features/guidance/guidanceToolData.slice';

const useStyles = makeStyles((theme) => ({}));

const Row = (props) => {
  const { fund, amounts, isSelected } = props;
  const [selected, setSelected] = useState(false);
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchema = yup.object().shape(
    {
      selected: yup.bool(),
      lumpsum: yup.number().when('monthly', {
        is: (monthly) => !monthly || monthly === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            (fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum) - 1,
            `Lumpsum must be more than R ${
              fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum
            } once off`
          )
          .typeError('Lump Sum must be a number'),
        otherwise: yup
          .number()
          .typeError('Lump Sum must be a number')
          .when('lumpsum', {
            is: (lumpsum) => lumpsum > 0,
            then: yup.number().typeError('Lump Sum must be a number')
          })
      }),
      monthly: yup.number().when('lumpsum', {
        is: (lumpsum) => !lumpsum || lumpsum === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            props.minimums?.monthly - 1,
            `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
          )
          .typeError('Monthly Contribution must be a number'),
        otherwise: yup
          .number()
          .typeError('Monthly Contribution must be a number')
          .when('monthly', {
            is: (monthly) => monthly !== 0,
            then: yup
              .number()
              .typeError('Monthly Contribution must be a number')
              .moreThan(
                props.minimums?.monthly - 1,
                `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
              )
          })
      })
    },
    ['lumpsum', 'monthly']
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      lumpsum: amounts?.lumpsum && amounts.lumpsum > 0 ? amounts.lumpsum : 0,
      monthly: amounts?.monthly && amounts.monthly > 0 ? amounts.monthly : 0
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  const cbSelectedWatch = watch('selected');
  useEffect(() => {
    onChange();
  }, [cbSelectedWatch]);

  useEffect(() => {
    if (isSelected) {
      setValue('selected', true);
    }
  }, []);

  const onChange = async () => {
    if (await trigger(['selected', 'lumpsum', 'monthly'])) handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    props.onChange(data);
  };

  return (
    <Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        // onClick={(event) => handleClick(event, fund)}
        role="checkbox"
        aria-checked={selected}
        tabIndex={-1}
        selected={selected}>
        <TableCell>
          <Checkbox
            name="selected"
            color="primary"
            label=""
            control={control}
            onChange={onChange}
          />
        </TableCell>
        <TableCell padding="normal">100%</TableCell>
        <TableCell padding={'normal'}>
          {fund.name} {fund.code && `(${fund.code})`}
        </TableCell>

        {!mobile && (
          <>
            <TableCell>{fund.time}</TableCell>
            <TableCell>{fund.assetHoldings}</TableCell>
            <TableCell>{fund.regionalHoldings}</TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={watch('selected')} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1, display: 'flex' }}
              flexDirection={mobile ? 'column' : 'row'}
              justifyContent={'space-evenly'}>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={amounts?.lumpsum && amounts.lumpsum > 0 ? amounts.lumpsum : 0}
                name="lumpsum"
                className={classes.input}
                onChange={onChange}
              />

              {fund.hasMonthlyContributions && (
                <CurrencyInput
                  control={control}
                  defaultValue={amounts?.monthly && amounts.monthly > 0 ? amounts.monthly : 0}
                  name="monthly"
                  className={classes.input}
                  type="number"
                  id="monthly"
                  label="Monthly Contribution"
                  symbol="R"
                  error={!!errors.monthly}
                  helperText={errors?.monthly?.message}
                  onChange={onChange}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

function HelpTaxFreeSavings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [openSpeakToConsultant, setOpenSpeakToConsultant] = useState({
    open: false
  });
  const totalSteps = 5;
  const [player, setPlayer] = useState(false);
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [withdrawNeedWarning, setWithdrawNeedWarning] = React.useState(false);
  const [qualifyingFunds, setQualifyingFunds] = useState([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const funds = useSelector((state) => state.fund.guidanceFunds.filter((v) => v.useForTFSAHelp));

  const guidanceData = useSelector(selectGuidanceToolData);

  const { NeedToWithdrawListTFSA, TaxFreeRiskDescriptionsList, TaxFreeRiskProfileTypes } =
    guidanceData || {};

  const validationSchema = yup.object().shape(
    {
      lumpsum: yup.number().when('monthly', {
        is: (monthly) => !monthly || monthly === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Debit Order is required')
          .moreThan(
            props.minimums?.lumpsum - 1,
            `Lump Sum must be more than ${numberToCurrency(props.minimums?.lumpsum, 0)} once off`
          )
          .typeError('Lump Sum must be a number'),
        otherwise: yup
          .number()
          .typeError('Lump Sum must be a number')
          .when('lumpsum', {
            is: (lumpsum) => lumpsum > 0,
            then: yup.number().typeError('Lump Sum must be a number')
          })
      }),
      monthly: yup.number().when('lumpsum', {
        is: (lumpsum) => !lumpsum || lumpsum === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Debit Order is required')
          .moreThan(
            props.minimums?.monthly - 1,
            `Debit Order must be more than ${numberToCurrency(props.minimums?.monthly, 0)} p/m`
          )
          .typeError('Debit Order must be a number'),
        otherwise: yup
          .number()
          .typeError('Debit Order must be a number')
          .when('monthly', {
            is: (monthly) => monthly !== 0,
            then: yup
              .number()
              .typeError('Debit Order must be a number')
              .moreThan(
                props.minimums?.monthly - 1,
                `Debit Order must be more than ${numberToCurrency(props.minimums?.monthly, 0)} p/m`
              )
          })
      }),

      withdrawNeed: yup.string().required('Please select one.'),
      riskDesc: yup.string().required('Please select one.'),
      riskRelated: yup.string().required('Please select the one you relate the most to.'),
      riskRelatedScore: yup.number(),
      funds: yup.array().min(1, 'Please select at least one fund.'),
      acknowledge: yup
        .boolean()
        .default(false)
        .oneOf([true], 'Please acknowledge disclaimer to continue.')
    },
    ['lumpsum', 'monthly']
  );

  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: ['all'],
    defaultValues: {
      lumpsum: 0,
      monthly: 0
    },
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    register('riskRelatedScore');
    register('funds');
    register('acknowledge');
  }, []);

  useEffect(() => {
    setValue('funds', selectedFunds, { shouldValidate: true });
  }, [selectedFunds, setValue]);

  const watchScore = Math.round(watch('riskRelatedScore') * 100) / 100;
  useEffect(() => {
    setQualifyingFunds(
      funds.filter((f) => {
        if (f.useForTFSAHelp.minPoints <= watchScore && f.useForTFSAHelp.maxPoints >= watchScore) {
          return f;
        }
      })
    );
  }, [watchScore]);

  const withdrawNeed = watch('withdrawNeed');
  useEffect(() => {
    const wn = NeedToWithdrawListTFSA.types.find((n) => n.desc === withdrawNeed);
    if (wn?.warning) setWithdrawNeedWarning(true);
    else setWithdrawNeedWarning(false);
  }, [withdrawNeed]);

  const calculate = () => {
    let riskValues = {};
    let riskProfileValues = {};
    let withdrawValues = {};

    const currentValues = getValues();

    if (currentValues.withdrawNeed && currentValues.withdrawNeed !== '') {
      withdrawValues.weight = NeedToWithdrawListTFSA.weight;
      withdrawValues.points = NeedToWithdrawListTFSA.types.find(
        (x) => x.desc === currentValues.withdrawNeed
      ).points;
    }

    if (currentValues.riskDesc && currentValues.riskDesc !== '') {
      riskValues.weight = 10;
      riskValues.points = TaxFreeRiskDescriptionsList.types.find(
        (x) => x.desc === currentValues.riskDesc
      ).points;
    }

    if (currentValues.riskRelated && currentValues.riskRelated !== '') {
      riskProfileValues.weight = TaxFreeRiskProfileTypes.weight;
      riskProfileValues.points = TaxFreeRiskProfileTypes.types.find(
        (x) => x.name === currentValues.riskRelated
      ).points;
    }

    if (riskValues.weight && riskProfileValues.weight) {
      const result = fundFilterCalculationTFSA(withdrawValues, riskValues, riskProfileValues);

      setValue('riskRelatedScore', result, { shouldValidate: true });
    }
  };

  const handlePlayer = () => {
    setPlayer(true);
  };

  const handleClosePlayer = () => {
    setPlayer(false);
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = async (validate) => {
    const isValid = await trigger(validate);
    const data = await getValues();
    const submit = isLastStep() ? 'submit' : 'continue';

    if (isLastStep() && isValid) {
      handleDisclaimer();
    } else {
      if (isValid) {
        calculate();

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.onHelpNext({ ...data, lastActiveStep: activeStep, formSlug: 'tfsa_invest_help' });
        clearErrors();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFundSelect = (data, fund) => {
    let currentFunds = [...selectedFunds];

    if (!data.selected) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);
      currentFunds.splice(fundIndex, 1);
    }

    if (data.selected && currentFunds) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);

      if (fundIndex !== -1) {
        currentFunds[fundIndex].lumpsum = data.lumpsum;
        currentFunds[fundIndex].monthly = data.monthly;
      } else {
        currentFunds.push({
          fund,
          lumpsum: data.lumpsum,
          monthly: data.monthly
        });
      }
    }

    setSelectedFunds(currentFunds);
  };

  const onSubmit = (data) => {
    data.formSlug = 'tfsa_invest_help';
    props.onDataSubmit({ ...data, lastActiveStep: activeStep });
    setOpen(false);
  };

  const getAmounts = () => {
    const amounts = {
      lumpsum: getValues('lumpsum'),
      monthly: getValues('monthly')
    };

    return amounts;
  };

  const NavButtons = ({ index, totalSteps, validate }) => {
    return (
      <Box
        sx={{
          position: 'sticky',
          bottom: 110,
          display: 'flex',
          alignItems: 'center',
          m: 2,
          mb: 4,
          width: 'fit-content',
          ml: 'auto',
          justifyContent: 'flex-end',
          backgroundColor: theme.palette.background.default,
          zIndex: 1000
        }}
        display="flex"
        justifyContent="flex-end">
        <PrimaryButton disabled={index === 1} onClick={handleStartOver} sx={{ mt: 1, mr: 1 }}>
          Start Over
        </PrimaryButton>
        <PrimaryButton disabled={index === 1} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </PrimaryButton>
        <PrimaryButton
          disabled={qualifyingFunds.length <= 0 && isLastStep()}
          onClick={() => handleNext(validate)}
          sx={{
            mt: 1,
            mr: 1,
            backgroundColor: '#CC6323',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#b35920'
            }
          }}>
          {index === totalSteps ? 'Submit' : 'Continue'}
        </PrimaryButton>
      </Box>
    );
  };
  const handleStartOver = () => {
    setConfirmResetOpen(true);
  };

  const handleCloseReset = () => {
    setConfirmResetOpen(false);
  };

  const handleConfirmReset = () => {
    reset();
    setActiveStep(0);
    setConfirmResetOpen(false);
  };

  const handleDisclaimer = () => {
    setOpenDisclaimer(true);
  };

  const closeDisclaimer = async () => {
    const isValid = await trigger('acknowledge');
    setOpenDisclaimer(false);
  };

  const acknowledgeDisclaimer = () => {
    setValue('acknowledge', true, { shouldValidate: true });

    handleSubmit(onSubmit)();
    setOpenDisclaimer(false);
  };

  const handleRedirectToUnitTrust = async () => {
    props.handleRedirectToUnitTrust();
  };

  return (
    <Fragment>
      <Box pb={6} pt={4}>
        <Typography variant="h2" sx={{ color: '#CC6323', fontWeight: 500 }}>
          TFSA Investment Options
        </Typography>
      </Box>
      <Form>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>How much are you looking to invest?</StepLabel>
            <StepContent>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={0}
                name="lumpsum"
                className={classes.input}
              />
              <CurrencyInput
                control={control}
                defaultValue={0}
                name="monthly"
                className={classes.input}
                type="number"
                id="monthly"
                label="Debit Order"
                symbol="R"
                error={!!errors.monthly}
                helperText={errors?.monthly?.message}
              />
              <Typography sx={{ color: '#CC6323', m: 2 }} variant="h4" textAlign={'center'}>
                Two contribution limits apply: R36,000 per annum and R500,000 over your lifetime,
                with 40% tax payable on the amount exceeding these limits.
              </Typography>
              <NavButtons index={1} totalSteps={5} validate={['lumpsum', 'monthly']} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Your need to withdraw</StepLabel>
            <StepContent>
              <Typography>
                {
                  'To start benefiting from opening a Tax-Free Savings Account, a long-term investment horizon of at least 5 years is ideal. Would you be able to stay invested throughout a minimum period of 5 years?'
                }
              </Typography>
              <RadioGroup
                control={control}
                aria-label="withdrawNeed"
                name="withdrawNeed"
                error={!!errors?.withdrawNeed}
                helperText={errors?.withdrawNeed?.message}
                xs={{ margin: 1 }}>
                {NeedToWithdrawListTFSA.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                    sx={mobile ? { mt: 1, mb: 1 } : {}}
                  />
                ))}
              </RadioGroup>

              <Box display="flex" justifyContent={'center'} sx={{ p: 3 }}>
                {withdrawNeedWarning ? (
                  <Typography sx={{ color: '#CC6323' }} variant="h4" textAlign={'center'}>
                    {NeedToWithdrawListTFSA.warning}
                  </Typography>
                ) : (
                  <Typography sx={{ color: '#CC6323' }} variant="h4">
                    Great, then a Tax-Free Savings Account is what you’re looking for.
                  </Typography>
                )}
              </Box>

              {!withdrawNeedWarning ? (
                <NavButtons index={2} totalSteps={5} validate={'withdrawNeed'} />
              ) : (
                <Box sx={{ mb: 2 }} display="flex" justifyContent={'flex-end'}>
                  <PrimaryButton onClick={handleRedirectToUnitTrust}>
                    GO TO UNIT TRUSTS
                  </PrimaryButton>
                </Box>
              )}
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Risk</StepLabel>
            <StepContent>
              <Typography>RISK EXPLANATION WHEN INVESTING:</Typography>
              <Typography>
                Risk can be defined as the possibility of something not turning out the way you
                would have liked or differently to how you previously supposed. The 'risk-return
                trade-off' means: the higher the investment's level of risk, the higher the
                potential return and vice versa. This could mean potentially losing some of your
                initial capital invested, but could also mean greater potential for growth (as your
                investment value can move up or down). The degree to how much it fluctuates is
                dependent on the asset classes you invest in. When looking at your investment
                options, it's important to consider how much time you have to invest, your unique
                financial situation and how much risk you're willing to take on.
              </Typography>
              <br />
              <Typography>
                When you think of 'risk', what word best describes it for you?
              </Typography>
              <RadioGroup
                control={control}
                aria-label="riskDesc"
                name="riskDesc"
                error={!!errors?.riskDesc}
                helperText={errors?.riskDesc?.message}
                xs={{ margin: 1 }}>
                {TaxFreeRiskDescriptionsList.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                  />
                ))}
              </RadioGroup>
              <NavButtons index={3} totalSteps={5} validate={'riskDesc'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Investor Profile</StepLabel>
            <StepContent>
              <Typography>
                Read the descriptions below and tell us who you can relate to most?
              </Typography>

              <RadioGroup
                control={control}
                aria-label="riskRelated"
                name="riskRelated"
                error={!!errors?.riskRelated}
                helperText={errors?.riskRelated?.message}
                xs={{ margin: 1 }}>
                {!mobile ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Withdrawing</TableCell>
                        <TableCell>Total Savings</TableCell>
                        <TableCell>Investment Goal</TableCell>
                        <TableCell>Risk Appetite</TableCell>
                        <TableCell>Portfolio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {TaxFreeRiskProfileTypes.types.map((item, key) => (
                        <TableRow key={key}>
                          <TableCell width="200px">
                            <FormControlLabel
                              value={item.name}
                              control={<Radio />}
                              color="primary"
                              label={item.name}
                            />
                          </TableCell>
                          <TableCell>{item.withdrawing}</TableCell>
                          <TableCell>{item.totalSavings}</TableCell>
                          <TableCell>{item.investmentGoal}</TableCell>
                          <TableCell>{item.riskAppetite}</TableCell>
                          <TableCell>{item.portfolio}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  TaxFreeRiskProfileTypes.types.map((item, key) => (
                    <FormControlLabel
                      value={item.name}
                      control={<Radio />}
                      color="primary"
                      label={item.name}
                    />
                  ))
                )}
              </RadioGroup>
              <NavButtons index={4} totalSteps={5} validate={'riskRelated'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Fund Selection</StepLabel>
            <StepContent>
              {qualifyingFunds.length > 0 ? (
                <>
                  <Table size="small" padding={mobile ? 'none' : 'normal'} sx={{ mt: 1, mb: 2 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{''}</TableCell>
                        <TableCell padding="normal">
                          <Typography variant="subtitle2">%</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Investment Solution</Typography>
                        </TableCell>

                        {!mobile && (
                          <>
                            <TableCell>
                              <Typography variant="subtitle2">Time</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Asset Holdings</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Regional Holdings</Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {qualifyingFunds.map((fund, index) => (
                        <Row
                          key={index}
                          fund={fund}
                          onChange={(data) => handleFundSelect(data, fund)}
                          minimums={props.minimums || null}
                          amounts={getAmounts()}
                          isSelected={selectedFunds.some(
                            (item) => item.fund.name === fund.name && item.fund._id === fund._id
                          )}
                        />
                      ))}
                    </TableBody>
                  </Table>
                  <Box>
                    Score:{''}
                    {watchScore}
                  </Box>
                </>
              ) : (
                <Typography variant="h4" sx={{ color: '#CC6323' }}>
                  We do not have any funds or investment solutions available that match your
                  investment objectives. Please consider revising your investment time horizon or
                  investment risk profile
                </Typography>
              )}

              <Box display="flex" justifyContent="flex-end">
                <ErrorMessage
                  errors={errors}
                  name="acknowledge"
                  render={({ message }) => <Typography color="error">{message}</Typography>}
                />
              </Box>
              <Box>
                {isLastStep() && qualifyingFunds.length > 0 && (
                  <ErrorMessage
                    errors={errors}
                    name="funds"
                    render={({ message }) => <Typography color="error">{message}</Typography>}
                  />
                )}
              </Box>
              <NavButtons index={5} totalSteps={5} validate={'funds'} />
            </StepContent>
          </Step>
        </Stepper>
      </Form>

      <Dialog open={openDisclaimer} onClose={closeDisclaimer}>
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I acknowledge that this tool is a factual filter of published information relevant to
            each investment solution's fact sheet. It does not make a recommendation or render
            advice to an investor, but merely provides factual information. For advice on the
            suitability of these products to the investor's financial goals and objectives, we
            strongly recommend consulting an approved financial advisor. Please note the disclosures
            relevant to each product displayed on this page as published on their fact sheets.
            Fairtree Asset Management is an authorised financial services provider (FSP 25917).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={closeDisclaimer} color="primary">
            Close
          </PrimaryButton>
          <PrimaryButton onClick={acknowledgeDisclaimer} color="primary">
            Acknowledge
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmResetOpen}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Start Over?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start over?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button onClick={handleConfirmReset} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default HelpTaxFreeSavings;
