import { calculationConstants } from "../constants";

export function calculation(state = {}, action) {
  switch (action.type) {
    case calculationConstants.RETIREMENT_GOAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case calculationConstants.RETIREMENT_GOAL_SUCCESS:
      return {
        ...state,
        retirementGoal: action.retirementGoal,
        loading: false,
      };
    case calculationConstants.RETIREMENT_GOAL_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
}
