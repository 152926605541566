import { createSlice } from '@reduxjs/toolkit';
import { vehiclesService } from './vehicle.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  guidanceVehicles: [],
  loading: false,
  error: null
};

export const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setVehicles: (state, action) => {
      state.guidanceVehicles = action.payload;
      state.loading = false;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearVehicles: (state) => {
      state.guidanceVehicles = [];
      state.loading = false;
      state.error = null;
    }
  }
});

// Thunk to fetch vehicles for guidance
export const getVehiclesForGuidance = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await vehiclesService.getVehiclesForGuidance();
    dispatch(setVehicles(response));
  } catch (error) {
    const err = error.response?.data || { msg: 'An error occurred' };
    dispatch(setError(err.msg));
    dispatch(setAlertError(err.msg));
  }
};
export const { setVehicles, setLoading, setError, clearVehicles } = vehicleSlice.actions;

export default vehicleSlice.reducer;
