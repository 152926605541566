import React, { useEffect, useState } from 'react';
import { transactionTypeActions } from '../../../../../redux/actions/transactionType.actions';
import { useSelector, useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import GeneralButton from '../../../../ui/buttons/generalButton';
import Form from '../../../../ui/forms';
import Input from '../../../../ui/forms/fields/input';
import PrimaryButton from '../../../../ui/buttons/primaryButton';
import FDialog from '../../../../ui/fairtreeComponents/dialog/fDialog';

const schema = yup.object().shape({
  name: yup.string().required()
});

function TransactionTypeDialog(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    shouldUnregister: false
  });

  useEffect(() => {
    if (props.selectedTransactionType) {
      setValue('name', props.selectedTransactionType ? props.selectedTransactionType.name : '', {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [props.selectedTransactionType]);

  const onSubmit = (data) => {
    if (props.selectedTransactionType) {
      dispatch(transactionTypeActions.update(props.selectedTransactionType._id, data));
    } else dispatch(transactionTypeActions.create(data));

    props.onClose();
  };

  return (
    <FDialog fullWidth={true} maxWidth={'xs'} aria-labelledby="transactiontype-title" {...props}>
      <DialogTitle id="transactiontype-title">
        {props.selectedTransactionType ? 'Update' : 'Add'} Transaction Type
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the name for your new Transaction Type.</DialogContentText>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            id="name"
            label="Transaction Type"
            {...register('name')}
            error={!!errors.name}
            helperText={errors?.name?.message}
            autoFocus
          />
          <PrimaryButton fullWidth={false}>
            {props.selectedTransactionType ? 'Edit' : 'Add'}
          </PrimaryButton>
          <GeneralButton fullWidth={false} onClick={() => props.onClose()} color="primary">
            Close
          </GeneralButton>
        </Form>
      </DialogContent>
    </FDialog>
  );
}

export default TransactionTypeDialog;
