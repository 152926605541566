import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import BackIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useTitle } from '../../../hooks/titleProvider';
import { logout } from '../../../features/auth/auth.slice';
import { Box, styled } from '@mui/system';
import ProfileMenu from '../profileMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.main,
  boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.7)'
}));

const ProfileIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const MainAppBar = () => {
  const { title } = useTitle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        {window.location.pathname !== '/home' && (
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate(-1)}>
            <BackIcon />
          </IconButton>
        )}

        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        {/* Profile */}
        {user && <ProfileMenu />}

        {/* {user && (
          <ProfileIconButton
            edge="end"
            color="inherit"
            aria-label="profile"
            onClick={() => navigate('/user/profile')}>
            <PersonOutlineIcon />
          </ProfileIconButton>
        )}


        {user && (
          <Box>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="logout"
              onClick={() => dispatch(logout())}>
              <LogoutIcon />
            </IconButton>
          </Box>
        )} */}
      </Toolbar>
    </StyledAppBar>
  );
};

export default MainAppBar;
