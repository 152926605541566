import React from 'react';
import { useTitle } from '../../hooks/titleProvider';
import { Box } from '@mui/system';
import RoundedIconTile from '../ui/tiles/roundedIconTile';

import FamilyIcon from '@mui/icons-material/Diversity1';
import EntityIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';

const StartHere = () => {
  const { setTitle } = useTitle();
  setTitle('Link an account');

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        marginBottom: '30px'
      }}>
      <Typography variant={'h5'} sx={{ marginBottom: '20px' }}>
        Welcome to the Fairtree Investment Portal. You have not linked any accounts yet, let's get
        you started.
      </Typography>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap="wrap">
        <RoundedIconTile
          title={'Personal'}
          icon={<PersonIcon />}
          component={NavLink}
          to={'/onboarding'}
        />
        <RoundedIconTile
          title={'Family'}
          icon={<FamilyIcon />}
          component={NavLink}
          to={'/onboarding/family'}
        />
        <RoundedIconTile
          title={'Organization/Trust'}
          icon={<EntityIcon />}
          component={NavLink}
          to={'/onboarding/entity'}
        />
      </Box>
    </Box>
  );
};

export default StartHere;
