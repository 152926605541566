import { authConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {};

    case authConstants.REGISTER_REQUEST:
      return {};
    case authConstants.REGISTER_SUCCESS:
      return {};
    case authConstants.REGISTER_FAILURE:
      return {};

    case authConstants.VERIFYID_REQUEST:
      return { ...state };
    case authConstants.VERIFYID_SUCCESS:
      return { ...state, userInfo: action.userInfo };
    case authConstants.VERIFYID_FAILURE:
      return { ...state, error: action.error };
    case authConstants.LOGOUT:
      return {};

    case authConstants.AUTH_REQUEST:
      return {};
    case authConstants.AUTH_SUCCESS:
      return {};
    case authConstants.AUTH_FAILURE:
      return {};

    case authConstants.SEND_ENAILVERIFICATION_REQUEST:
      return { ...state, loading: true };
    case authConstants.SEND_ENAILVERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationSent: action.result.success,
        loading: false
      };
    case authConstants.SEND_ENAILVERIFICATION_FAILURE:
      return { err: action.err, loading: false };

    case authConstants.VERIFY_ENAILVERIFICATION_REQUEST:
      return { ...state, loading: true };
    case authConstants.VERIFY_ENAILVERIFICATION_SUCCESS:
      return { ...state, success: action.result.success, loading: false };
    case authConstants.VERIFY_ENAILVERIFICATION_FAILURE:
      return { ...state, err: action.err, loading: false };

    case authConstants.SENDOTP_REQUEST:
      return { ...state, loading: true };
    case authConstants.SENDOTP_SUCCESS:
      return { ...state, otp: action.otp, loading: false };
    case authConstants.SENDOTP_FAILURE:
      return { error: action.error, loading: false };

    case authConstants.VERIFYOTP_REQUEST:
      return { ...state, loading: true };
    case authConstants.VERIFYOTP_SUCCESS:
      return { ...state, otp: action.otp, loading: false };
    case authConstants.VERIFYOTP_FAILURE:
      return { ...state, error: action.error, loading: false };

    case authConstants.FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return { ...state, success: action.user.success, loading: false };
    case authConstants.FORGOT_PASSWORD_FAILURE:
      return { error: action.error, loading: false };

    case authConstants.UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case authConstants.UPDATE_PASSWORD_SUCCESS:
      return { ...state, success: action.payload.success, loading: false };
    case authConstants.UPDATE_PASSWORD_FAILURE:
      return { error: action.error, loading: false };

    default:
      return state;
  }
}
