import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getByAccount } from '../../features/transaction/transaction.slice';

import PrimaryButton from '../ui/buttons/primaryButton';
import OrangeCircularProgress from '../ui/controls/circularProgress';
import { numberToCurrency } from '../utils/currency';
import Fees from './fees';

const Transactions = () => {
  const dispatch = useDispatch();
  const clientTransactions = useSelector((state) => state.transaction.transactions);
  const account = useSelector((state) => state.transaction.account);
  const fees = useSelector((state) => state.transaction.fees);
  const params = useParams();
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(0);

  const handleExpandClick = (index) => {
    expanded === index ? setExpanded(-1) : setExpanded(index);
  };

  useEffect(() => {
    dispatch(getByAccount(params.accountNumber));
  }, [dispatch, params.accountNumber]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <List
        component="nav"
        aria-label="secondary"
        onClick={() => handleExpandClick(props.index)}
        sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <ListItemButton>
          <Typography variant="h4" color="primary" sx={{ mt: 3 }}>
            {props.month}
          </Typography>
          <IconButton {...other} />
        </ListItemButton>
      </List>
    );
  })(({ theme, index }) => ({
    transform: expanded === index ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  return clientTransactions ? (
    <Box sx={{ m: 1 }}>
      <PrimaryButton sx={{ mb: 1, width: '200px' }} onClick={() => navigate(-1)}>
        Back
      </PrimaryButton>

      <Typography component="h2" variant="h5">
        {account && account.product}
      </Typography>

      <Box>
        {clientTransactions.map((monthlyRow, index) => (
          <Fragment key={index}>
            <ExpandMore
              index={index}
              month={monthlyRow.month}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandLessIcon />
            </ExpandMore>
            <Collapse in={expanded === index} timeout="auto" unmountOnExit>
              {monthlyRow.transactions.length > 0 ? (
                <Fragment>
                  <TableContainer>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Fund / Product</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthlyRow.transactions.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                style={{ fontSize: '10px' }}
                                color="primary"
                                variant="p"
                                component="small">
                                {moment(row.date).format('DD/MM/YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{ fontSize: '10px' }}
                                color="primary"
                                variant="p"
                                component="small">
                                {row.product}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" width="40%">
                              <Typography variant="p">{row.description}</Typography>
                            </TableCell>
                            {row.amount ? (
                              <TableCell align="right">{numberToCurrency(row.amount)}</TableCell>
                            ) : row.credit ? (
                              <TableCell align="right">{numberToCurrency(row.credit)}</TableCell>
                            ) : (
                              <TableCell align="right">
                                {numberToCurrency(row.debit * -1)}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              ) : (
                <Typography align={'center'}>No transactions reported for this month</Typography>
              )}
            </Collapse>
          </Fragment>
        ))}
      </Box>

      {fees.items.length > 0 ? (
        <Paper sx={{ p: 3, m: 2 }}>
          <Fees />
        </Paper>
      ) : null}
    </Box>
  ) : (
    <OrangeCircularProgress caption="Loading account transactions..." />
  );
};

export default Transactions;
