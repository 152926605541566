import React from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { downloadPdf } from '../../../../features/fundFilter/fundFilter.slice';

const Step4Finalize = ({ onReset }) => {
  const dispatch = useDispatch();

  const fundFilterData = useSelector((state) => state.fundFilter);
  const loading = useSelector((state) => state.fundFilter.loading);
  const handleDownload = () => {
    if (fundFilterData._id) {
      dispatch(downloadPdf(fundFilterData._id));
    } else {
      console.error('No _id found in fundFilterData');
    }
  };

  const NavButtons = () => (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <Button
        onClick={handleDownload}
        disabled={loading}
        variant="contained"
        sx={{
          backgroundColor: '#CC6323',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#b35920'
          },
          padding: '10px 20px',
          fontSize: '16px',
          width: '15%',
          borderRadius: '8px',
          textTransform: 'none'
        }}>
        DOWNLOAD {loading && <CircularProgress size={24} sx={{ ml: 2, color: '#CC6323' }} />}
      </Button>
      <Button
        onClick={onReset}
        variant="contained"
        sx={{
          backgroundColor: '#CC6323',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#b35920'
          },
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '8px',
          width: '15%',
          textTransform: 'none'
        }}>
        START AGAIN
      </Button>
    </Box>
  );

  return (
    <Box sx={{ m: 5 }}>
      <Typography sx={{ mb: 2 }} variant="h3">
        Congratulations your Record is Complete!
      </Typography>
      <Typography sx={{ mb: 2 }} variant="h5">
        To view / download your PDF please select below
      </Typography>

      <NavButtons />
    </Box>
  );
};

export default Step4Finalize;
