import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';
import { history } from '../helpers';
import { authService } from './auth.service';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? { loggedIn: true, user, otp: { resending: false, success: false } }
  : {};

export const login = createAsyncThunk('auth/login', async (data, thunkAPI) => {
  return await authService
    .login(data)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response));

      thunkAPI.dispatch(setAlertSuccess('Signed in successfully. Email with OTP has been sent.'));
      return thunkAPI.fulfillWithValue(response);
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const auth = createAsyncThunk('auth/auth', async (data, thunkAPI) => {
  return await authService
    .auth()
    .then((data) => {
      let user = JSON.parse(localStorage.getItem('user'));
      user = { ...user, ...data.user };

      localStorage.setItem('user', JSON.stringify(user));
      return user;
    })
    .catch((error) => {
      const err = error.response.data;
      console.log(err);

      if (err.errorCode && err.errorCode === 1020) {
        localStorage.removeItem('user');
        history.push('/signin');
      }

      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const logout = createAsyncThunk('auth/logout', async (data, thunkAPI) => {
  //Tell server your logged out
  localStorage.removeItem('user');
  history.push('/signin');
  return;
});

export const register = createAsyncThunk('auth/register', async (data, thunkAPI) => {
  return await authService
    .register(data)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response));

      thunkAPI.dispatch(setAlertSuccess('Signed up successfully. Email with OTP has been sent.'));
      return thunkAPI.fulfillWithValue(response);
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const registerAdvisor = createAsyncThunk('advisor/register', async (data, thunkAPI) => {
  return await authService
    .registerAdvisor(data)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response));

      thunkAPI.dispatch(setAlertSuccess('Signed up successfully. Email with OTP has been sent.'));
      return thunkAPI.fulfillWithValue(response);
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const verifyId = createAsyncThunk('auth/verifyId', async (data, thunkAPI) => {
  return await authService
    .verifyId(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Please confirm your details'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      thunkAPI.rejectWithValue(err.message);
    });
});

export const sendPasswordReset = createAsyncThunk(
  'auth/sendPasswordReset',
  async (data, thunkAPI) => {
    return await authService
      .forgotPassword(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Recovery email sent to your inbox.'));
        return thunkAPI.fulfillWithValue(response);
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, thunkAPI) => {
  return await authService
    .updatePassword(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Password reset successfully.'));
      return thunkAPI.fulfillWithValue(response);
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      thunkAPI.rejectWithValue(err.message);
    });
});

export const sendClientVerificationOTP = createAsyncThunk(
  'auth/sendClientVerificationOTP',
  async (data, thunkAPI) => {
    return await authService
      .sendClientVerificationOTP(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('OTP sent successfully.'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const resendOTP = createAsyncThunk('auth/resendOTP', async (_, thunkAPI) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const data = { userId: user.id };

  return await authService
    .resendOTP(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('OTP sent successfully.'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const verifyOTP = createAsyncThunk('auth/verifyOTP', async (otpData, thunkAPI) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  if (user) {
    const data = {
      userId: user.id,
      otp: otpData.otp
    };

    return await authService
      .verifyOTP(data)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response));

        return thunkAPI
          .dispatch(auth())
          .unwrap()
          .then((userData) => {
            thunkAPI.dispatch(setAlertSuccess('Verified successfully.'));
            return thunkAPI.fulfillWithValue(userData);
          })
          .catch((error) => {
            thunkAPI.dispatch(setAlertError(error.msg));
            return thunkAPI.rejectWithValue(error.msg);
          });
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  } else {
    thunkAPI.dispatch(setAlertError('User not found'));
    return thunkAPI.rejectWithValue();
  }
});

export const verifyClientVerificationOTP = createAsyncThunk(
  'auth/verifyClientVerificationOTP',
  async (data, thunkAPI) => {
    return await authService
      .verifyClientVerificationOTP(data)
      .then((response) => {
        return thunkAPI
          .dispatch(auth())
          .unwrap()
          .then((userData) => {
            thunkAPI.dispatch(setAlertSuccess('Verified successfully.'));
            return thunkAPI.fulfillWithValue(userData);
          })
          .catch((error) => {
            thunkAPI.dispatch(setAlertError(error.msg));
            return thunkAPI.rejectWithValue(error.msg);
          });
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, thunkAPI) => {
  return await authService
    .refreshToken()
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response));

      return thunkAPI
        .dispatch(auth())
        .unwrap()
        .then((userData) => {
          return thunkAPI.fulfillWithValue(userData);
        })
        .catch((error) => {
          thunkAPI.dispatch(setAlertError(error.msg));
          return thunkAPI.rejectWithValue(error.msg);
        });
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearOTP: (state, action) => {
      state.otp = null;
      state.userInfo = null;
    },
    removeClientAccount: (state, action) => {
      const indexOfClient = state.user.client.findIndex((client) => client._id === action.payload);
      state.user.client.splice(indexOfClient, 1);
    },
    cancelOnBoarding: (state, action) => {
      state.userInfo = null;
      state.otp.success = false;
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loggingIn = false;
      state.user = action.payload;
    },
    [login.pending]: (state, action) => {
      state.loggingIn = true;
    },
    [login.rejected]: (state, action) => {
      state.loggingIn = false;
    },
    [logout.fulfilled]: (state, action) => {
      state.user = null;
    },
    [register.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [register.pending]: (state, action) => {
      state.loggingIn = true;
    },
    [register.rejected]: (state, action) => {
      state.loggingIn = false;
    },
    [registerAdvisor.fulfilled]: (state, action) => {
      state.otp = action.payload;
      state.otp.loading = false;
    },
    [registerAdvisor.pending]: (state, action) => {
      state.otp = {};
      state.otp.loading = true;
    },
    [registerAdvisor.rejected]: (state, action) => {
      state.otp = {};
      state.otp.loading = false;
    },
    [verifyId.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.userInfoLoading = false;
    },
    [verifyId.pending]: (state, action) => {
      state.userInfo = {};
      state.userInfoLoading = true;
    },
    [verifyId.rejected]: (state, action) => {
      state.userInfo = {};
      state.userInfoLoading = false;
    },
    [sendPasswordReset.fulfilled]: (state, action) => {
      state.success = action.payload.success;
      state.loading = false;
    },
    [sendPasswordReset.pending]: (state, action) => {
      state.success = false;
      state.loading = true;
    },
    [sendPasswordReset.rejected]: (state, action) => {
      state.success = false;
      state.loading = false;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [resetPassword.pending]: (state, action) => {
      state.loading = true;
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading = false;
    },
    [resendOTP.fulfilled]: (state, action) => {
      state.resendingOTP = false;
    },
    [resendOTP.pending]: (state, action) => {
      state.resendingOTP = true;
    },
    [resendOTP.rejected]: (state, action) => {
      state.resendingOTP = false;
    },
    [verifyOTP.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.otpLoading = false;
    },
    [verifyOTP.pending]: (state, action) => {
      state.otpLoading = true;
    },
    [verifyOTP.rejected]: (state, action) => {
      state.otpLoading = false;
    },
    [refreshToken.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.refreshTokenLoading = false;
    },
    [refreshToken.pending]: (state, action) => {
      state.refreshTokenLoading = true;
    },
    [refreshToken.rejected]: (state, action) => {
      state.refreshTokenLoading = false;
    },
    // [verifyUser.fulfilled]: (state, action) => {
    //   state.otpLoading = false;
    //   state.user.client = action.payload;
    // },
    // [verifyUser.pending]: (state, action) => {
    //   state.otpLoading = true;
    // },
    // [verifyUser.rejected]: (state, action) => {
    //   state.otpLoading = false;
    // },
    [sendClientVerificationOTP.fulfilled]: (state, action) => {
      state.otpLoading = false;
      state.otp = action.payload;
    },
    [sendClientVerificationOTP.pending]: (state, action) => {
      state.otpLoading = true;
    },
    [sendClientVerificationOTP.rejected]: (state, action) => {
      state.otpLoading = false;
    },
    [verifyClientVerificationOTP.fulfilled]: (state, action) => {
      state.otpLoading = false;
      state.userInfo = null;
      state.otp.success = false;
      state.user = action.payload;
    },
    [verifyClientVerificationOTP.pending]: (state, action) => {
      state.otpLoading = true;
    },
    [verifyClientVerificationOTP.rejected]: (state, action) => {
      state.otpLoading = false;
    }
  }
});

export const { removeClientAccount, clearOTP, cancelOnBoarding } = authSlice.actions;

export default authSlice.reducer;
