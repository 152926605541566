import React from 'react';
import { Button, useTheme } from '@mui/material';

const GeneralButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      fullWidth
      color="accent"
      sx={{
        borderRadius: '20px',
        margin: theme.spacing(3, 0, 2)
      }}
      {...props}>
      {children}
    </Button>
  );
};

export default GeneralButton;
