//Create redux slice for advisorProfile
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { advisorService } from './advisor.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = {
  providers: [],
  loadingCodes: false,
  addingCode: false,
  loading: false
};

export const getCodes = createAsyncThunk('advisor/getCodes', async (userId, thunkAPI) => {
  return await advisorService
    .getCodes(userId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const addCode = createAsyncThunk('advisor/addCode', async (data, thunkAPI) => {
  return await advisorService
    .addCode(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Code added successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const removeCode = createAsyncThunk('advisor/removeCode', async (id, thunkAPI) => {
  return await advisorService
    .removeCode(id)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Code removed successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getProviders = createAsyncThunk('advisor/getProviders', async (userId, thunkAPI) => {
  return await advisorService
    .getProviders(userId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const advisorSlice = createSlice({
  name: 'advisor',
  initialState,
  reducers: {},
  extraReducers: {
    [getCodes.fulfilled]: (state, action) => {
      state.codes = action.payload.codes;
      state.loadingCodes = false;
    },
    [getCodes.pending]: (state) => {
      state.loadingCodes = true;
    },
    [getCodes.rejected]: (state) => {
      state.loadingCodes = false;
    },
    [addCode.rejected]: (state) => {
      state.addingCode = false;
    },
    [addCode.fulfilled]: (state, action) => {
      state.addingCode = false;
      state.codes = action.payload.codes;
    },
    [addCode.pending]: (state) => {
      state.addingCode = true;
    },
    [removeCode.fulfilled]: (state, action) => {
      state.codes = action.payload.codes;
      state.loadingCodes = false;
    },
    [removeCode.pending]: (state) => {
      state.loadingCodes = true;
    },
    [removeCode.rejected]: (state) => {
      state.loadingCodes = false;
    },
    [getProviders.fulfilled]: (state, action) => {
      state.providers = action.payload.providers;
      state.loading = false;
    },
    [getProviders.pending]: (state) => {
      state.loading = true;
    },
    [getProviders.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export default advisorSlice.reducer;
