import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';

import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../ui/buttons/primaryButton';

import { useTitle } from '../../hooks/titleProvider';
import { banners, useBanner } from '../../hooks/bannerProvider';
import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import FCardHeader from '../ui/fairtreeComponents/card/fCardHeader';
import FCard from '../ui/fairtreeComponents/card/fCard';
import FCardTypography from '../ui/fairtreeComponents/card/fCardTypography';
import { submitForm } from '../../features/form/form.slice';

const formId = '64f8720f33b2e5c1292854e6';

function Contact() {
  //Contact Us form using ReactHookForm
  //Fields = Message, Name, Surname, Email, Phone Number

  const { setTitle } = useTitle();
  const { setBanner } = useBanner();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.form.loading);
  const [formSubmitted, setFormSubmitted] = useState(false);

  React.useEffect(() => {
    setTitle('Contact Us');
    setBanner(banners.default);
  }, []);

  const schema = yup.object().shape({
    message: yup.string().required('Message is a required field.'),
    name: yup.string().required('Name is a required field.'),
    surname: yup.string().required('Surname is a required field.'),
    email: yup.string().email().required('Email is a required field.'),
    phone: yup.string().required('Phone Number is a required field.')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    data.formId = formId;

    dispatch(submitForm(data))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setFormSubmitted(true);
        }
      });
  };

  const handleSubmitAnother = () => {
    setFormSubmitted(false);
    reset();
  };

  return (
    <Box display="flex" justifyContent="center">
      <FCard sx={{ maxWidth: '700px' }}>
        <CardContent>
          {!loading ? (
            formSubmitted ? (
              <Box>
                <Typography variant="h4" textAlign="center">
                  Thank you for contacting us.
                </Typography>
                <Typography variant="h6" textAlign="center" mt={3}>
                  We will be in touch with you shortly.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h3">Contact Us</Typography>
                <Typography variant="h6" mt={4}>
                  Speak with a consultant telephonically:
                </Typography>

                <Box display="flex" alignItems={'center'} sx={{ mt: 1, mb: 1 }}>
                  <SupportAgentIcon sx={{ fontSize: '28px', marginRight: '5px' }} />
                  <Divider orientation="vertical" flexItem />
                  <Link href="tel:+27861760760" underline="none">
                    <Typography variant="h4" sx={{ marginLeft: '5px' }}>
                      +27 861 76 0760
                    </Typography>
                  </Link>
                </Box>

                <Typography variant="h6" mt={4}>
                  Alternatively, please explain the nature of your query:
                </Typography>
                <Form>
                  <Input
                    label="Please tell us how we can help you:"
                    name="message"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    multiline
                    minRows={2}
                    maxRows={4}
                    {...register('message')}
                  />
                  <Input
                    label="Name"
                    autoComplete="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    {...register('name')}
                  />
                  <Input
                    label="Surname"
                    autoComplete="surname"
                    error={!!errors.surname}
                    helperText={errors?.surname?.message}
                    {...register('surname')}
                  />
                  <Input
                    label="Email Address"
                    type="email"
                    autoComplete="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email')}
                  />
                  <Input
                    label="Phone Number"
                    type="tel"
                    autoComplete="phone"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    {...register('phone')}
                  />
                </Form>
              </Box>
            )
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </CardContent>
        <CardActions disableSpacing sx={{ width: '100%', justifyContent: 'center' }}>
          {!loading && !formSubmitted && (
            <PrimaryButton onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
          )}
          {!loading && formSubmitted && (
            <PrimaryButton onClick={() => handleSubmitAnother()}>
              Submit another query
            </PrimaryButton>
          )}
        </CardActions>
      </FCard>
    </Box>
  );
}

export default Contact;
