import { userFunctionConstants } from "../constants";

export function userFunction(state = {}, action) {
  switch (action.type) {
    case userFunctionConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case userFunctionConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        userFunctions: action.result.userFunctions,
        success: action.result.success,
        loading: false,
      };
    case userFunctionConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userFunctionConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case userFunctionConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        userFunction: action.result.userFunction,
        success: action.result.success,
        loading: false,
      };
    case userFunctionConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userFunctionConstants.CREATE_REQUEST:
      return { ...state, loading: true };
    case userFunctionConstants.CREATE_SUCCESS:
      return {
        ...state,
        userFunction: action.result.userFunction,
        success: action.result.success,
        loading: false,
      };
    case userFunctionConstants.CREATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userFunctionConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case userFunctionConstants.UPDATE_SUCCESS:
      return {
        ...state,
        userFunction: action.result.userFunction,
        success: action.result.success,
        loading: false,
      };
    case userFunctionConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userFunctionConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case userFunctionConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.result.success,
        loading: false,
      };
    case userFunctionConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
