import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import PrimaryButton from '../buttons/primaryButton';

const ImageBadge = ({ src, altText, dialog, dialogContent }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (dialog) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems={'center'}
        m={'3px'}
        sx={{ cursor: 'pointer' }}
        onClick={() => handleOpen()}>
        <Box
          component="img"
          sx={{
            maxWidth: 60,
            borderRadius: '10px'
          }}
          alt={altText}
          src={src}
        />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{altText}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageBadge;
