import { transactionTypeConstants } from "../constants";

export function transactionType(state = {}, action) {
  switch (action.type) {
    case transactionTypeConstants.GET_ALL_REQUEST:
      return { ...state };
    case transactionTypeConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        transactionTypes: action.transactionTypes.transactionTypes,
        success: action.transactionTypes.success,
      };
    case transactionTypeConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.GET_BYID_REQUEST:
      return { ...state };
    case transactionTypeConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        transactionType: action.transactionTypes.transactionType,
        success: action.transactionTypes.success,
      };
    case transactionTypeConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.CREATE_REQUEST:
      return { ...state };
    case transactionTypeConstants.CREATE_SUCCESS:
      return {
        ...state,
        transactionType: action.transactionType.transactionType,
        success: action.transactionType.success,
      };
    case transactionTypeConstants.CREATE_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.UPDATE_REQUEST:
      return { ...state };
    case transactionTypeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        transactionType: action.transactionType.transactionType,
        success: action.transactionType.success,
      };
    case transactionTypeConstants.UPDATE_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.DELETE_REQUEST:
      return { ...state };
    case transactionTypeConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.response.success,
      };
    case transactionTypeConstants.DELETE_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.GET_MAPPING_REQUEST:
      return { ...state };
    case transactionTypeConstants.GET_MAPPING_SUCCESS:
      return {
        ...state,
        transactionTypeMappings: action.result.transactionTypeMappings,
        success: action.result.success,
      };
    case transactionTypeConstants.GET_MAPPING_FAILURE:
      return { ...state, error: action.error };

    case transactionTypeConstants.UPDATE_MAPPING_REQUEST:
      return { ...state };
    case transactionTypeConstants.UPDATE_MAPPING_SUCCESS:
      return {
        ...state,
        transactionTypeMapping: action.result.transactionTypeMapping,
        success: action.result.success,
      };
    case transactionTypeConstants.UPDATE_MAPPING_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
