//User slice
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';
import { auth, removeClientAccount } from '../auth/auth.slice';
import { userService } from './user.service';

const initialState = {
  updatingPassword: false,
  updatingEmail: false,
  updatingDetails: false,
  disconnectingClient: false,
  selectedUser: {}
};

export const getUsers = createAsyncThunk('user/getUsers', async (_, thunkAPI) => {
  return await userService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getUser = createAsyncThunk('user/getUser', async (id, thunkAPI) => {
  return await userService
    .get(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
  return await userService
    .update(data.id, data.data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('User updated successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const createUser = createAsyncThunk('user/createUser', async (data, thunkAPI) => {
  return await userService
    .create(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('User created successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (id, thunkAPI) => {
  return await userService
    .delete(id)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('User deleted'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async (data, thunkAPI) => {
    return await userService
      .updatePassword(data.id, data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Password updated'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateEmail = createAsyncThunk('user/updateEmail', async (data, thunkAPI) => {
  return await userService
    .updateEmail(data.id, data.data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Email updated'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateDetails = createAsyncThunk('user/updateDetails', async (data, thunkAPI) => {
  return await userService
    .updateDetails(data.id, data.data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Details updated'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const deleteClient = createAsyncThunk('user/deleteClient', async (id, thunkAPI) => {
  return await userService
    .deleteClient(id)
    .then(async (response) => {
      thunkAPI.dispatch(setAlertSuccess('Client Profile Disconnected'));

      //Removing client account from auth user
      thunkAPI.dispatch(removeClientAccount(id));
      thunkAPI.dispatch(auth());

      return thunkAPI.fulfillWithValue(response);
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const addAdvisorCode = createAsyncThunk('user/addAdvisorCode', async (data, thunkAPI) => {
  return await userService
    .addAdvisorCode(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Advisor Code Added'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const assignRoleToUser = createAsyncThunk(
  'user/assignRoleToUser',
  async (data, thunkAPI) => {
    return await userService
      .assignRole(data.user, data.role)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Role assigned'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const revokeRoleFromUser = createAsyncThunk(
  'user/revokeRoleFromUser',
  async (data, thunkAPI) => {
    return await userService
      .revokeRole(data.user, data.role)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Role revoked'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    }
  },
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload.users;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
    },
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [createUser.pending]: (state, action) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateUserPassword.pending]: (state, action) => {
      state.updatingPassword = true;
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      state.updatingPassword = false;
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.updatingPassword = false;
      state.errorMsg = action.payload;
    },
    [updateEmail.pending]: (state, action) => {
      state.updatingEmail = true;
    },
    [updateEmail.fulfilled]: (state, action) => {
      state.updatingEmail = false;
    },
    [updateEmail.rejected]: (state, action) => {
      state.updatingEmail = false;
      state.errorMsg = action.payload;
    },
    [updateDetails.pending]: (state, action) => {
      state.updatingDetails = true;
    },
    [updateDetails.fulfilled]: (state, action) => {
      state.updatingDetails = false;
    },
    [updateDetails.rejected]: (state, action) => {
      state.updatingDetails = false;
      state.errorMsg = action.payload;
    },
    [deleteClient.pending]: (state, action) => {
      state.disconnectingClient = true;
    },
    [deleteClient.fulfilled]: (state, action) => {
      state.disconnectingClient = false;
    },
    [deleteClient.rejected]: (state, action) => {
      state.disconnectingClient = false;
      state.errorMsg = action.payload;
    },
    [assignRoleToUser.pending]: (state, action) => {
      state.assigningRole = true;
    },
    [assignRoleToUser.fulfilled]: (state, action) => {
      state.assigningRole = false;
    },
    [assignRoleToUser.rejected]: (state, action) => {
      state.assigningRole = false;
    },
    [revokeRoleFromUser.pending]: (state, action) => {
      state.revokingRole = true;
    },
    [revokeRoleFromUser.fulfilled]: (state, action) => {
      state.revokingRole = false;
    },
    [revokeRoleFromUser.rejected]: (state, action) => {
      state.revokingRole = false;
    }
  }
});

export const { selectUser } = userSlice.actions;

export default userSlice.reducer;
