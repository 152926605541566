import { config } from "../config";
import { authHeader } from "../helpers";
import axios from "axios";
//import { IsJsonString } from '../helpers'

export const authService = {
  auth,
  login,
  logout,
  register,
  verifyId,
  sendOTP,
  verifyOTP,
  forgotPassword,
  updatePassword,
  sendEmailVerification,
  verifyEmailAddress,
};

function auth() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(`${config.auth_server}/`, requestOptions)
    .then((responce) => {
      let user = JSON.parse(localStorage.getItem("user"));
      user = { ...user, ...responce.data.user };

      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

function login(credentials) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.auth_server}/login`, credentials, requestOptions)
    .then((responce) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(responce.data));
      return responce.data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return axios.get(`${config.auth_server}/logout`, requestOptions);
}

function register(credentials) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.auth_server}/register`, credentials, requestOptions)
    .then((responce) => {
      localStorage.setItem("user", JSON.stringify(responce.data));
      return responce.data;
    });
}

function sendEmailVerification() {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.auth_server}/email/verification/send`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function verifyEmailAddress(token) {
  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .get(
      `${config.auth_server}/email/verification/confirm/${token}`,
      requestOptions
    )
    .then((responce) => {
      if (responce.data.success) {
        const user = JSON.parse(localStorage.getItem("user"));
        user.emailConfirmed = responce.data.emailConfirmed;
        user.emailToken = responce.data.emailToken;

        localStorage.setItem("user", JSON.stringify(user));
      }

      return responce.data;
    });
}

function forgotPassword(credentials) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(`${config.auth_server}/forgot-password`, credentials, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function updatePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  return axios
    .post(`${config.auth_server}/update-password`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function verifyId(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.auth_server}/verifyId`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function sendOTP(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.auth_server}/sendOTP`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function verifyOTP(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.auth_server}/verifyOTP`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
