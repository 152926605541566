import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formService } from './form.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  loading: false
};

export const submitForm = createAsyncThunk('form/submit', async (data, thunkAPI) => {
  return await formService
    .submitForm(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      return thunkAPI.rejectWithValue(err.msg);
    });
});

export const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [submitForm.fulfilled]: (state, action) => {
      state.success = action.payload.success;
      state.loading = false;
    },
    [submitForm.pending]: (state) => {
      state.loading = true;
    },
    [submitForm.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export default formSlice.reducer;
