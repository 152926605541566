import { Box } from '@mui/system';
import React from 'react';
import { useBanner } from '../../../hooks/bannerProvider';
import { useTheme } from '@mui/system';

const LandingBanner = () => {
  const { banner, isMobile } = useBanner();
  const theme = useTheme();

  return banner !== null ? (
    <Box
      component="img"
      sx={{
        [theme.breakpoints.down('sm')]: {
          height: '100px'
        },
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center bottom',
        borderRadius: '0 0 7px 7px',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.12)'
      }}
      alt="Home Banner"
      src={isMobile ? banner.mobile : banner.desktop}
    />
  ) : null;
};

export default LandingBanner;
