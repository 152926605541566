/**
 *     <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
 */

import React, { useState } from 'react';
import { Box } from '@mui/system';

const iframe =
  '<iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/37343805/f/13kmhx-1848/2S6DKS7010SJM93EWE" onwheel="" width="100%" height="100%" style="background: transparent;" frameBorder="0"></iframe>';

function SubmitTicket() {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app-cdn.clickup.com/assets/js/forms-embed/v1.js';
    document.body.appendChild(script);
  }, []);

  function Iframe(props) {
    return (
      <div
        style={{ height: '100%' }}
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
      />
    );
  }

  return <Iframe iframe={iframe} />;
}

export default SubmitTicket;
