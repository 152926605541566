import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import { Link } from 'react-router-dom';

const Actions = () => {
  return (
    <Fragment>
      {/*  Action Card */}
      <Card>
        <CardContent>
          <Typography>Statements</Typography>
        </CardContent>
        <CardActions>
          <PrimaryButton href="/admin/actions/statements">Go</PrimaryButton>
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default Actions;
