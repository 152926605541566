import React from 'react';
import { Button, useTheme } from '@mui/material';

const DangerButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      type="submit"
      variant="outlined"
      sx={{
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1, 3),
        borderRadius: '20px',
        border: '1.5px solid',
        textTransform: 'none',
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
          borderColor: theme.palette.error.main
        }
      }}
      {...props}>
      {children}
    </Button>
  );
};

export default DangerButton;
