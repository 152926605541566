import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { clearClientReports, getAdminClientReports } from '../../../features/client/client.slice';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import { Box, Stack } from '@mui/system';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import DocumentRow from '../../ui/rows/documentRow';
import OrangeCircularProgress from '../../ui/controls/circularProgress';

const ClientReports = ({ client }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const reports = useSelector((state) => state.client.clientReports);
  const loading = useSelector((state) => state.client.loadingClientReports);

  useEffect(() => {
    return () => {
      dispatch(clearClientReports());
    };
  }, [dispatch]);

  const handleOpen = () => {
    dispatch(getAdminClientReports(client._id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="accent"
        sx={{ color: 'white', border: 'none' }}
        onClick={() => handleOpen()}>
        Client Reports
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Client Reports</DialogTitle>
        <DialogContent>
          <Stack>
            {loading && <OrangeCircularProgress caption="Fetching Reports" />}
            {reports &&
              reports.map((report, index) => <DocumentRow document={report} key={index} />)}
            {!loading && reports && reports.length === 0 && (
              <Typography variant="body1" align="center">
                No reports available
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientReports;
