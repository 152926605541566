import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceProviders } from '../../../features/serviceProvider/serviceProvider.slice';
import { Box } from '@mui/material';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Chip from '@mui/material/Chip';

import UpdateStatusDialog from './updateStatusDialog';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../../hooks/titleProvider';

function ServiceProviders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();

  const serviceProviders = useSelector((state) => state.serviceProvider.providers);

  setTitle('Service Providers');

  useEffect(() => {
    dispatch(getServiceProviders());
  }, [dispatch]);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {serviceProviders &&
          serviceProviders.map(
            (sp) =>
              sp && (
                <FCard sx={{ maxWidth: '700px', width: '100%' }} key={sp._id}>
                  <FCardHeader
                    title={
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box>{sp.name}</Box>
                        <Box>
                          {sp.usingSftp && (
                            <Chip
                              label={'SFTP'}
                              size="small"
                              sx={{ backgroundColor: '#6A9C89', color: '#fff' }}
                            />
                          )}
                          {sp.usingApi && (
                            <Chip
                              label={'API'}
                              size="small"
                              sx={{ backgroundColor: '#419197', color: '#fff' }}
                            />
                          )}
                        </Box>
                      </Box>
                    }
                  />
                  <CardContent>{`Sync Status: ${sp.status}`}</CardContent>
                  <CardActions sx={{ width: '100%', justifyContent: 'flex-end' }}>
                    <UpdateStatusDialog serviceProvider={sp} />
                    <PrimaryButton onClick={() => navigate(`/admin/serviceproviders/${sp._id}`)}>
                      View Details
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => navigate(`/admin/serviceproviders/mappings/${sp._id}`)}>
                      Update Mappings
                    </PrimaryButton>
                  </CardActions>
                </FCard>
              )
          )}
      </Box>
    </Fragment>
  );
}

export default ServiceProviders;
