import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Box } from '@mui/material';

const OrangeCircularProgress = (props) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ m: 2 }}>
      <CircularProgress
        {...props}
        color="accent"
        variant="indeterminate"
        disableShrink
        size={40}
        thickness={4}
      />

      {props.caption ? <Typography component="p">{props.caption}</Typography> : null}
    </Box>
  );
};

export default OrangeCircularProgress;
