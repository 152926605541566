export const houseModelConstants = {
  GET_ALL_REQUEST: "GET_ALL_HOUSEMODEL_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_HOUSEMODEL_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_HOUSEMODEL_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_HOUSEMODEL_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_HOUSEMODEL_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_HOUSEMODEL_FAILURE",

  CREATE_REQUEST: "CREATE_HOUSEMODEL_REQUEST",
  CREATE_SUCCESS: "CREATE_HOUSEMODEL_SUCCESS",
  CREATE_FAILURE: "CREATE_HOUSEMODEL_FAILURE",

  UPDATE_REQUEST: "UPDATE_HOUSEMODEL_REQUEST",
  UPDATE_SUCCESS: "UPDATE_HOUSEMODEL_SUCCESS",
  UPDATE_FAILURE: "UPDATE_HOUSEMODEL_FAILURE",

  DELETE_REQUEST: "DELETE_HOUSEMODEL_REQUEST",
  DELETE_SUCCESS: "DELETE_HOUSEMODEL_SUCCESS",
  DELETE_FAILURE: "DELETE_HOUSEMODEL_FAILURE",

  GET_BYSERVICEPROVIDER_REQUEST: "GET_MODELS_BYSERVICEPROVIDER_REQUEST",
  GET_BYSERVICEPROVIDER_SUCCESS: "GET_MODELS_BYSERVICEPROVIDER_SUCCESS",
  GET_BYSERVICEPROVIDER_FAILURE: "GET_MODELS_BYSERVICEPROVIDER_FAILURE",

  GET_MAPPING_REQUEST: "GET_MAPPING_MODELS_REQUEST",
  GET_MAPPING_SUCCESS: "GET_MAPPING_MODELS_SUCCESS",
  GET_MAPPING_FAILURE: "GET_MAPPING_MODELS_FAILURE",

  UPDATE_MAPPING_REQUEST: "UPDATE_MAPPING_MODELS_REQUEST",
  UPDATE_MAPPING_SUCCESS: "UPDATE_MAPPING_MODELS_SUCCESS",
  UPDATE_MAPPING_FAILURE: "UPDATE_MAPPING_MODELS_FAILURE",
};
