import { Box, Button, DialogContentText, Step, StepLabel, Stepper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFundsForGuidance } from '../../../features/fund/fund.slice';
import {
  createFundFilter,
  createFundFilterAsync,
  resetFundFilter,
  updateFundFilter
} from '../../../features/fundFilter/fundFilter.slice';
import { getGuidanceToolData } from '../../../features/guidance/guidanceToolData.slice';
import { nextStep, prevStep, resetStepper } from '../../../features/guidance/stepper.slice';
import { getTaxFreeDataForGuidance } from '../../../features/taxFree/taxFreeData.slice';
import { getVehiclesForGuidance } from '../../../features/vehicle/vehicle.slice';
import Step1SelectProduct from './steps/step1SelectProduct';
import Step2SelectFund from './steps/step2SelectFund';
import Step3ClientDetails from './steps/step3ClientDetails';
import Step4Finalize from './steps/step4Finalize';
import CheckIcon from '@mui/icons-material/Check';
// Custom connector with background color
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#CC6323'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#CC6323'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  ...(ownerState.active
    ? {
        backgroundColor: '#CC6323'
      }
    : { backgroundColor: '#ccc' }),
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: '#CC6323'
  })
}));

const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }}>
      {completed ? <CheckIcon /> : <span>{icon}</span>}
    </CustomStepIconRoot>
  );
};

const steps = ['Select Product', 'Select Fund', 'Client Details', 'Complete'];

const GuidanceTool = () => {
  const { activeStep } = useSelector((state) => state.stepper);
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const fundFilterData = useSelector((state) => state.fundFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(fundFilterData).length === 0) {
      dispatch(resetStepper());
    }
  }, [fundFilterData, dispatch]);

  useEffect(() => {
    dispatch(getVehiclesForGuidance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFundsForGuidance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxFreeDataForGuidance());
  }, []);

  useEffect(() => {
    dispatch(getGuidanceToolData());
  }, []);

  const handleNext = async (data) => {
    if (data) {
      try {
        if (activeStep === 0) {
          await dispatch(createFundFilter(data));
        } else {
          await dispatch(updateFundFilter(data));
        }
        dispatch(nextStep());
      } catch (error) {
        console.error('Error processing submission:', error);
      }
    }
  };

  const handleSubmitData = async (data) => {
    if (data) {
      try {
        await dispatch(createFundFilterAsync(data));
        dispatch(nextStep());
      } catch (error) {
        console.error('Error processing submission:', error);
      }
    }
  };

  const handleBack = () => {
    dispatch(prevStep());
  };

  const handleReset = () => {
    setConfirmResetOpen(true);
  };

  const handleCloseReset = () => {
    setConfirmResetOpen(false);
  };

  const handleConfirmReset = () => {
    dispatch(resetStepper());
    dispatch(resetFundFilter());
    setConfirmResetOpen(false);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1SelectProduct onNext={handleNext} onBack={handleBack} onReset={handleReset} />;
      case 1:
        return <Step2SelectFund onNext={handleNext} onBack={handleBack} onReset={handleReset} />;
      case 2:
        return (
          <Step3ClientDetails onNext={handleSubmitData} onBack={handleBack} onReset={handleReset} />
        );
      case 3:
        return <Step4Finalize onNext={handleNext} onBack={handleBack} onReset={handleReset} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={(props) => <CustomStepIcon {...props} icon={index + 1} />}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ padding: 3 }}>{getStepContent(activeStep)}</Box>
      <Dialog
        open={confirmResetOpen}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Start Over?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start over?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button onClick={handleConfirmReset} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GuidanceTool;
