import TrashIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from '../../../../features/role/role.slice';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import SecondaryButton from '../../../ui/buttons/secondaryButton';
import PrimarySelect from '../../../ui/forms/fields/select';

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from '@mui/system';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { assignRoleToUser, revokeRoleFromUser } from '../../../../features/user/user.slice';
import Form from '../../../ui/forms';

const schema = yup.object().shape({
  role : yup.string().required(),
});

function RoleDetails({ user, onRoleAssigned, onRoleRevoked, ...props}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState(null);
    const [openAssignRole, setOpenAssignRole] = useState(false);
    const [openRevokeRole, setOpenRevokeRole] = useState(false);

    const roles = useSelector((state) => state.role.roles);
    const loadingRoles = useSelector((state) => state.role.loading);
    const assigningRole = useSelector((state) => state.user.assigningRole);

    const { control, handleSubmit, formState: { errors } } = useForm({
      mode: "onBlur",
      resolver: yupResolver(schema)
    });

    useEffect(() => {
      dispatch(getAllRoles());
    }, [dispatch]);


    const openAssignRoleDialog = () => {
      setOpenAssignRole(true);
    };

    const onAssign = (data) => {
      dispatch(assignRoleToUser({user: user._id, ...data})).unwrap().then(() => {
        setOpenAssignRole(false);
        onRoleAssigned();
      });
    };

    const onRevoke = () => {
      dispatch(revokeRoleFromUser({user: user._id, role: selectedRole._id})).unwrap().then(() => {
        setOpenRevokeRole(false);
        setSelectedRole(null);
        onRoleRevoked();
      });
    }

    const handleRevokeRole = (role) => {
      setSelectedRole(role);
      setOpenRevokeRole(true);
    }

    const assignRoleDialog = () => {
        return (
            <Dialog open={openAssignRole} onClose={() => setOpenAssignRole(false)} fullWidth maxWidth={'sm'}>
                <DialogTitle>Assign role to {user.firstName} {user.lastName}</DialogTitle>
                <DialogContent>
                  <Form>
                    { !loadingRoles ? 
                    <PrimarySelect name="role" control={control} >
                      {roles && roles.map((role) => (
                        role.hidden ? null :
                        <MenuItem key={role._id} value={role._id}>
                          <Box display="flex" flexDirection={'column'}>
                            <Typography variant="h6">{role.name}</Typography>
                            <Typography variant="caption">{role.description}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </PrimarySelect>
                    : <Typography variant="body1">Loading Roles...</Typography> }
                  </Form>
                </DialogContent>
                <DialogActions>
                  { assigningRole ? <Typography variant="body1">Assigning Role...</Typography> : <>
                      <PrimaryButton onClick={() => setOpenAssignRole(false)}>Cancel</PrimaryButton>
                      <PrimaryButton onClick={handleSubmit(onAssign)}>
                          Assign
                      </PrimaryButton>
                    </> 
                  }
                </DialogActions>
            </Dialog>
        )
    }

    const revokeRoleDialog = () => {
        return (
          selectedRole &&
            <Dialog open={openRevokeRole} onClose={() => setOpenRevokeRole(false)} fullWidth maxWidth={'sm'}>
                <DialogTitle>Are you sure you want to revoke {selectedRole.name} from {user.firstName} {user.lastName}?</DialogTitle>
                <DialogContent>
                  <Typography variant="body1">Revoking a role will remove all permissions associated with that role.</Typography>
                </DialogContent>
                <DialogActions>
                  <PrimaryButton onClick={() => setOpenRevokeRole(false)}>Cancel</PrimaryButton>
                  <PrimaryButton onClick={onRevoke}>
                      Revoke
                  </PrimaryButton>
                </DialogActions>
            </Dialog>
        )
    }

    return (
      <>
       <SecondaryButton onClick={openAssignRoleDialog}>Assign Role</SecondaryButton>
        <Table aria-label="simple table">
        <TableBody>
         
          {user.roles.map((role) => (
            <TableRow
              key={role._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {role.name}
              </TableCell>
              <TableCell align="right">{role.description}</TableCell>
              <TableCell align="right">
                <SecondaryButton size={'small'} color={'error'} sx={{ m:0 }} onClick={() => handleRevokeRole(role)}>
                  <TrashIcon />
                </SecondaryButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      { assignRoleDialog() }
      { revokeRoleDialog() }
      </>
    );
}

export default RoleDetails;