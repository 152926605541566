import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientService } from './client.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = {
  client: null,
  account: null,
  daily: {
    balances: [],
    loading: false,
    loadingClientInfo: false
  }
};

export const getAll = createAsyncThunk('client/getAll', async (_, thunkAPI) => {
  return await clientService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getById = createAsyncThunk('client/getById', async (id, thunkAPI) => {
  return await clientService
    .getById(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateClient = createAsyncThunk('client/updateClient', async (data, thunkAPI) => {
  return await clientService
    .update(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess(response.msg));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getClientsByAdvisor = createAsyncThunk('client/getByAdvisor', async (_, thunkAPI) => {
  return await clientService
    .getByAdvisor()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getBalances = createAsyncThunk('client/getBalances', async (id, thunkAPI) => {
  return await clientService
    .getBalances(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getDaily = createAsyncThunk('client/getDaily', async (data, thunkAPI) => {
  return await clientService
    .getDaily(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      return thunkAPI.rejectWithValue(err.msg);
    });
});

export const search = createAsyncThunk('client/search', async (data, thunkAPI) => {
  return await clientService
    .search(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const searchByAdvisor = createAsyncThunk(
  'client/searchByAdvisor',
  async (data, thunkAPI) => {
    return await clientService
      .searchByAdvisor(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getClientUsers = createAsyncThunk('client/getClientUsers', async (id, thunkAPI) => {
  return await clientService
    .getUsers(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const addClientUser = createAsyncThunk('client/addClientUser', async (data, thunkAPI) => {
  return await clientService
    .addUser(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess(response.msg));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const removeClientUser = createAsyncThunk(
  'client/removeClientUser',
  async (data, thunkAPI) => {
    return await clientService
      .removeUser(data.clientId, data.userId)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess(response.msg));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getClientReports = createAsyncThunk('client/getClientReports', async (_, thunkAPI) => {
  return await clientService
    .getClientReports()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getAdminClientReports = createAsyncThunk(
  'client/getClientReports',
  async (clientId, thunkAPI) => {
    return await clientService
      .getAdminClientReports(clientId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getClientAffiliations = createAsyncThunk(
  'client/getClientAffiliations',
  async (_, thunkAPI) => {
    return await clientService
      .getClientAffiliations()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    clearSearch(state) {
      state.results = [];
      state.searching = false;
    },
    clearAccount(state) {
      state.account = null;
      state.client = null;
    },
    clearClientReports(state) {
      state.clientReports = [];
      state.loadingClientReports = false;
    }
  },
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.clients = action.payload.clients;
    },
    [getAll.pending]: (state) => {
      state.loading = true;
    },
    [getAll.rejected]: (state) => {
      state.loading = false;
    },
    [getById.fulfilled]: (state, action) => {
      state.client = action.payload.client;
      state.loadingClientInfo = false;
    },
    [getById.pending]: (state) => {
      state.loadingClientInfo = true;
    },
    [getById.rejected]: (state) => {
      state.loadingClientInfo = false;
    },
    [updateClient.fulfilled]: (state, action) => {
      state.client = action.payload.client;
      state.loadingClientInfo = false;
    },
    [updateClient.pending]: (state) => {
      state.loadingClientInfo = true;
    },
    [updateClient.rejected]: (state) => {
      state.loadingClientInfo = false;
    },
    [getBalances.fulfilled]: (state, action) => {
      state.account = action.payload;
      state.loadingBalances = false;
    },
    [getBalances.pending]: (state) => {
      state.loadingBalances = true;
    },
    [getBalances.rejected]: (state) => {
      state.loadingBalances = false;
    },
    [getDaily.fulfilled]: (state, action) => {
      state.daily.balances = action.payload.daily;
      state.daily.loading = false;
    },
    [getDaily.pending]: (state) => {
      state.daily.loading = true;
    },
    [getDaily.rejected]: (state) => {
      state.daily.loading = false;
    },
    [getClientsByAdvisor.fulfilled]: (state, action) => {
      state.clients = action.payload.clients;
    },
    [getClientsByAdvisor.pending]: (state) => {
      state.loading = true;
    },
    [getClientsByAdvisor.rejected]: (state) => {
      state.loading = false;
    },
    [search.fulfilled]: (state, action) => {
      state.results = action.payload.results;
      state.searching = false;
    },
    [search.pending]: (state) => {
      state.searching = true;
    },
    [search.rejected]: (state) => {
      state.searching = false;
    },
    [searchByAdvisor.fulfilled]: (state, action) => {
      state.results = action.payload.results;
      state.searching = false;
    },
    [searchByAdvisor.pending]: (state) => {
      state.searching = true;
    },
    [searchByAdvisor.rejected]: (state) => {
      state.searching = false;
    },
    [getClientUsers.fulfilled]: (state, action) => {
      state.client.loading = false;
      state.client.users = action.payload.users;
    },
    [getClientUsers.pending]: (state) => {
      state.client.loading = true;
    },
    [getClientUsers.rejected]: (state) => {
      state.client.loading = false;
    },
    [addClientUser.fulfilled]: (state, action) => {
      state.client.users.push(action.payload.user);
      state.client.loading = false;
    },
    [addClientUser.pending]: (state) => {
      state.client.loading = true;
    },
    [addClientUser.rejected]: (state) => {
      state.client.loading = false;
    },
    [removeClientUser.fulfilled]: (state, action) => {
      state.client.users = action.payload.users;
      state.client.loading = false;
    },
    [removeClientUser.pending]: (state) => {
      state.client.loading = true;
    },
    [removeClientUser.rejected]: (state) => {
      state.client.loading = false;
    },
    [getClientReports.fulfilled]: (state, action) => {
      state.loadingClientReports = false;
      state.clientReports = action.payload.reports;
    },
    [getClientReports.pending]: (state) => {
      state.loadingClientReports = true;
    },
    [getClientReports.rejected]: (state) => {
      state.loadingClientReports = false;
    },
    [getAdminClientReports.fulfilled]: (state, action) => {
      state.loadingClientReports = false;
      state.clientReports = action.payload.reports;
    },
    [getAdminClientReports.pending]: (state) => {
      state.loadingClientReports = true;
    },
    [getAdminClientReports.rejected]: (state) => {
      state.loadingClientReports = false;
    },
    [getClientAffiliations.fulfilled]: (state, action) => {
      state.clientAffiliations = action.payload.affiliations;
      state.loadingClientAffiliations = false;
    },
    [getClientAffiliations.pending]: (state) => {
      state.loadingClientAffiliations = true;
    },
    [getClientAffiliations.rejected]: (state) => {
      state.loadingClientAffiliations = false;
    }
  }
});

export const { clearSearch, clearAccount, clearClientReports } = clientSlice.actions;

export default clientSlice.reducer;
