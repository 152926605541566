import { config } from "../config";
import { authHeader } from "../helpers";
import axios from "axios";

export const communicationService = {
  getContacts,
  createList,
  getLists,
  getEmails,
  createEmail,
};

function getContacts() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.communication_server}/contacts`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getLists() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.communication_server}/lists`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function createList(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.communication_server}/lists`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getEmails() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.communication_server}/email`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function createEmail(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.communication_server}/email`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
