import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ButtonBase, LinearProgress, Link, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { formatBytes } from '../../../../helpers/helpers';

function FileDropzone({ name, label, loading, progress, file, defaultValue, ...props }) {
  const theme = useTheme();
  const [droppedFile, setDroppedFile] = useState();
  const {
    register,
    unregister,
    setValue,
    resetField,
    formState: { errors }
  } = useFormContext();

  const onDrop = useCallback(async (droppedFile) => {
    setDroppedFile(droppedFile[0]);
    const formData = new FormData();
    formData.append('file', droppedFile[0]);

    props.onUpload(formData);
  }, []);

  const handleRemove = (file) => {
    setDroppedFile(null);
    resetField(name, { keepDirty: true, keepError: true });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useEffect(() => {
    if (defaultValue) {
      //setFile(defaultValue);
      setValue(name, defaultValue, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [defaultValue]);

  return (
    <Fragment>
      <Typography sx={{ mt: 1, mb: 2 }} variant={'h5'}>
        {label}
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          padding: theme.spacing(3),
          border: 'dashed 2px',
          borderColor: theme.palette.primary.main
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        id={name}>
        {droppedFile && droppedFile.path ? (
          <Fragment>
            {!loading ? (
              <>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: '1em'
                  }}>
                  Uploaded Files
                </Typography>
                <Typography>
                  {droppedFile.path} - {formatBytes(droppedFile.size)}{' '}
                  <ButtonBase onClick={() => handleRemove(file?._id)}>
                    <Link>Remove</Link>
                  </ButtonBase>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: '1em'
                  }}>
                  Uploading File
                </Typography>
                {droppedFile.path} - {formatBytes(droppedFile.size)}{' '}
                <Box sx={{ width: '100%', mt: 2 }}>
                  <LinearProgress variant="determinate" value={progress || 0} color={'primary'} />
                </Box>
              </>
            )}
          </Fragment>
        ) : file && file._id ? (
          <>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '1em'
              }}>
              Uploaded File
            </Typography>
            <Typography>
              {file.fileName}{' '}
              <ButtonBase onClick={() => handleRemove(file?._id)}>
                <Link>Remove</Link>
              </ButtonBase>
            </Typography>
          </>
        ) : (
          <>
            <input {...props} {...getInputProps({ multiple: false })} />
            <CloudUploadIcon fontSize="large" />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop files here, or click to select files</Typography>
            )}
          </>
        )}
        {errors[name] && (
          <Typography sx={{ pt: 2 }} color={'error'}>
            {errors[name].message}
          </Typography>
        )}
      </Box>
    </Fragment>
  );
}

export default FileDropzone;
