import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles } from '../../../features/role/role.slice';
import { DialogContent, DialogTitle, Box, CardActions } from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import AssignUserFunctions from './components/assignUserFunctions';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../../hooks/titleProvider';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import FDialog from '../../ui/fairtreeComponents/dialog/fDialog';

function Roles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();

  setTitle('Roles');

  const roles = useSelector((state) => state.role.roles);
  const [openUF, setOpenUF] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const handleUFOpen = (role) => {
    setSelectedRole(role);
    setOpenUF(true);
  };

  const handleUFClose = () => {
    dispatch(getAllRoles());
    setSelectedRole({});
    setOpenUF(false);
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '100%', maxWidth: '700px' }}>
          <PrimaryButton onClick={() => navigate('/admin/roles/create')}>Create Role</PrimaryButton>
          {roles
            ? roles.map((role) => (
                <FCard key={role._id} sx={{ mb: 2 }}>
                  <FCardHeader title={role.name} subheader={role.description} />

                  <CardActions
                    disableSpacing
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                    {' '}
                    <PrimaryButton size="small" onClick={() => handleUFOpen(role)}>
                      (
                      {role.userFunctions.length > 0
                        ? role.userFunctions.filter((uf) => uf.value > 0).length
                        : 0}
                      ) Update
                    </PrimaryButton>
                  </CardActions>
                </FCard>
              ))
            : null}
        </Box>
      </Box>

      <FDialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openUF}
        onClose={handleUFClose}
        aria-labelledby="uf-dialog-title">
        <DialogTitle id="uf-dialog-title" onClose={handleUFClose}>
          {selectedRole && `${selectedRole.name} User Functions`}
        </DialogTitle>
        <DialogContent>
          {selectedRole && <AssignUserFunctions role={selectedRole} onClose={handleUFClose} />}
        </DialogContent>
      </FDialog>
    </React.Fragment>
  );
}

export default Roles;
