import { entityConstants } from "../constants";
import { entityService } from "../services";
import { alertActions } from "./";

export const entityActions = {
  getAll,
  getById,
  create,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return entityService.getAll().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: entityConstants.GET_ALL_REQUEST };
  }
  function success(result) {
    return { type: entityConstants.GET_ALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: entityConstants.GET_ALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return entityService.getById(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: entityConstants.GET_BYID_REQUEST };
  }
  function success(result) {
    return { type: entityConstants.GET_BYID_SUCCESS, result };
  }
  function failure(error) {
    return { type: entityConstants.GET_BYID_FAILURE, error };
  }
}

function create(newEntity) {
  return (dispatch) => {
    dispatch(request());

    return entityService.create(newEntity).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: entityConstants.CREATE_REQUEST };
  }
  function success(result) {
    return { type: entityConstants.CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: entityConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return entityService.update(id, data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: entityConstants.UPDATE_REQUEST };
  }
  function success(result) {
    return { type: entityConstants.UPDATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: entityConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return entityService.remove(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: entityConstants.DELETE_REQUEST };
  }
  function success(result) {
    return { type: entityConstants.DELETE_SUCCESS, result };
  }
  function failure(error) {
    return { type: entityConstants.UPDATE_FAILURE, error };
  }
}
