import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import { CardActions, CardContent, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import FCardHeader from '../fairtreeComponents/card/fCardHeader';
import FCard from '../fairtreeComponents/card/fCard';
import FCardCurrencyTypography from '../fairtreeComponents/card/fCardCurrencyTypography';
import FCardTypography from '../fairtreeComponents/card/fCardTypography';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProductCard = ({ product, model, accountNumber, administrator, closingDate, onClick }) => {
  const theme = useTheme();

  return (
    <FCard onClick={onClick} raised={false}>
      <FCardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ lineHeight: 1, fontWeight: 'bold' }}>{product}</Typography>
            <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
          </Box>
        } //accountNumber
        subheader={
          <Box display="flex" flexDirection={'column'}>
            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              Administered by {administrator}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '8px' }}>
              {accountNumber}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Box display="flex" flexDirection={'column'} width="100%">
          <Typography
            variant="p"
            component="small"
            fontWeight={500}
            color={theme.palette.accent.main}
            textAlign="right">
            {moment(closingDate).format('MMMM YYYY')} Closing
          </Typography>
          <Box display="flex" flexGrow={1} justifyContent={'space-between'} alignItems={'center'}>
            <FCardTypography sx={{ textTransform: 'capitalize' }}>
              {model.name.toLowerCase()}
            </FCardTypography>

            <FCardCurrencyTypography>{model.closing}</FCardCurrencyTypography> 
          </Box>
        </Box>
      </CardContent>
    </FCard>
  );
};

ProductCard.propTypes = {
  product: propTypes.string.isRequired,
  model: propTypes.object.isRequired,
  accountNumber: propTypes.string.isRequired,
  administrator: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  closingDate: propTypes.string.isRequired
};

export default ProductCard;
