import { authActions } from '../redux/actions';

export const hasAccess = (user, accessFunction, accessMapValue) => {
  if (user && user.roles) {
    const ufIndex = user.roles.findIndex((uf) => uf.key === accessFunction);

    if (ufIndex !== -1) {
      const mappingValueBinary = binSplit(user.roles[ufIndex].access);
      const mapIndex = mappingValueBinary.findIndex((m) => m === accessMapValue);
      if (mapIndex !== -1) {
        return true;
      }
    }
    return false;
  }
};

export const AccessMap = {
  Create: 1,
  Read: 2,
  Update: 4,
  Delete: 8,
  Execute: 16
};

export const UserFunctions = {
  GeneralAccess: 'GeneralAccess',
  AdminView: 'AdminView',
  ServiceProviders: 'ServiceProviders',
  ApplicaitonSettings: 'ApplicaitonSettings',
  TransactionTypes: 'TransactionTypes',
  Models: 'Models',
  Reports: 'Reports',
  Products: 'Products',
  Funds: 'Funds',
  Goals: 'Goals',
  Users: 'Users',
  Roles: 'Roles',
  AdvisorDetails: 'AdvisorDetails',
  InvestorDetails: 'InvestorDetails',
  SubmitTransactions: 'SubmitTransactions',
  Documents: 'Documents',
  EmployeeBenefits: 'EmployeeBenefits',
  EmployeeBenefitsAdmin: 'EmployeeBenefitsAdmin',
  SystemAdmin: 'SystemAdmin',
  UserFunctions: 'UserFunctions',
  Entities: 'Entities',
  ClientsAdmin: 'ClientAdmin',
  AdvisorAdmin: 'AdvisorAdmin'
};

const binSplit = (number, bits = 5) => {
  let unit = 1 << bits;
  const result = new Array(number >> bits).fill(unit);
  while (unit >= 1) {
    unit >>= 1;
    if (number & unit) result.push(unit);
  }
  return result;
};
