import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CardContent, Paper, Typography, useTheme } from '@mui/material';
import ClientAccountCard from '../partials/clientAccountCard';

import { useTitle } from '../../../hooks/titleProvider';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';

function AccountSettings() {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);

  const { setTitle } = useTitle();
  setTitle('Account Settings');

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" mb={3}>
      {user.client && user.client.length > 0 ? (
        <FCard>
          <FCardHeader title={'Client Account'} />
          <CardContent>
            <ClientAccountCard />
          </CardContent>
        </FCard>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(3),
            margin: theme.spacing(2)
          }}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: theme.spacing(2)
            }}>
            Client Account
          </Typography>
          <Typography variant="body1">
            You do not have a client account. Please contact your administrator to create one.
          </Typography>
        </Paper>
      )}
    </Box>
  );
}

export default AccountSettings;
