//Create redux slice for advisorProfile
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { advisorProfileService } from './advisorProfile.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = {};

export const getByUserId = createAsyncThunk(
  'advisorProfile/getByUserId',
  async (userId, thunkAPI) => {
    return await advisorProfileService
      .getByUserId(userId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const getByProviderId = createAsyncThunk(
  'advisorProfile/getByProviderId',
  async (providerId, thunkAPI) => {
    return await advisorProfileService
      .getByProviderId(providerId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const getIFACodes = createAsyncThunk('advisorProfile/getIFACodes', async (thunkAPI) => {
  return await advisorProfileService
    .getIFACodes()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const registerAdvisor = createAsyncThunk(
  'advisorProfile/register',
  async (advisorProfile, thunkAPI) => {
    return await advisorProfileService
      .register(advisorProfile)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Successfully registered advisor profile'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateAdvisor = createAsyncThunk(
  'advisorProfile/update',
  async (advisorProfile, thunkAPI) => {
    return await advisorProfileService
      .update(advisorProfile)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Successfully updated advisor profile'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const removeAdvisor = createAsyncThunk('advisorProfile/remove', async (id, thunkAPI) => {
  return await advisorProfileService
    .remove(id)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Successfully removed advisor profile'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const advisorProfileSlice = createSlice({
  name: 'advisorProfile',
  initialState,
  reducers: {},
  extraReducers: {
    [getByUserId.fulfilled]: (state, action) => {
      state.advisorProfile = action.payload.advisorProfile;
      state.loading = false;
    },
    [getByUserId.pending]: (state) => {
      state.loading = true;
    },
    [getByUserId.rejected]: (state) => {
      state.loading = false;
    },
    [getByProviderId.pending]: (state) => {
      state.loading = true;
    },
    [getByProviderId.fulfilled]: (state, action) => {
      state.advisorProfiles = action.payload.advisorProfiles;
      state.loading = false;
    },
    [getByProviderId.rejected]: (state) => {
      state.loading = false;
    },
    [getIFACodes.pending]: (state) => {
      state.loading = true;
    },
    [getIFACodes.fulfilled]: (state, action) => {
      state.ifaCodes = action.payload.ifaCodes;
      state.loading = false;
    },
    [getIFACodes.rejected]: (state) => {
      state.loading = false;
    },
    [registerAdvisor.pending]: (state) => {
      state.loading = true;
    },
    [registerAdvisor.fulfilled]: (state, action) => {
      state.loading = false;
      state.advisorProfile = action.payload.advisorProfile;
    },
    [registerAdvisor.rejected]: (state) => {
      state.loading = false;
    },
    [updateAdvisor.pending]: (state) => {
      state.loading = true;
    },
    [updateAdvisor.fulfilled]: (state, action) => {
      state.loading = false;
      state.advisorProfile = action.payload.advisorProfile;
    },
    [updateAdvisor.rejected]: (state) => {
      state.loading = false;
    },
    [removeAdvisor.pending]: (state) => {
      state.loading = true;
    },
    [removeAdvisor.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeAdvisor.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export default advisorProfileSlice.reducer;
