import React, { Fragment, useState } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';

import { updateSyncStatusServiceProvider } from '../../../features/serviceProvider/serviceProvider.slice';

import Form from '../../ui/forms';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, MenuItem, Typography } from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import PrimarySelect from '../../ui/forms/fields/select';
import FDialog from '../../ui/fairtreeComponents/dialog/fDialog';

const schema = yup.object().shape({
  status: yup.string().required()
});

function UpdateStatusDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const sp = props.serviceProvider;

  const loading = useSelector((state) => state.serviceProvider.loadingStatus);

  const { handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    dispatch(updateSyncStatusServiceProvider({ id: sp._id, data }))
      .unwrap()
      .then((responce) => {
        handleClose();
      });
  };

  return (
    <Fragment>
      <PrimaryButton onClick={handleClickOpen}>Update Sync Status</PrimaryButton>
      <FDialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">Update Sync status for {sp.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the status of the syncing process for this service provider.
          </DialogContentText>
          <Form>
            <PrimarySelect
              id="status"
              name="status"
              label="Status"
              control={control}
              defaultValue={sp.status}
              error={errors?.status}>
              <MenuItem key="Running" value="Running">
                Running
              </MenuItem>
              <MenuItem key="Done" value="Done">
                Done
              </MenuItem>
            </PrimarySelect>
          </Form>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Typography>Updating...</Typography>
          ) : (
            <PrimaryButton onClick={handleSubmit(onSubmit)}>Update Status</PrimaryButton>
          )}
        </DialogActions>
      </FDialog>
    </Fragment>
  );
}

export default UpdateStatusDialog;
