import { productConstants } from "../constants";

export function product(state = {}, action) {
  switch (action.type) {
    case productConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case productConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        products: action.products.products,
        success: action.products.success,
        loading: false,
      };
    case productConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case productConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case productConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        product: action.products.product,
        success: action.products.success,
        loading: false,
      };
    case productConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case productConstants.CREATE_REQUEST:
      return { ...state, loading: true };
    case productConstants.CREATE_SUCCESS:
      return {
        ...state,
        product: action.product.product,
        success: action.product.success,
        loading: false,
      };
    case productConstants.CREATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case productConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case productConstants.UPDATE_SUCCESS:
      return {
        ...state,
        product: action.product.product,
        success: action.product.success,
        loading: false,
      };
    case productConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case productConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case productConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.response.success,
        loading: false,
      };
    case productConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case productConstants.GET_BYSERVICEPROVIDER_REQUEST:
      return { ...state };
    case productConstants.GET_BYSERVICEPROVIDER_SUCCESS:
      return {
        ...state,
        spProducts: action.products.spProducts,
        success: action.products.success,
      };
    case productConstants.GET_BYSERVICEPROVIDER_FAILURE:
      return { ...state, error: action.error };

    case productConstants.GET_MAPPING_REQUEST:
      return { ...state };
    case productConstants.GET_MAPPING_SUCCESS:
      return {
        ...state,
        productMappings: action.result.productMappings,
        success: action.result.success,
      };
    case productConstants.GET_MAPPING_FAILURE:
      return { ...state, error: action.error };

    case productConstants.UPDATE_MAPPING_REQUEST:
      return { ...state };
    case productConstants.UPDATE_MAPPING_SUCCESS:
      return {
        ...state,
        productMapping: action.result.productMapping,
        success: action.result.success,
      };
    case productConstants.UPDATE_MAPPING_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
