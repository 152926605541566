export const fundConstants = {
    GET_FUNDS_REQUEST: 'GET_FUNDS_REQUEST',
    GET_FUNDS_SUCCESS: 'GET_FUNDS_SUCCESS',
    GET_FUNDS_FAILURE: 'GET_FUNDS_FAILURE',

    GET_FUND_REQUEST: 'GET_FUND_REQUEST',
    GET_FUND_SUCCESS: 'GET_FUND_SUCCESS',
    GET_FUND_FAILURE: 'GET_FUND_FAILURE',
    
    CREATE_FUND_REQUEST: 'CREATE_FUND_REQUEST',
    CREATE_FUND_SUCCESS: 'CREATE_FUND_SUCCESS',
    CREATE_FUND_FAILURE: 'CREATE_FUND_FAILURE',
};