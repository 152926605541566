import React, { useEffect } from 'react';
import FCard from '../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../ui/fairtreeComponents/card/fCardHeader';
import { CardActions, CardContent, Box } from '@mui/material';

import DocumentRow from '../ui/rows/documentRow';

import DashExample from '../../resources/images/dashexample.png';
import { useDispatch, useSelector } from 'react-redux';
import { getClientReports } from '../../features/client/client.slice';

const ClientReports = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.client.clientReports);
  const loading = useSelector((state) => state.client.loadingClientReports);

  useEffect(() => {
    dispatch(getClientReports());
  }, [dispatch]);

  return (
    <Box display={'flex'} flexDirection={'row'} gap={3}>
      {/* <Box
        component="img"
        sx={{
          maxWidth: 800,
          borderRadius: '10px'
        }}
        alt={'Example Dashboard'}
        src={DashExample}
      /> */}
      <FCard>
        <FCardHeader
          title={`Reports`}
          sx={{
            '.MuiCardHeader-subheader': {
              textTransform: 'capitalize'
            }
          }}
        />
        <CardContent>
          {loading && <p>Loading...</p>}
          {reports && reports.map((report, index) => <DocumentRow document={report} key={index} />)}
        </CardContent>
        <CardActions disableSpacing sx={{ width: '100%' }}></CardActions>
      </FCard>
    </Box>
  );
};

export default ClientReports;
