import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';
import { serviceProviderActions } from '../../../redux/actions';

import {
  getServiceProviderById,
  updateServiceProvider,
  resetServiceProvider
} from '../../../features/serviceProvider/serviceProvider.slice';

import Form from '../../ui/forms';

import { MenuItem, Typography } from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import PrimarySelect from '../../ui/forms/fields/select';
import Checkbox from '../../ui/forms/fields/checkbox';
import Input from '../../ui/forms/fields/input';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import OrangeCircularProgress from '../../ui/controls/circularProgress';

const schema = yup.object().shape({
  usingApi: yup.boolean(),
  name: yup.string().required(),
  notes: yup.string(),
  contactEmail: yup.string().email(),
  contactName: yup.string(),
  usingSftp: yup.boolean(),
  sftpHost: yup.string(),
  sftpPort: yup.number(),
  sftpUsername: yup.string(),
  sftpPassword: yup.string(),
  sftpDefaultPath: yup.string(),
  calculateOpeningValues: yup.boolean(),
  run: yup.boolean(),
  seperateTransactionFile: yup.boolean(),
  zipBaseFile: yup.boolean(),
  syncFreq: yup.string(),
  SftpIgnoreFolders: yup.array().of(yup.string())
});

function ServiceProviderDetails(props) {
  const dispatch = useDispatch();
  const serviceProviderId = useParams().id;

  const serviceProvider = useSelector((state) => state.serviceProvider.provider);
  const loading = useSelector((state) => state.serviceProvider.loading);

  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    reset();
    dispatch(getServiceProviderById(serviceProviderId));

    return () => {
      reset();
    };
  }, [dispatch, reset, serviceProviderId]);

  const onSubmit = (data) => {
    dispatch(updateServiceProvider({ id: serviceProvider._id, data }))
      .unwrap()
      .then((responce) => {});
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      {!loading && serviceProvider ? (
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxWidth={'700px'}>
            <Typography variant="h4" gutterBottom>
              {serviceProvider.name}
            </Typography>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Checkbox
                id="usingApi"
                name="usingApi"
                label="Using API"
                control={control}
                value={serviceProvider.usingApi}
                disabled
              />
              <Checkbox
                id="usingSftp"
                name="usingSftp"
                label="Using SFTP"
                control={control}
                value={serviceProvider.usingSftp}
                disabled
              />
              <Input
                label="Notes"
                type="textarea"
                defaultValue={serviceProvider.notes}
                error={errors?.notes}
                disabled
                {...register('notes')}
              />

              <Input
                label="Contact Email"
                defaultValue={serviceProvider.contactEmail}
                error={errors?.contactEmail}
                disabled
                {...register('contactEmail')}
              />
              <Input
                label="Contact Name"
                defaultValue={serviceProvider.contactName}
                error={errors?.contactName}
                disabled
                {...register('contactName')}
              />

              {/* <Input
              label="SFTP Host"
              defaultValue={serviceProvider.sftpHost}
              error={errors?.sftpHost}
              disabled
              {...register('sftpHost')}
            />
            <Input
              label="SFTP Port"
              defaultValue={serviceProvider.sftpPort}
              error={errors?.sftpPort}
              disabled
              {...register('sftpPort')}
            />
            <Input
              label="SFTP Username"
              defaultValue={serviceProvider.sftpUsername}
              error={errors?.sftpUsername}
              disabled
              {...register('sftpUsername')}
            />
            <Input
              label="SFTP Password"
              defaultValue={serviceProvider.sftpPassword}
              error={errors?.sftpPassword}
              disabled
              {...register('sftpPassword')}
            />
            <Input
              label="SFTP Default Path"
              defaultValue={serviceProvider.sftpDefaultPath}
              error={errors?.sftpDefaultPath}
              disabled
              {...register('sftpDefaultPath')}
            /> */}

              <Checkbox
                id="calculateOpeningValues"
                name="calculateOpeningValues"
                label="Calculate Opening Values"
                control={control}
                disabled
                value={serviceProvider.calculateOpeningValues}
              />
              <Checkbox
                id="run"
                name="run"
                label="Enable automatic import."
                control={control}
                disabled
                value={serviceProvider.run}
              />
              <Checkbox
                id="seperateTransactionFile"
                name="seperateTransactionFile"
                label="Seperate Transaction File"
                control={control}
                disabled
                value={serviceProvider.seperateTransactionFile}
              />
              <Checkbox
                id="zipBaseFile"
                name="zipBaseFile"
                label="The base file is a Zip file."
                control={control}
                disabled
                value={serviceProvider.zipBaseFile}
              />

              <PrimarySelect
                id="syncFreq"
                name="syncFreq"
                label="Sync Frequency"
                control={control}
                defaultValue={serviceProvider.syncFreq}
                error={errors?.syncFreq}
                disabled>
                <MenuItem key="Daily" value="Daily">
                  Daily
                </MenuItem>
                <MenuItem key="Weekly" value="Weekly">
                  Weekly
                </MenuItem>
                <MenuItem key="Monthly" value="Monthly">
                  Monthly
                </MenuItem>
              </PrimarySelect>

              {/* <PrimaryButton>Update</PrimaryButton> */}
            </Form>
          </Box>
        </Box>
      ) : (
        <OrangeCircularProgress caption={'Loading Service Provider Details...'} />
      )}
    </Box>
  );
}

export default ServiceProviderDetails;
