import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const ReportProgress = ({ jobId }) => {
  let wsUrl = `ws://localhost:3005/ws?type=job&jobId=${jobId}`;

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      wsUrl = `ws://portal.fairtree.com/ws?type=job&jobId=${jobId}`;
      break;
    case 'staging':
      wsUrl = `ws://staging.fairtreewealth.co.za/ws?type=job&jobId=${jobId}`;
      break;
    default:
      wsUrl = `ws://localhost:3005/ws?type=job&jobId=${jobId}`;
  }

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => {},
    onClose: () => {},
    onError: (error) => console.log('WebSocket error', error),
    shouldReconnect: (closeEvent) => true // Reconnect on close
  });

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (jobId) {
      switch (readyState) {
        case ReadyState.OPEN: {
          sendJsonMessage({
            event: 'subscribe',
            data: {
              channel: 'portal-reports'
            }
          });
          break;
        }
        case ReadyState.CLOSED: {
          break;
        }
        default:
          break;
      }
    }
  }, [readyState, sendJsonMessage, jobId]);

  useEffect(() => {
    console.log(lastJsonMessage);
    if (lastJsonMessage && lastJsonMessage.progress) {
      const progress = lastJsonMessage.progress;
      if (progress !== undefined) {
        setProgress(progress);
        setMessage(`Progress: ${progress}%`);
      }
    }
  }, [lastJsonMessage]);

  return (
    progress > 0 && (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        my={2}>
        <Typography variant="h4" gutterBottom>
          Report Progress
        </Typography>
        <LinearProgress
          variant="determinate"
          color="accent"
          value={progress}
          style={{ width: '80%' }}
        />
        {progress === 100 ? (
          <Typography variant="body1" style={{ marginTop: '20px' }}>
            Ready for download.
          </Typography>
        ) : (
          <Typography variant="body1" style={{ marginTop: '20px' }}>
            {message}
          </Typography>
        )}
      </Box>
    )
  );
};

export default ReportProgress;
