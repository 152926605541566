import { accountConstants } from "../constants";

export function account(state = {}, action) {
  switch (action.type) {
    case accountConstants.SUMMARY_REQUEST:
      return {
        ...state,
      };
    case accountConstants.SUMMARY_SUCCESS:
      return {
        summary: action.summary.summary,
        success: action.summary.success,
        ...state,
      };
    case accountConstants.SUMMARY_FAILURE:
      return { error: action.error, ...state };
    case accountConstants.DAILY_REQUEST:
      return {
        ...state,
      };
    case accountConstants.DAILY_SUCCESS:
      return {
        daily: action.daily.daily,
        success: action.daily.success,
        ...state,
      };
    case accountConstants.DAILY_FAILURE:
      return { error: action.error, ...state };
    case accountConstants.DAILY_LIST_REQUEST:
      return { ...state };
    case accountConstants.DAILY_LIST_SUCCESS:
      return {
        dailyList: action.dailyList.dailyList,
        success: action.dailyList.success,
        ...state,
      };
    case accountConstants.DAILY_LIST_FAILURE:
      return { error: action.error, ...state };

    case accountConstants.TRANSACTIONS_REQUEST:
      return {
        ...state,
      };
    case accountConstants.TRANSACTIONS_SUCCESS:
      return {
        transactions: action.transactions.transactions,
        success: action.transactions.success,
        ...state,
      };
    case accountConstants.TRANSACTIONS_FAILURE:
      return { error: action.error, ...state };

    case accountConstants.STATEMENTS_REQUEST:
      return {
        ...state,
      };
    case accountConstants.STATEMENTS_SUCCESS:
      return {
        statements: action.result.statements,
        success: action.result.success,
        ...state,
      };
    case accountConstants.STATEMENTS_FAILURE:
      return { error: action.error, ...state };

    case accountConstants.CLEAR_REQUEST:
      return {
        loading: true,
      };
    case accountConstants.CLEAR_SUCCESS:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
