import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStatements, resetStatments } from '../../../../features/account/account.slice';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';
import DocumentRow from '../../../ui/rows/documentRow';

const Statements = ({ accountNumber }) => {
  const dispatch = useDispatch();
  const statements = useSelector((state) => state.account.statements);
  const loadingStatements = useSelector((state) => state.account.loadingStatements);

  useEffect(() => {
    dispatch(loadStatements(accountNumber));

    return () => {
      dispatch(resetStatments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, dispatch]);

  const handleLoadStatements = () => {
    dispatch(loadStatements(accountNumber));
  };

  return (
    <>
      {statements &&
        statements.length > 0 &&
        statements.map((document, index) => <DocumentRow document={document} key={index} />)}
      {statements && statements.length > 0 && (
        <Box display={'flex'} justifyContent={'center'}>
          <PrimaryButton onClick={() => handleLoadStatements()}>Show More</PrimaryButton>
        </Box>
      )}
      {loadingStatements && <OrangeCircularProgress caption="Loading account statements..." />}
    </>
  );
};

export default Statements;
