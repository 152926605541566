import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const advisorService = {
  getCodes,
  addCode,
  removeCode,
  getProviders
};

function getCodes() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.advisor}/codes`, requestOptions).then((response) => {
    return response.data;
  });
}

function addCode(data) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.post(`${config.advisor}/codes`, data, requestOptions).then((response) => {
    return response.data;
  });
}

function removeCode(code) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.advisor}/codes/${code}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function getProviders() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.advisor}/providers`, requestOptions).then((response) => {
    return response.data;
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
