import React, { Fragment } from 'react';
import MainMenuItems from '../ui/mainMenuItems';

function Admin() {
  return (
    <Fragment>
      <MainMenuItems />
    </Fragment>
  );
}

export default Admin;
