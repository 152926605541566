import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { accountService } from './account.service';
import { setAlertError } from '../alert/alert.slice';
import { auth } from '../auth/auth.slice';

const initialState = {};

export const getBalances = createAsyncThunk('account/getSummary', async (clientId, thunkAPI) => {
  await thunkAPI.dispatch(auth());

  return await accountService
    .getSummary(clientId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getDaily = createAsyncThunk('account/getDaily', async (data, thunkAPI) => {
  await thunkAPI.dispatch(auth());
  return await accountService
    .getDaily(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const listDailyAccounts = createAsyncThunk(
  'account/listDailyAccounts',
  async (clientId, thunkAPI) => {
    await thunkAPI.dispatch(auth());
    return await accountService
      .listDailyAccounts(clientId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getTransactions = createAsyncThunk('account/getTransactions', async (thunkAPI) => {
  await thunkAPI.dispatch(auth());
  return await accountService
    .getTransactions()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getTransactionsByAccount = createAsyncThunk(
  'account/getTransactionsByAccount',
  async (data, thunkAPI) => {
    await thunkAPI.dispatch(auth());

    return await accountService
      .getTransactionsByAccount(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getStatements = createAsyncThunk('account/getStatements', async (data, thunkAPI) => {
  await thunkAPI.dispatch(auth());
  return await accountService
    .getStatements(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getStatementsFilter = createAsyncThunk(
  'account/getStatementsFilter',
  async (data, thunkAPI) => {
    await thunkAPI.dispatch(auth());
    return await accountService
      .getStatementsFilter(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getStatementsCount = createAsyncThunk(
  'account/getStatementsCount',
  async (data, thunkAPI) => {
    await thunkAPI.dispatch(auth());
    return await accountService
      .getStatementsCount(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const executeClientDataReport = createAsyncThunk(
  'account/executeClientDataReport',
  async (data, thunkAPI) => {
    await thunkAPI.dispatch(auth());
    return await accountService
      .executeClientDataReport(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const loadStatements = createAsyncThunk('account/loadStatements', async (data, thunkAPI) => {
  await thunkAPI.dispatch(auth());
  const { statements } = thunkAPI.getState().account;
  const offset = statements ? statements.length : 0;
  const accountId = data;

  return await accountService
    .loadStatements({ accountId, offset })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clear: (state) => {
      state = initialState;
    },
    resetStatments: (state) => {
      state.statements = [];
    }
  },
  extraReducers: {
    [getBalances.fulfilled]: (state, action) => {
      state.balances = action.payload.balances;
      state.accountTotal = action.payload.accountTotal;
      state.accountInceptionTotal = action.payload.accountInceptionTotal;
      state.loadingBalances = false;
    },
    [getBalances.pending]: (state, action) => {
      state.loadingBalances = true;
    },
    [getBalances.rejected]: (state, action) => {
      state.error = action.err;
      state.loadingBalances = false;
    },
    [getDaily.fulfilled]: (state, action) => {
      state.daily = action.payload.daily;
      state.loading = false;
    },
    [getDaily.pending]: (state, action) => {
      state.loading = true;
    },
    [getDaily.rejected]: (state, action) => {
      state.error = action.err;
      state.loading = false;
    },
    [listDailyAccounts.fulfilled]: (state, action) => {
      state.dailyList = action.payload?.dailyList;
      state.dailyLoading = false;
    },
    [listDailyAccounts.pending]: (state, action) => {
      state.dailyLoading = true;
    },
    [listDailyAccounts.rejected]: (state, action) => {
      state.error = action.err;
      state.dailyLoading = false;
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.transactions = action.payload;
      state.loading = false;
    },
    [getTransactions.pending]: (state, action) => {
      state.loading = true;
    },
    [getTransactions.rejected]: (state, action) => {
      state.error = action.err;
      state.loading = false;
    },
    [getTransactionsByAccount.fulfilled]: (state, action) => {
      state.transactions = action.payload;
      state.loading = false;
    },
    [getTransactionsByAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getTransactionsByAccount.rejected]: (state, action) => {
      state.error = action.err;
      state.loading = false;
    },
    [getStatements.fulfilled]: (state, action) => {
      state.statements = action.payload.statements;
      state.loadingStatements = false;
    },
    [getStatements.pending]: (state, action) => {
      state.loadingStatements = true;
    },
    [getStatements.rejected]: (state, action) => {
      state.error = action.err;
      state.loadingStatements = false;
    },
    [getStatementsFilter.fulfilled]: (state, action) => {
      state.statements = action.payload.statements;
      state.loadingStatements = false;
    },
    [getStatementsFilter.pending]: (state, action) => {
      state.loadingStatements = true;
    },
    [getStatementsFilter.rejected]: (state, action) => {
      state.error = action.err;
      state.loadingStatements = false;
    },
    [loadStatements.fulfilled]: (state, action) => {
      if (state.statements && state.statements.length > 0) {
        state.statements = [
          ...state.statements,
          ...action.payload.statements.filter(
            (newStatement) =>
              !state.statements.some(
                (existingStatement) => existingStatement._id === newStatement._id
              )
          )
        ];
      } else {
        state.statements = action.payload.statements;
      }
      state.loadingStatements = false;
    },
    [loadStatements.pending]: (state, action) => {
      state.loadingStatements = true;
    },
    [loadStatements.rejected]: (state, action) => {
      state.error = action.err;
      state.loadingStatements = false;
    },
    [getStatementsCount.fulfilled]: (state, action) => {
      state.statementsCount = action.payload.statementsCount;
      state.loadingStatementsCount = false;
    },
    [getStatementsCount.pending]: (state, action) => {
      state.loadingStatementsCount = true;
    },
    [getStatementsCount.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loadingStatementsCount = false;
    },
    [executeClientDataReport.fulfilled]: (state, action) => {
      state.clientDataReport = action.payload;
      state.loadingClientDataReport = false;
    },
    [executeClientDataReport.pending]: (state, action) => {
      state.loadingClientDataReport = true;
    },
    [executeClientDataReport.rejected]: (state, action) => {
      state.error = action.err;
      state.loadingClientDataReport = false;
    }
  }
});

export const { clear, resetStatments } = accountSlice.actions;

export default accountSlice.reducer;
