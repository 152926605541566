import React, { Fragment, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

import PrimaryButton from '../../../../components/ui/buttons/primaryButton';
import Form from '../../../../components/ui/forms';
import Checkbox from '../../../../components/ui/forms/fields/checkbox';
import Select from '../../../../components/ui/forms/fields/select';
import RadioGroup from '../../../../components/ui/forms/fields/radioGroup';
import CurrencyInput from '../../../../components/ui/forms/fields/currencyInput';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControlLabel,
  MenuItem,
  Radio,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  DialogContentText,
  IconButton,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material';
import { Box } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import PDFView from './pdfView';

import { ErrorMessage } from '@hookform/error-message';

import { useNavigate } from 'react-router-dom';
import { fundFilterCalculation } from '../../../utils/calculations';
import { selectGuidanceToolData } from '../../../../features/guidance/guidanceToolData.slice';

const useStyles = makeStyles((theme) => ({}));

const Row = (props) => {
  const { fund, amounts, isSelected } = props;
  const [selected, setSelected] = useState(false);
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchema = yup.object().shape(
    {
      selected: yup.bool(),
      lumpsum: yup.number().when('monthly', {
        is: (monthly) => !monthly || monthly === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            (fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum) - 1,
            `Lumpsum must be more than R ${
              fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum
            } once off`
          )
          .typeError('Lump Sum must be a number'),
        otherwise: yup
          .number()
          .typeError('Lump Sum must be a number')
          .when('lumpsum', {
            is: (lumpsum) => lumpsum > 0,
            then: yup.number().typeError('Lump Sum must be a number')
          })
      }),
      monthly: yup.number().when('lumpsum', {
        is: (lumpsum) => !lumpsum || lumpsum === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            props.minimums?.monthly - 1,
            `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
          )
          .typeError('Monthly Contribution must be a number'),
        otherwise: yup
          .number()
          .typeError('Monthly Contribution must be a number')
          .when('monthly', {
            is: (monthly) => monthly !== 0,
            then: yup
              .number()
              .typeError('Monthly Contribution must be a number')
              .moreThan(
                props.minimums?.monthly - 1,
                `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
              )
          })
      })
    },
    ['lumpsum', 'monthly']
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      lumpsum: amounts?.lumpsum && amounts.lumpsum > 0 ? amounts.lumpsum : 0,
      monthly: amounts?.monthly && amounts.monthly > 0 ? amounts.monthly : 0
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  const cbSelectedWatch = watch('selected');
  useEffect(() => {
    onChange();
  }, [cbSelectedWatch]);

  useEffect(() => {
    if (isSelected) {
      setValue('selected', true);
    }
  }, []);

  const onChange = async () => {
    if (await trigger(['selected', 'lumpsum', 'monthly'])) handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    props.onChange(data);
  };

  return (
    <Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        // onClick={(event) => handleClick(event, fund)}
        role="checkbox"
        aria-checked={selected}
        tabIndex={-1}
        selected={selected}>
        <TableCell>
          <Checkbox
            name="selected"
            color="primary"
            label=""
            control={control}
            onChange={onChange}
          />
        </TableCell>
        <TableCell padding="normal">100%</TableCell>
        <TableCell padding={'normal'}>
          {fund.name} {fund.code && `(${fund.code})`}
        </TableCell>

        {!mobile && (
          <>
            <TableCell>{fund.time}</TableCell>
            <TableCell>{fund.assetHoldings}</TableCell>
            <TableCell>{fund.regionalHoldings}</TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={watch('selected')} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1, display: 'flex' }}
              flexDirection={mobile ? 'column' : 'row'}
              justifyContent={'space-evenly'}>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={amounts?.lumpsum && amounts.lumpsum > 0 ? amounts.lumpsum : 0}
                name="lumpsum"
                className={classes.input}
                onChange={onChange}
              />

              {fund.hasMonthlyContributions && (
                <CurrencyInput
                  control={control}
                  defaultValue={amounts?.monthly && amounts.monthly > 0 ? amounts.monthly : 0}
                  name="monthly"
                  className={classes.input}
                  type="number"
                  id="monthly"
                  label="Monthly Contribution"
                  symbol="R"
                  error={!!errors.monthly}
                  helperText={errors?.monthly?.message}
                  onChange={onChange}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

function HelpRetirementAnnuity(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  const totalSteps = 6;

  const [selectedFunds, setSelectedFunds] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const [withdrawNeedWarning, setWithdrawNeedWarning] = React.useState(false);

  const [qualifyingFunds, setQualifyingFunds] = useState([]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const funds = useSelector((state) =>
    state.fund.guidanceFunds.filter((v) => v.useForRetirementAnnuityHelp)
  );

  const guidanceData = useSelector(selectGuidanceToolData);

  const { NeedToWithdrawListRA, RiskDescriptionsList, RiskProfileTypes } = guidanceData || {};

  const validationSchema = yup.object().shape(
    {
      currentAge: yup.number().required('Please specify your current age.'),
      retirementAge: yup
        .number()
        .moreThan(
          yup.ref('currentAge'),
          'The retirement age can not be smaller than your current age.'
        )
        .required('Please specify on what age are you planing to retire.'),
      lumpsum: yup.number().when('monthly', {
        is: (monthly) => !monthly || monthly === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            props.minimums?.lumpsum - 1,
            `Lump Sum must be more than R ${props.minimums?.lumpsum} once off`
          )
          .typeError('Lump Sum must be a number'),
        otherwise: yup
          .number()
          .typeError('Lump Sum must be a number')
          .when('lumpsum', {
            is: (lumpsum) => lumpsum > 0,
            then: yup.number().typeError('Lump Sum must be a number')
            // .moreThan(
            //   props.minimums?.lumpsum - 1,
            //   `Lumpsum must be more than R ${props.minimums?.lumpsum} once off`
            // ),
          })
      }),
      monthly: yup.number().when('lumpsum', {
        is: (lumpsum) => !lumpsum || lumpsum === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            props.minimums?.monthly - 1,
            `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
          )
          .typeError('Monthly Contribution must be a number'),
        otherwise: yup
          .number()
          .typeError('Monthly Contribution must be a number')
          .when('monthly', {
            is: (monthly) => monthly !== 0,
            then: yup
              .number()
              .typeError('Monthly Contribution must be a number')
              .moreThan(
                props.minimums?.monthly - 1,
                `Monthly Contribution must be more than R ${props.minimums?.monthly} p/m`
              )
          })
      }),
      withdrawNeed: yup.string().required('Please select one.'),
      riskDesc: yup.string().required('Please select one.'),
      riskRelated: yup.string().required('Please select the one you relate the most to.'),
      riskRelatedScore: yup.number(),
      funds: yup.array().min(1, 'Please select at least one fund.'),
      acknowledge: yup
        .boolean()
        .default(false)
        .oneOf([true], 'Please acknowledge disclaimer to continue.')
    },
    ['lumpsum', 'monthly']
  );

  const {
    register,
    reset,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      lumpsum: 0,
      monthly: 0
    },
    mode: ['all'],
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    register('riskRelatedScore');
    register('funds');
    register('acknowledge');
  }, []);

  useEffect(() => {
    setValue('funds', selectedFunds, { shouldValidate: true });
  }, [selectedFunds, setValue]);

  const watchScore = Math.round(watch('riskRelatedScore') * 100) / 100;
  const watchLumpsum = watch('lumpsum');
  useEffect(() => {
    const lumpsumValue = getValues('lumpsum');
    setQualifyingFunds(
      funds.filter((f) => {
        if (
          f.useForRetirementAnnuityHelp.minPoints <= watchScore &&
          f.useForRetirementAnnuityHelp.maxPoints >= watchScore
        ) {
          return f;
        }
      })
    );
  }, [watchScore, watchLumpsum]);

  const withdrawNeed = watch('withdrawNeed');
  useEffect(() => {
    const wn = NeedToWithdrawListRA.types.find((n) => n.desc === withdrawNeed);
    if (wn?.warning) setWithdrawNeedWarning(true);
    else setWithdrawNeedWarning(false);
  }, [withdrawNeed]);

  const calculate = () => {
    let ageValues = {};
    let withdrawValues = {};
    let riskValues = {};
    let riskProfileValues = {};

    const currentValues = getValues();

    if (currentValues.currentAge && currentValues.retirementAge) {
      const ageVal = currentValues.retirementAge - currentValues.currentAge;
      ageValues.weight = 10;
      if (ageVal < 2) ageValues.points = 1;
      else if (ageVal >= 2 && ageVal < 3) ageValues.points = 2;
      else if (ageVal >= 3 && ageVal < 5) ageValues.points = 3;
      else if (ageVal >= 5 && ageVal < 7) ageValues.points = 4;
      else if (ageVal >= 7) ageValues.points = 5;
    }

    if (currentValues.withdrawNeed && currentValues.withdrawNeed !== '') {
      withdrawValues.weight = NeedToWithdrawListRA.weight;
      withdrawValues.points = NeedToWithdrawListRA.types.find(
        (x) => x.desc === currentValues.withdrawNeed
      ).points;
    }

    if (currentValues.riskDesc && currentValues.riskDesc !== '') {
      riskValues.weight = 10;
      riskValues.points = RiskDescriptionsList.types.find(
        (x) => x.desc === currentValues.riskDesc
      ).points;
    }

    if (currentValues.riskRelated && currentValues.riskRelated !== '') {
      riskProfileValues.weight = RiskProfileTypes.weight;
      riskProfileValues.points = RiskProfileTypes.types.find(
        (x) => x.name === currentValues.riskRelated
      ).points;
    }

    if (
      ageValues.weight &&
      withdrawValues.weight &&
      riskValues.weight &&
      riskProfileValues.weight
    ) {
      const result = fundFilterCalculation(
        ageValues,
        withdrawValues,
        riskValues,
        riskProfileValues
      );

      setValue('riskRelatedScore', result, { shouldValidate: true });
    }
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = async (validate) => {
    const isValid = await trigger(validate);
    const data = await getValues();

    if (isLastStep() && isValid) {
      handleDisclaimer();
    } else {
      if (isValid) {
        calculate();

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.onHelpNext({ ...data, lastActiveStep: activeStep, formSlug: 'ra_invest_help' });
        clearErrors();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFundSelect = (data, fund) => {
    let currentFunds = [...selectedFunds];

    if (!data.selected) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);
      currentFunds.splice(fundIndex, 1);
    }

    if (data.selected && currentFunds) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);

      if (fundIndex !== -1) {
        currentFunds[fundIndex].lumpsum = data.lumpsum;
        currentFunds[fundIndex].monthly = data.monthly;
      } else {
        currentFunds.push({
          fund,
          lumpsum: data.lumpsum,
          monthly: data.monthly
        });
      }
    }

    setSelectedFunds(currentFunds);
  };

  const onSubmit = (data) => {
    data.formSlug = 'ra_invest_help';
    props.onDataSubmit({ ...data, lastActiveStep: activeStep });
    setOpen(false);
  };

  const getAmounts = () => {
    const amounts = {
      lumpsum: getValues('lumpsum'),
      monthly: getValues('monthly')
    };

    return amounts;
  };

  const NavButtons = ({ index, totalSteps, validate }) => {
    return (
      <Box
        sx={{
          position: 'sticky',
          bottom: 110,
          display: 'flex',
          alignItems: 'center',
          m: 2,
          mb: 4,
          width: 'fit-content',
          ml: 'auto',
          justifyContent: 'flex-end',
          backgroundColor: theme.palette.background.default,
          zIndex: 1000
        }}
        display="flex"
        justifyContent="flex-end">
        <PrimaryButton disabled={index === 1} onClick={handleStartOver} sx={{ mt: 1, mr: 1 }}>
          Start Over
        </PrimaryButton>
        <PrimaryButton disabled={index === 1} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </PrimaryButton>
        <PrimaryButton
          disabled={qualifyingFunds.length <= 0 && isLastStep()}
          onClick={() => handleNext(validate)}
          sx={{
            mt: 1,
            mr: 1,
            backgroundColor: '#CC6323',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#b35920'
            }
          }}>
          {index === totalSteps ? 'Submit' : 'Continue'}
        </PrimaryButton>
      </Box>
    );
  };

  const handleStartOver = () => {
    setConfirmResetOpen(true);
  };

  const handleCloseReset = () => {
    setConfirmResetOpen(false);
  };

  const handleConfirmReset = () => {
    reset();
    setActiveStep(0);
    setConfirmResetOpen(false);
  };

  const ages = (max) => {
    let ages = [];

    for (let i = 0; i < max; i++) {
      ages.push(i + 1);
    }

    return ages;
  };

  const handleDisclaimer = () => {
    setOpenDisclaimer(true);
  };

  const closeDisclaimer = async () => {
    setOpenDisclaimer(false);
  };

  const acknowledgeDisclaimer = () => {
    setValue('acknowledge', true, { shouldValidate: true });

    handleSubmit(onSubmit)();
    setOpenDisclaimer(false);
  };

  return (
    <Fragment>
      <Box pb={6} pt={4}>
        <Typography variant="h2" sx={{ color: '#CC6323', fontWeight: 500 }}>
          Retirement Investment Options
        </Typography>
      </Box>
      <Form>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>How much are you looking to invest?</StepLabel>

            <StepContent>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={0}
                name="lumpsum"
                className={classes.input}
              />
              <CurrencyInput
                control={control}
                defaultValue={0}
                name="monthly"
                className={classes.input}
                type="number"
                id="monthly"
                label="Debit Order"
                symbol="R"
                error={!!errors.monthly}
                helperText={errors?.monthly?.message}
              />
              <NavButtons index={1} totalSteps={6} validate={['lumpsum', 'monthly']} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Retirement</StepLabel>
            <StepContent>
              <Select
                name={'currentAge'}
                control={control}
                error={!!errors.currentAge}
                helperText={errors.currentAge?.message}
                label="What is your current age?"
                defaultValue={'25'}>
                {ages(95).map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Select
                name={'retirementAge'}
                control={control}
                error={!!errors.retirementAge}
                helperText={errors.retirementAge?.message}
                label="What age do you plan on retiring?"
                defaultValue={'65'}>
                {ages(95).map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <NavButtons index={2} totalSteps={6} validate={['currentAge', 'retirementAge']} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your need to withdraw</StepLabel>
            <StepContent>
              <Typography>
                Will you need to withdraw from your investment before you reach the age of 55 or are
                you able to stay invested until then?
              </Typography>
              <RadioGroup
                control={control}
                aria-label="withdrawNeed"
                name="withdrawNeed"
                error={!!errors?.withdrawNeed}
                helperText={errors?.withdrawNeed?.message}
                xs={{ margin: 1 }}>
                {NeedToWithdrawListRA.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                    sx={mobile ? { mt: 1, mb: 1 } : {}}
                  />
                ))}
              </RadioGroup>

              <Box display="flex" justifyContent={'center'} sx={{ p: 3 }}>
                {withdrawNeedWarning ? (
                  <Typography sx={{ color: '#CC6323' }} variant="h4" textAlign={'center'}>
                    {NeedToWithdrawListRA.warning}
                  </Typography>
                ) : (
                  <Typography sx={{ color: '#CC6323' }} variant="h4">
                    Great, then a Retirement Annuity is what you’re looking for.
                  </Typography>
                )}
              </Box>

              <NavButtons index={3} totalSteps={6} validate={'withdrawNeed'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Risk</StepLabel>
            <StepContent>
              <Typography>RISK EXPLANATION WHEN INVESTING:</Typography>
              <Typography>
                Risk can be defined as the possibility of something not turning out the way you
                would have liked or differently to how you previously supposed. The 'risk-return
                trade-off' means: the higher the investment's level of risk, the higher the
                potential return and vice versa. This could mean potentially losing some of your
                initial capital invested, but could also mean greater potential for growth (as your
                investment value can move up or down). The degree to how much it fluctuates is
                dependent on the asset classes you invest in. When looking at your investment
                options, it's important to consider how much time you have to invest, your unique
                financial situation and how much risk you're willing to take on.
              </Typography>
              <Typography>
                When you think of 'risk', what word best describes it for you?
              </Typography>
              <RadioGroup
                control={control}
                aria-label="riskDesc"
                name="riskDesc"
                error={!!errors?.riskDesc}
                helperText={errors?.riskDesc?.message}
                xs={{ margin: 1 }}>
                {RiskDescriptionsList.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                  />
                ))}
              </RadioGroup>
              <NavButtons index={4} totalSteps={6} validate={'riskDesc'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Investor Profile</StepLabel>
            <StepContent>
              <RadioGroup
                control={control}
                aria-label="riskRelated"
                name="riskRelated"
                error={!!errors?.riskRelated}
                helperText={errors?.riskRelated?.message}
                xs={{ margin: 1 }}>
                {!mobile ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Withdrawing</TableCell>
                        <TableCell>Total Savings</TableCell>
                        <TableCell>Investment Goal</TableCell>
                        <TableCell>Risk Appetite</TableCell>
                        <TableCell>Portfolio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {RiskProfileTypes.types.map((item, key) => (
                        <TableRow key={key}>
                          <TableCell width="200px">
                            <FormControlLabel
                              value={item.name}
                              control={<Radio />}
                              color="primary"
                              label={item.name}
                            />
                          </TableCell>
                          <TableCell>{item.withdrawing}</TableCell>
                          <TableCell>{item.totalSavings}</TableCell>
                          <TableCell>{item.investmentGoal}</TableCell>
                          <TableCell>{item.riskAppetite}</TableCell>
                          <TableCell>{item.portfolio}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  RiskProfileTypes.types.map((item, key) => (
                    <FormControlLabel
                      value={item.name}
                      control={<Radio />}
                      color="primary"
                      label={item.name}
                    />
                  ))
                )}
              </RadioGroup>
              <NavButtons index={5} totalSteps={6} validate={'riskRelated'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Fund Selection</StepLabel>
            <StepContent>
              {qualifyingFunds.length > 0 ? (
                <>
                  {' '}
                  <Table size="small" padding={mobile ? 'none' : 'normal'} sx={{ mt: 1, mb: 2 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{''}</TableCell>
                        <TableCell padding="normal">
                          <Typography variant="subtitle2">%</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Investment Solution</Typography>
                        </TableCell>

                        {!mobile && (
                          <>
                            <TableCell>
                              <Typography variant="subtitle2">Time</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Asset Holdings</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Regional Holdings</Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {qualifyingFunds.map((fund, index) => (
                        <Row
                          key={index}
                          fund={fund}
                          onChange={(data) => handleFundSelect(data, fund)}
                          minimums={props.minimums || null}
                          amounts={getAmounts()}
                          isSelected={selectedFunds.some(
                            (item) => item.fund.name === fund.name && item.fund._id === fund._id
                          )}
                        />
                      ))}
                    </TableBody>
                  </Table>
                  <Box>
                    Score:{''}
                    {watchScore}
                  </Box>
                </>
              ) : (
                <Typography variant="h4" sx={{ color: '#CC6323' }}>
                  We do not have any funds or investment solutions available that match your
                  investment objectives. Please consider revising your investment time horizon or
                  investment risk profile
                </Typography>
              )}
              <Box>
                {isLastStep() && qualifyingFunds.length > 0 && (
                  <ErrorMessage
                    errors={errors}
                    name="funds"
                    render={({ message }) => <Typography color="error">{message}</Typography>}
                  />
                )}
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <ErrorMessage
                  errors={errors}
                  name="acknowledge"
                  render={({ message }) => <Typography color="error">{message}</Typography>}
                />
              </Box>
              <NavButtons index={6} totalSteps={6} validate={'funds'} />
            </StepContent>
          </Step>
        </Stepper>
      </Form>

      <Dialog open={openDisclaimer} onClose={closeDisclaimer}>
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I acknowledge that this tool is a factual filter of published information relevant to
            each investment solution's fact sheet. It does not make a recommendation or render
            advice to an investor, but merely provides factual information. For advice on the
            suitability of these products to the investor's financial goals and objectives, we
            strongly recommend consulting an approved financial advisor. Please note the disclosures
            relevant to each product displayed on this page as published on their fact sheets.
            Fairtree Asset Management is an authorised financial services provider (FSP 25917).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={closeDisclaimer} color="primary">
            Close
          </PrimaryButton>
          <PrimaryButton onClick={acknowledgeDisclaimer} color="primary">
            Acknowledge
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmResetOpen}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Start Over?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start over?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button onClick={handleConfirmReset} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default HelpRetirementAnnuity;
