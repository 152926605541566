import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { roleService } from './role.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = {};

export const getAllRoles = createAsyncThunk('role/getAll', async (_, thunkAPI) => {
  return await roleService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getRoleById = createAsyncThunk('role/getById', async (id, thunkAPI) => {
  return await roleService
    .getById(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const createRole = createAsyncThunk('role/create', async (role, thunkAPI) => {
  return await roleService
    .create(role)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Role created successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;

      if (err && err.msg) {
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      } else {
        if (error) {
          thunkAPI.dispatch(setAlertError(err));
          thunkAPI.rejectWithValue(err);
        }
      }
    });
});

export const updateRole = createAsyncThunk('role/update', async (role, thunkAPI) => {
  return await roleService
    .update(role)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Role updated successfully'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload.roles;
    },
    [getAllRoles.rejected]: (state, action) => {
      state.loading = false;
    },
    [getRoleById.pending]: (state, action) => {
      state.loading = true;
    },
    [getRoleById.fulfilled]: (state, action) => {
      state.loading = false;
      state.role = action.payload.role;
    },
    [getRoleById.rejected]: (state, action) => {
      state.loading = false;
    },
    [createRole.pending]: (state, action) => {
      state.loading = true;
    },
    [createRole.fulfilled]: (state, action) => {
      state.role = action.payload.role;
      state.loading = false;
    },
    [createRole.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateRole.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRole.fulfilled]: (state, action) => {
      state.role = action.payload.role;
      state.loading = false;
    },
    [updateRole.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});
