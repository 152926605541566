import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import TransactioMapping from './components/transactionMapping';
import ProductMapping from './components/productMapping';
import HouseModelMapping from './components/houseModelMapping';

import { useTitle } from '../../../hooks/titleProvider';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceProviderById } from '../../../features/serviceProvider/serviceProvider.slice';
import { Container } from '@mui/system';
import FTabs from '../../ui/fairtreeComponents/tab/fTabs';
import FTab from '../../ui/fairtreeComponents/tab/fTab';
import FTabPanel from '../../ui/fairtreeComponents/tab/fTabPanel';

// const TabPanel = (props) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}>
//       {value === index && <Box p={3}>{children}</Box>}
//     </div>
//   );
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`
//   };
// }

const Mappings = () => {
  const [value, setValue] = React.useState(0);
  const serviceProviderId = useParams().id;
  const dispatch = useDispatch();
  let serviceProvider = useSelector((state) => state.serviceProvider.provider);

  const { setTitle } = useTitle();

  useEffect(() => {
    if (serviceProviderId) {
      dispatch(getServiceProviderById(serviceProviderId));
    }
  }, [serviceProviderId, dispatch]);

  useEffect(() => {
    if (serviceProvider) {
      setTitle(`Update Mappings for ${serviceProvider.name}`);
    }
  }, [serviceProvider, setTitle]);

  const tabs = [
    {
      title: 'Transaction Mapping',
      content: <TransactioMapping />
    },
    {
      title: 'Product Mapping',
      content: <ProductMapping />
    },
    {
      title: 'Model Mapping',
      content: <HouseModelMapping />
    }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mb: 3 }}>
      <Box sx={{ mb: 3 }}>
        <FTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
          {tabs.map((tab, index) => (
            <FTab label={tab.title} index={IDBIndex} />
          ))}
        </FTabs>
      </Box>

      {tabs.map((tab, index) => (
        <FTabPanel value={value} index={index}>
          {tab.content}
        </FTabPanel>
      ))}
    </Container>
  );
};

export default Mappings;
