import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { hasAccess, UserFunctions, AccessMap } from '../../helpers/access';
import AuthLayout from './authLayout';

const AuthRoute = (props) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  return user ? (
    <Navigate to={'/home'} />
  ) : (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default AuthRoute;
