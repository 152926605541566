import * as React from 'react';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const StyledCardHeader = styled(CardHeader)(({ theme }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return {
    '.MuiCardHeader-title': {
      fontSize: isSmallScreen ? '0.8rem' : '1rem'
    },
    '.MuiCardHeader-subheader': {
      fontSize: isSmallScreen ? '0.6rem' : '0.7rem'
    }
  };
});

export default function FCardHeader(props) {
  return <StyledCardHeader {...props}>{props.children}</StyledCardHeader>;
}
