import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const clientService = {
  getAll,
  getById,
  update,
  getBalances,
  getByAdvisor,
  getDaily,
  search,
  searchByAdvisor,
  getUsers,
  addUser,
  removeUser,
  getClientReports,
  getClientAffiliations,
  getAdminClientReports
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.client_server}/`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.client_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function update(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.client_server}/${data.id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getByAdvisor() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.client_server}/advisor`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getBalances(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/${id}/balances`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getDaily(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/${accountId}/daily`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function search(query) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.client_server}/search`, query, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function searchByAdvisor(query) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.client_server}/search/advisor`, query, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getUsers(clientId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/${clientId}/users`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function addUser(clientId, userEmail) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.client_server}/${clientId}/users`, { userEmail }, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function removeUser(clientId, userId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.client_server}/${clientId}/users/${userId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getClientReports() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/client_reports`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getAdminClientReports(clientId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/client_reports_admin/${clientId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getClientAffiliations() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.client_server}/affiliations`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
