import { transactionConstants } from "../constants";

export function transaction(state = {}, action) {
  switch (action.type) {
    case transactionConstants.GET_ALL_REQUEST:
      return {};
    case transactionConstants.GET_ALL_SUCCESS:
      return {
        transactions: action.transactions.transactions,
        success: action.transactions.success,
      };
    case transactionConstants.GET_ALL_FAILURE:
      return { error: action.error };

    case transactionConstants.GET_BYID_REQUEST:
      return {};
    case transactionConstants.GET_BYID_SUCCESS:
      return {
        transaction: action.transaction.transaction,
        success: action.transaction.success,
      };
    case transactionConstants.GET_BYID_FAILURE:
      return { error: action.error };

    case transactionConstants.GET_BY_ACCOUNT_REQUEST:
      return {};
    case transactionConstants.GET_BY_ACCOUNT_SUCCESS:
      return {
        transactions: action.transactions.transactions,
        fees: action.transactions.fees,
        success: action.transactions.success,
        account: action.transactions.account,
      };
    case transactionConstants.GET_BY_ACCOUNT_FAILURE:
      return { error: action.error };

    case transactionConstants.GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_REQUEST:
      return {};
    case transactionConstants.GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_SUCCESS:
      return {
        transactionTypes: action.transactionTypes.transactionTypes,
        success: action.transactionTypes.success,
      };
    case transactionConstants.GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}
