import { config } from '../config';
import { authHeader } from '../helpers';
import axios from 'axios';

export const accountService = {
  getSummary,
  getTransactions,
  getDaily,
  listDailyAccounts,
  getStatements
};

function getSummary() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axios.get(`${config.account_server}/summary`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getDaily(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axios
    .get(`${config.account_server}/daily/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function listDailyAccounts(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axios.get(`${config.account_server}/daily`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getTransactions() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axios.get(`${config.account_server}/transactions`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getStatements(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axios
    .get(`${config.account_server}/statements/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
