import { clientConstants } from '../constants';

export function client(state = {}, action) {
  switch (action.type) {
    case clientConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case clientConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        clients: action.result.clients,
        success: action.result.success,
        loading: false
      };
    case clientConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clientConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case clientConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        client: action.result.client,
        success: action.result.success,
        loading: false
      };
    case clientConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case clientConstants.GET_BALANCES_REQUEST:
      return { ...state, loading: true };
    case clientConstants.GET_BALANCES_SUCCESS:
      return {
        ...state,
        clientBalances: action.result.balances,
        success: action.result.success,
        loading: false
      };
    case clientConstants.GET_BALANCES_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
