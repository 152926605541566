import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const mddService = {
  getAll,
  getById,
  create,
  update,
  upload,
  remove
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.mdd}/`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.mdd}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.post(`${config.mdd}/`, data, requestOptions).then((responce) => {
    return responce.data;
  });
}

function update(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.put(`${config.mdd}/${id}`, data, requestOptions).then((responce) => {
    return responce.data;
  });
}

function upload(formData, onUploadProgress) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/mixed', onUploadProgress, ...authHeader() }
  };

  return axiosInstance.post(`${config.mdd}/upload`, formData, requestOptions).then((responce) => {
    console.log(responce.data);
    return responce.data;
  });
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.delete(`${config.mdd}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}
