import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  CardActions,
  CardContent,
  Paper,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../../features/user/user.slice";
import OrangeCircularProgress from "../../ui/controls/circularProgress";
import RoleDetails from "./components/roleDetails";
import FTab from "../../ui/fairtreeComponents/tab/fTab";
import FTabs from "../../ui/fairtreeComponents/tab/fTabs";
import FTabPanel from "../../ui/fairtreeComponents/tab/fTabPanel";
import FCard from "../../ui/fairtreeComponents/card/fCard";
import FCardHeader from "../../ui/fairtreeComponents/card/fCardHeader";

function UserDetails() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const selectedUser = useSelector((state) => state.user.selectedUser);
  const user = useSelector((state) => state.user?.user);
  const theme = useTheme();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      dispatch(getUser(params.id));
    } else {
      navigate("/admin/users");
    }
  }, [dispatch, params.id]);

  const handleUserUpdate = () => {
    dispatch(getUser(params.id));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const displaySummary = (user) => {
    return (
      <FCard>
        <CardContent>
          <Typography
            variant={"body1"}
          >{`First Name: ${user.firstName}`}</Typography>
          <Typography
            variant={"body1"}
          >{`Last Name: ${user.lastName}`}</Typography>
          <Typography variant={"body1"}>{`Email: ${user.email}`}</Typography>
          <Typography
            variant={"body1"}
          >{`Phone Number: ${user.phoneNumber}`}</Typography>
          <Typography
            variant={"body1"}
          >{`Created: ${user.createdAt}`}</Typography>
        </CardContent>
      </FCard>
    );
  };

  const tabs = [
    {
      title: "Summary",
      content: user ? displaySummary(user) : null,
    },
    {
      title: "Roles",
      content: user ? (
        <RoleDetails
          user={user}
          onRoleAssigned={handleUserUpdate}
          onRoleRevoked={handleUserUpdate}
        />
      ) : null,
    },
    // {
    //   title: "Advisor",
    //   content: "Coming Soon",
    // },
    {
      title: "Client Portfolios",
      content: "Coming Soon",
    },
    {
      title: "Submissions",
      content: "Coming Soon",
    },
    {
      title: "Files",
      content: "Coming Soon",
    },
  ];

  return user ? (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: "100%", maxWidth: "700px" }}>
        <Box sx={{ mb: 3 }}>
          <FTabs
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            {tabs.map((tab, index) => (
              <FTab label={tab.title} index={IDBIndex} />
            ))}
          </FTabs>
        </Box>
        <FCard sx={{ mb: 3 }}>
          <FCardHeader
            title={`${user.firstName} ${user.lastName}`}
            subheader={`${user.email} `}
          />
        </FCard>
        {tabs.map((tab, index) => (
          <FTabPanel value={value} index={index}>
            {tab.content}
          </FTabPanel>
        ))}
      </Box>
    </Box>
  ) : (
    <OrangeCircularProgress caption={"Loading User"} />
  );
}

export default UserDetails;
