import React, { Fragment } from 'react';
import { Box } from '@mui/system';
import { CardContent, Typography } from '@mui/material';
import FCard from '../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../ui/fairtreeComponents/card/fCardHeader';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { useSelector } from 'react-redux';
import PrimaryButton from '../ui/buttons/primaryButton';
import { useNavigate } from 'react-router-dom';

const SelectClientAccount = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const handleViewBalances = (client) => {
    navigate(`/balances/${client._id}`);
  };

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <Box width={'100%'} maxWidth={'700px'}>
        <Box display={'flex'} justifyContent={'start'} alignSelf={'start'}>
          <InsightsOutlinedIcon />
          <Typography ml={1} mb={1} variant="h5">
            Select Account
          </Typography>
        </Box>

        {user?.client && user.client.length > 0
          ? user.client.map((client) => (
              <Box
                key={client._id}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 3 }}>
                <FCard>
                  <FCardHeader
                    title={
                      client
                        ? `${
                            client.entityName
                              ? client.entityName
                              : `${client.firstName} ${client.lastName}`
                          }`
                        : 'Account'
                    }
                    subheader={client && client.clientId}
                  />
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={'flex-end'}
                      justifyContent={'flex-end'}>
                      <PrimaryButton onClick={() => handleViewBalances(client)}>
                        View Balances
                      </PrimaryButton>
                    </Box>
                  </CardContent>
                </FCard>
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default SelectClientAccount;
