import React from 'react';

const Form = ({ children, ...props }) => {
  return (
    <form sx={{ width: '100%', mt: 1 }} noValidate {...props}>
      {children}
    </form>
  );
};

export default Form;
