import React from 'react';
import { ButtonBase } from '@mui/material';

const TextButton = ({ children, ...props }) => {
  return (
    <ButtonBase variant="text" sx={{ textDecoration: 'underline' }} {...props}>
      {children}
    </ButtonBase>
  );
};

export default TextButton;
