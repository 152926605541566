//Create slice MDD

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mddService } from './mdd.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = { file: { file: '', success: false, uploading: false } };

export const getMdds = createAsyncThunk('mdd/getMdds', async (_, thunkAPI) => {
  return await mddService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getMddById = createAsyncThunk('mdd/getMddById', async (id, thunkAPI) => {
  return await mddService
    .getById(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const createMdd = createAsyncThunk('mdd/createMdd', async (data, thunkAPI) => {
  return await mddService
    .create(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('MDD created'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateMdd = createAsyncThunk('mdd/updateMdd', async (data, thunkAPI) => {
  return await mddService
    .update(data.id, data.data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('MDD updated'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const deleteMdd = createAsyncThunk('mdd/deleteMdd', async (id, thunkAPI) => {
  return await mddService
    .remove(id)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('MDD deleted'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const uploadMdd = createAsyncThunk('mdd/uploadMdd', async (data, thunkAPI) => {
  return await mddService
    .upload(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('MDD uploaded'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const mddSlice = createSlice({
  name: 'mdd',
  initialState,
  reducers: {
    resetMdd: (state) => {
      state.file = { file: '', success: false, uploading: false };
    }
  },
  extraReducers: {
    [getMdds.pending]: (state, action) => {
      state.loading = true;
    },
    [getMdds.fulfilled]: (state, action) => {
      state.loading = false;
      state.mdds = action.payload.mdds;
    },
    [getMdds.rejected]: (state, action) => {
      state.loading = false;
    },
    [getMddById.pending]: (state, action) => {
      state.loading = true;
    },
    [getMddById.fulfilled]: (state, action) => {
      state.loading = false;
      state.mdd = action.payload.mdd;
    },
    [getMddById.rejected]: (state, action) => {
      state.loading = false;
    },
    [createMdd.pending]: (state, action) => {
      state.loading = true;
    },
    [createMdd.fulfilled]: (state, action) => {
      state.loading = false;
      state.mdd = action.payload.mdd;
    },
    [createMdd.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateMdd.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMdd.fulfilled]: (state, action) => {
      state.loading = false;
      state.mdd = action.payload.mdd;
    },
    [updateMdd.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteMdd.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMdd.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteMdd.rejected]: (state, action) => {
      state.loading = false;
    },
    [uploadMdd.pending]: (state, action) => {
      state.uploading = true;
      state.success = false;
    },
    [uploadMdd.fulfilled]: (state, action) => {
      state.uploading = false;
      state.mdd = action.payload.mdd;
      state.success = true;
    },
    [uploadMdd.rejected]: (state, action) => {
      state.uploading = false;
      state.success = false;
    }
  }
});

export const { resetMdd } = mddSlice.actions;

export default mddSlice.reducer;
