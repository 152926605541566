import { config } from '../config';
import { authHeader } from '../helpers';
import FileDownload from 'js-file-download';
import axiosInstance from '../axiosInstance';

export const fileStorageService = {
  downloadFile,
  uploadFile,
  deleteFile
};

function downloadFile(id, filename) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    responseType: 'blob'
  };

  return axiosInstance
    .get(`${config.file_server}/download/${id}`, requestOptions)
    .then((response) => {
      FileDownload(response.data, filename);
    });
}

function uploadFile(file) {
  return new Promise((resolve, reject) => {
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() }
    };

    axiosInstance
      .post(`${config.file_server}/sign`, { fileName, fileType }, requestOptions)
      .then((response) => {
        const { signedRequest } = response.data;
        if (signedRequest) {
          var options = {
            headers: {
              'Content-Type': fileType
            }
          };
          axiosInstance
            .put(signedRequest.signedUrl, file, options)
            .then((result) => {
              axiosInstance
                .post(`${config.file_server}/`, signedRequest, requestOptions)
                .then((response) => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
  });
}

function deleteFile(id) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.delete(`${config.file_server}/${id}`, requestOptions).then((responce) => {
    return responce.data;
  });
}
