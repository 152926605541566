export const transactionConstants = {
  GET_ALL_REQUEST: "GET_ALL_TRANSACTION_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_TRANSACTION_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_TRANSACTION_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_TRANSACTION_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_TRANSACTION_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_TRANSACTION_FAILURE",

  GET_BY_ACCOUNT_REQUEST: "GET_BY_TRANSACTION_ACCOUNT_REQUEST",
  GET_BY_ACCOUNT_SUCCESS: "GET_BY_TRANSACTION_ACCOUNT_SUCCESS",
  GET_BY_ACCOUNT_FAILURE: "GET_BY_TRANSACTION_ACCOUNT_FAILURE",

  GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_REQUEST:
    "GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_REQUEST",
  GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_SUCCESS:
    "GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_SUCCESS",
  GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_FAILURE:
    "GET_TRANSACTIONTYPES_BYSERVICEPROVIDER_FAILURE",
};
