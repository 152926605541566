import * as React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const StyledCardTypography = styled(Typography)(({ theme }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return {
    fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
    marginRight: '3px'
  };
});

export default function FCardTypography(props) {
  return <StyledCardTypography {...props}>{props.children}</StyledCardTypography>;
}
