import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// Selects the Statements - Select Account then continue to Statements
const StatementsSelector = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'start'}
      alignItems={'start'}
      sx={{
        marginBottom: '30px'
      }}>
      <Box display={'flex'} justifyContent={'start'} alignItems={'start'}>
        <Typography ml={1} variant="h5">
          Statements
        </Typography>

        

      </Box>
    </Box>
  );
};

export default StatementsSelector;
