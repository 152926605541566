import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import React from 'react';

const AccountInfoCard = ({ account }) => {
  console.log(account);
  return (
    <Paper sx={{ mb: 2 }}>
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        p={2}>
        <Typography variant="h3" textAlign={'center'}>
          {account.product}
        </Typography>

        <Stack direction={'row'} sx={{ p: 3, pb: 0 }} alignItems={'flex-start'}>
          {/* {account.model.totalInceptionValue && (
            <Stack sx={{ mr: 3 }} justifyContent={'center'} alignItems={'center'}>
              <Typography>{'Inception'}</Typography>
              <Typography variant="h5">{account.model.totalInceptionValue}</Typography>
              <Typography variant="p" component="small">
                {account.model.modelInceptionDate &&
                  moment(account.model.modelInceptionDate).format('YYYY')}
              </Typography>
            </Stack>
          )} */}

          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography>{'Balance'}</Typography>
            <Typography variant="h5">
              {account.model.closing}
              {account.balanceDisclaimer ? '*' : ''}
            </Typography>
            <Typography variant="p" component="small">
              {moment(account.funds[0].closingDate).format('MMMM YYYY')} Closing
            </Typography>
            {account.balanceDisclaimer ? (
              <Typography variant="caption" color="error" alignSelf={'flex-end'} mt={4}>
                * {account.balanceDisclaimer}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default AccountInfoCard;

AccountInfoCard.propTypes = {
  account: PropTypes.object.isRequired
};
