import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../../redux/actions';

import OrangeCircularProgress from '../../../ui/controls/circularProgress';

import Form from '../../../ui/forms';
import Input from '../../../ui/forms/fields/input';
import PrimaryButton from '../../../ui/buttons/primaryButton';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import GeneralButton from '../../../ui/buttons/generalButton';

const schema = yup.object().shape({
  name: yup.string().required()
});

function ProductAddEdit(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.product.loading);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    shouldUnregister: false
  });

  useEffect(() => {
    if (props.selectedItem) {
      setValue('name', props.selectedItem.name);
    }
  }, [setValue, props.selectedItem]);

  const onSubmit = (data) => {
    if (props.formType === 'Add') {
      dispatch(productActions.create(data));

      props.onSave();
    } else {
      if (props.selectedItem) {
        dispatch(productActions.update(props.selectedItem._id, data));
        props.onSave();
      }
    }
  };

  return loading ? (
    <OrangeCircularProgress caption="Saving..." />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="name"
        label="Name"
        {...register('name')}
        autoComplete="name"
        error={!!errors.name}
        helperText={errors?.name?.message}
      />

      <PrimaryButton fullWidth={false}>{props.formType === 'Add' ? 'Add' : 'Save'}</PrimaryButton>
      <GeneralButton
        fullWidth={false}
        onClick={() => props.onCancel()}
        style={{ marginLeft: '10px' }}>
        Cancel
      </GeneralButton>
    </Form>
  );
}

export default ProductAddEdit;
