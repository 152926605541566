// import { MuiOtpInput } from 'mui-one-time-password-input';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const OTPInput = ({ name, label, control, defaultValue, helperText, disabled, ...props }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <TextField
          label={label}
          fullWidth
          value={value}
          onChange={onChange}
          helperText={helperText}
          autoComplete={'off'}
          type="tel"
          autoFocus={true}
        />
        // <MuiOtpInput
        //   value={value}
        //   onChange={onChange}
        //   autoFocus={true}
        //   autosuggest="false"
        //   TextFieldsProps={{
        //     type: 'tel'
        //   }}
        // />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      {...props}
    />
  );
};

export default OTPInput;
