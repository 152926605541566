import React, { forwardRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  input: {
    height: '40px'
  }
}));

const CurrencyInput = forwardRef(
  ({ name, label, control, defaultValue, helperText, disabled, ...props }, ref) => {
    const labelId = `${name}-label`;
    const classes = useStyles();

    return (
      <Controller
        render={({ field: { onChange, onBlur, value = '' } }) => (
          <FormControl
            fullWidth
            className={classes.root}
            disabled={disabled}
            sx={{ m: 1 }}
            {...props}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <OutlinedInput
              ref={ref}
              className={classes.input}
              label={label}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              startAdornment={<InputAdornment position="start">{props.symbol}</InputAdornment>}
            />
            <FormHelperText error={props.error}>{helperText}</FormHelperText>
          </FormControl>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
      />
    );
  }
);

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
