import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../ui/buttons/primaryButton';

const Welcome = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          width: '100%',
          maxWidth: '400px'
        }}
        p={3}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Typography variant="h1" gutterBottom sx={{ fontSize: '4em', fontWeight: 'bold', mb: 5 }}>
          Welcome.
        </Typography>

        <Typography variant="h4" sx={{ mt: 4 }}>
          Already Registered?
        </Typography>
        <PrimaryButton onClick={() => navigate('/signin')}>Login</PrimaryButton>

        <Typography variant="h4" sx={{ mt: 4 }}>
          Not Registered?
        </Typography>

        <PrimaryButton onClick={() => navigate('/signup/select')}>Sign Up</PrimaryButton>
      </Box>
    </Box>
  );
};

export default Welcome;
