export const userFunctionConstants = {
  GET_ALL_REQUEST: "GET_ALL_USERFUNCTION_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_USERFUNCTION_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_USERFUNCTION_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_USERFUNCTION_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_USERFUNCTION_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_USERFUNCTION_FAILURE",

  CREATE_REQUEST: "CREATE_USERFUNCTION_REQUEST",
  CREATE_SUCCESS: "CREATE_USERFUNCTION_SUCCESS",
  CREATE_FAILURE: "CREATE_USERFUNCTION_FAILURE",

  UPDATE_REQUEST: "UPDATE_USERFUNCTION_REQUEST",
  UPDATE_SUCCESS: "UPDATE_USERFUNCTION_SUCCESS",
  UPDATE_FAILURE: "UPDATE_USERFUNCTION_FAILURE",

  DELETE_REQUEST: "DELETE_USERFUNCTION_REQUEST",
  DELETE_SUCCESS: "DELETE_USERFUNCTION_SUCCESS",
  DELETE_FAILURE: "DELETE_USERFUNCTION_FAILURE",
};
