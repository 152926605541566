// Updated Theme Version 1.1
import { createTheme } from '@mui/material/styles';

import GothamBold from './fonts/GothamBold.ttf';
import GothamBook from './fonts/GothamBook.ttf';
import GothamLight from './fonts/GothamLight.ttf';
import GothamMedium from './fonts/GothamMedium.ttf';
import RecifeDisplayTrialBlack from './fonts/RecifeDisplayTrialBlack.ttf';
import RecifeDisplayTrialBold from './fonts/RecifeDisplayTrialBold.ttf';
import RecifeDisplayTrialMedium from './fonts/RecifeDisplayTrialMedium.ttf';
import RecifeDisplayTrialRegular from './fonts/RecifeDisplayTrialRegular.ttf';

const getPaletteMode = (mode) => {
  return mode === 'light'
    ? {
        primary: { main: '#5D5957' },
        secondary: { main: '#666873' },
        background: { default: '#FEFEFE', paper: '#F6F6F4', backdrop: 'rgba(255,255,255,0.8)' },
        accent: { main: '#CC934D' },
        text: { main: '#2A3242', light: '#FEFEFE' }
      }
    : {
        primary: { main: '#383435' },
        secondary: { main: '#666873' },
        background: { default: '#888583', paper: '#2A2627' },
        accent: { main: '#CC934D' },
        text: { main: '#ffffff' }
      };
};

let createFairtreeTheme = (mode) => {
  const palette = getPaletteMode(mode);

  return createTheme({
    palette,
    typography: {
      fontFamily: 'Gotham, Arial',
      htmlFontSize: 18,
      body1: { fontSize: '0.8rem' },
      subtitle1: {
        fontSize: '0.65rem',
        fontWeight: 500,
        color: palette.secondary.main,
        lineHeight: 1.2
      },
      subtitle2: { fontSize: '0.9rem', fontWeight: 500 },
      h1: { fontSize: '2.5rem' },
      h2: { fontSize: '2.0rem' },
      h3: { fontSize: '1.7rem' },
      h4: { fontSize: '1.4rem' },
      h5: { fontSize: '1rem', fontWeight: 700 },
      h6: { fontSize: '0.9rem', fontWeight: 400 }
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.paper,
            color: palette.text.main,
            border: 'none',
            borderRadius: '4px',
            boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.7)'
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: '#E5E5E5'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {},
          paper: {
            boxShadow: 'none'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 200;
          src: local('Gotham Light'), local('Gotham-Light'), url(${GothamLight}) format('truetype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;        
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gotham Book'), local('Gotham-Book'), url(${GothamBook}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gotham Medium'), local('Gotham-Medium'), url(${GothamMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Gotham Bold'), local('Gotham-Bold'), url(${GothamBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Recife Display Trial'), local('RecifeDisplayTrial-Regular'), url(${RecifeDisplayTrialRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Recife Display Trial Black'), local('RecifeDisplayTrial-Black'), url(${RecifeDisplayTrialBlack}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Recife Display Trial Medium'), local('RecifeDisplayTrial-Medium'), url(${RecifeDisplayTrialMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Recife Display Trial'), local('RecifeDisplayTrial-Bold'), url(${RecifeDisplayTrialBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
      }
    }
  });
};

export { createFairtreeTheme };
