import React, { Fragment, useEffect } from 'react';
import { Box } from '@mui/system';
import { Dialog, DialogContent, Divider, MenuItem, Typography } from '@mui/material';
import TextButton from '../../ui/buttons/textButton';
import PrimaryButton from '../../ui/buttons/primaryButton';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';
import { getById, updateClient } from '../../../features/client/client.slice';

import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import PrimarySelect from '../../ui/forms/fields/select';
import FTabs from '../../ui/fairtreeComponents/tab/fTabs';
import FTab from '../../ui/fairtreeComponents/tab/fTab';
import FTabPanel from '../../ui/fairtreeComponents/tab/fTabPanel';
import OrangeCircularProgress from '../../ui/controls/circularProgress';

import AccountLinking from './components/accountLinking';

// entityname only required when entitytype is not individual

const schema = yup.object().shape({
  entityType: yup.string().required('Please select an entity type'),
  entityName: yup.string().when('entityType', {
    is: (val) => val !== 'Individual',
    then: yup.string().required('Please enter an entity name')
  }),
  firstName: yup.string().required('Please enter a first name'),
  lastName: yup.string().required('Please enter a last name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  clientId: yup.string().required('Please enter a client ID'),
  contactNumber: yup.string()
});

const ClientUpdate = ({ clientId }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client?.client);
  const loading = useSelector((state) => state.client?.loadingClientInfo);

  useEffect(() => {
    if (clientId) {
      dispatch(getById(clientId));
    }
  }, [clientId, dispatch]);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (client) {
      reset({
        entityType: client.entityType,
        entityName: client.entityName,
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        clientId: client.clientId,
        contactNumber: client.contactNumber
      });
    }
  }, [reset, client]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    data.id = client._id;

    dispatch(updateClient(data));
  };

  const accountDetails = () =>
    !loading ? (
      <Fragment>
        <Typography variant="body2" pt={1}>
          This information is received from our MANCO's, and may be incomplete.
        </Typography>
        <Form>
          <PrimarySelect
            name="entityType"
            label="Entity Type"
            error={!!errors.entityType}
            helperText={errors?.entityType?.message}
            control={control}>
            <MenuItem value="Individual">Individual</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Trust">Trust</MenuItem>
            <MenuItem value="Organisation">Organisation</MenuItem>
          </PrimarySelect>
          <Input
            name="entityName"
            label="Entity Name"
            error={!!errors.entityName}
            helperText={errors?.entityName?.message}
            {...register('entityName')}
          />
          <Input
            name="clientId"
            label="Identification Number / Registration Number"
            error={!!errors.clientId}
            helperText={errors?.clientId?.message}
            {...register('clientId')}
          />
          <Input
            name="firstName"
            label="First Name"
            error={!!errors.firstName}
            helperText={errors?.firstName?.message}
            {...register('firstName')}
          />
          <Input
            name="lastName"
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
            {...register('lastName')}
          />
          <Input
            name="email"
            label="Email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            {...register('email')}
          />
          <Input
            name="contactNumber"
            label="Contact Number"
            error={!!errors.contactNumber}
            helperText={errors?.contactNumber?.message}
            {...register('contactNumber')}
          />
        </Form>
        <PrimaryButton onClick={handleSubmit(onSubmit)}>Update Client Account</PrimaryButton>
      </Fragment>
    ) : (
      <OrangeCircularProgress caption="Fetching client profile." />
    );

  const eliteAccount = () => (
    <Fragment>
      <Typography variant="body2">
        You can get details from Elite Wealth. Any changes made here will not reflect in Elite
        Wealth. This will also overwrite any changes made in Fairtree Portal. <br />
        <br />
        Please make sure your client is up to date and information is correct in Elite Wealth before
        getting the information. Especially the ID/Registration Number amd Preferred Email Address.
        <br />
        <br />
        If no data is retrieved from Elite Wealth, please make sure the client is up to date in
        Elite.
        <br />
        <br />
        Suggestion: Get the details from Elite and then make your updates.
      </Typography>

      <PrimaryButton>Get Details From Elite</PrimaryButton>
    </Fragment>
  );

  const tabs = [
    {
      title: 'Account Details',
      content: accountDetails()
    }
    // {
    //   title: 'Account Linking',
    //   content: <AccountLinking client={client} />
    // },
    // {
    //   title: 'Get Details from Elite Wealth',
    //   content: eliteAccount()
    // }
  ];

  return (
    <Fragment>
      <TextButton onClick={() => handleClickOpen()}>Update Client Account Details</TextButton>

      <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <FTabs
              value={tabValue}
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable">
              {tabs.map((tab, index) => (
                <FTab label={tab.title} key={index} index={IDBIndex} />
              ))}
            </FTabs>
          </Box>

          {tabs.map((tab, index) => (
            <FTabPanel key={index} value={tabValue} index={index}>
              {tab.content}
            </FTabPanel>
          ))}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ClientUpdate;
