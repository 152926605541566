import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';
import { transactionTypeService } from './transactionType.service';

const initialState = {};

export const getAllTypes = createAsyncThunk('transactionType/getAll', async (data, thunkAPI) => {
  return await transactionTypeService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getTypeById = createAsyncThunk('transactionType/getById', async (data, thunkAPI) => {
  return await transactionTypeService
    .getById(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const createType = createAsyncThunk('transactionType/create', async (data, thunkAPI) => {
  return await transactionTypeService
    .create(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Transaction Type Created'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const updateType = createAsyncThunk('transactionType/update', async (data, thunkAPI) => {
  return await transactionTypeService
    .update(data.id, data.data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Transaction Type Updated'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const removeType = createAsyncThunk('transactionType/remove', async (data, thunkAPI) => {
  return await transactionTypeService
    .remove(data)
    .then((response) => {
      thunkAPI.dispatch(setAlertSuccess('Transaction Type Removed'));
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getTransactionMappings = createAsyncThunk(
  'transactionType/getTransactionMappings',
  async (data, thunkAPI) => {
    return await transactionTypeService
      .getTransactionMappings(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateTransactionMapping = createAsyncThunk(
  'transactionType/updateTransactionMapping',
  async (data, thunkAPI) => {
    return await transactionTypeService
      .updateTransactionMapping(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Transaction Mapping Updated'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const transactionTypeSlice = createSlice({
  name: 'transactionType',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllTypes.fulfilled]: (state, action) => {
      return action.payload;
    },
    [getTypeById.fulfilled]: (state, action) => {
      return action.payload;
    },
    [createType.fulfilled]: (state, action) => {
      return action.payload;
    },
    [updateType.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateType.pending]: (state, action) => {
      state.loading = true;
    },
    [updateType.rejected]: (state, action) => {
      state.loading = false;
    },
    [removeType.fulfilled]: (state, action) => {
      return action.payload;
    },
    [getTransactionMappings.fulfilled]: (state, action) => {
      state.transactionTypeMappings = action.payload.transactionTypeMappings;
      state.loadingMappings = false;
    },
    [getTransactionMappings.pending]: (state, action) => {
      state.loadingMappings = true;
    },
    [getTransactionMappings.rejected]: (state, action) => {
      state.loadingMappings = false;
    },
    [updateTransactionMapping.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.updatingMapping = false;
    },
    [updateTransactionMapping.pending]: (state, action) => {
      state.updatingMapping = true;
    },
    [updateTransactionMapping.rejected]: (state, action) => {
      state.updatingMapping = false;
    }
  }
});

export default transactionTypeSlice.reducer;
