//Create slice for houseModel

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { houseModelService } from './houseModel.service';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';

const initialState = {
  houseModels: []
};

export const getHouseModels = createAsyncThunk('houseModel/getHouseModels', async (_, thunkAPI) => {
  return await houseModelService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.message));
      thunkAPI.rejectWithValue(err.message);
    });
});

export const getHouseModelById = createAsyncThunk(
  'houseModel/getHouseModelById',
  async (id, thunkAPI) => {
    return await houseModelService
      .getById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const createHouseModel = createAsyncThunk(
  'houseModel/createHouseModel',
  async (data, thunkAPI) => {
    return await houseModelService
      .create(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const updateHouseModel = createAsyncThunk(
  'houseModel/updateHouseModel',
  async (data, thunkAPI) => {
    return await houseModelService
      .update(data.id, data.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const deleteHouseModel = createAsyncThunk(
  'houseModel/deleteHouseModel',
  async (id, thunkAPI) => {
    return await houseModelService
      .remove(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getDistinctHouseModel = createAsyncThunk(
  'houseModel/getDistinctHouseModel',
  async (serviceprovider, thunkAPI) => {
    return await houseModelService
      .getDestinctModelsByServiceProvider(serviceprovider)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getModelMappings = createAsyncThunk(
  'houseModel/getModelMappings',
  async (serviceprovider, thunkAPI) => {
    return await houseModelService
      .getModelMappings(serviceprovider)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const updateModelMapping = createAsyncThunk(
  'houseModel/updateModelMapping',
  async (data, thunkAPI) => {
    return await houseModelService
      .updateModelMapping(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Model Mapping Updated'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.message));
        thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const houseModelSlice = createSlice({
  name: 'houseModel',
  initialState,
  reducers: {},
  extraReducers: {
    [getHouseModels.pending]: (state, action) => {
      state.loadingModels = true;
    },
    [getHouseModels.fulfilled]: (state, action) => {
      state.loadingModels = false;
      state.houseModels = action.payload.houseModels;
    },
    [getHouseModels.rejected]: (state, action) => {
      state.loadingModels = false;
    },
    [getHouseModelById.pending]: (state, action) => {
      state.loading = true;
    },
    [getHouseModelById.fulfilled]: (state, action) => {
      state.loading = false;
      state.houseModel = action.payload.houseModel;
    },
    [getHouseModelById.rejected]: (state, action) => {
      state.loading = false;
    },
    [createHouseModel.pending]: (state, action) => {
      state.loading = true;
    },
    [createHouseModel.fulfilled]: (state, action) => {
      state.loading = false;
      state.houseModel = action.payload.houseModel;
    },
    [createHouseModel.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateHouseModel.pending]: (state, action) => {
      state.loading = true;
    },
    [updateHouseModel.fulfilled]: (state, action) => {
      state.loading = false;
      state.houseModel = action.payload.houseModel;
    },
    [updateHouseModel.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteHouseModel.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteHouseModel.fulfilled]: (state, action) => {
      state.houseModels = state.houseModels.filter(
        (houseModel) => houseModel._id.toString() !== action.payload.id
      );

      state.loading = false;
    },
    [deleteHouseModel.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDistinctHouseModel.pending]: (state, action) => {
      state.loading = true;
    },
    [getDistinctHouseModel.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountModels = action.payload.accountModels;
    },
    [getDistinctHouseModel.rejected]: (state, action) => {
      state.loading = false;
    },
    [getModelMappings.pending]: (state, action) => {
      state.loadingMappingModels = true;
    },
    [getModelMappings.fulfilled]: (state, action) => {
      state.loadingMappingModels = false;
      state.modelMappings = action.payload.modelMappings;
    },
    [getModelMappings.rejected]: (state, action) => {
      state.loadingMappingModels = false;
    },
    [updateModelMapping.pending]: (state, action) => {
      state.loading = true;
    },
    [updateModelMapping.fulfilled]: (state, action) => {
      state.loading = false;
      state.modelMapping = action.payload.modelMapping;
    },
    [updateModelMapping.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export default houseModelSlice.reducer;
