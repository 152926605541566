import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: '' // Replace with your API base URL
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     const currentBuildVersion = response.headers['x-build-version'];
//     const storedBuildVersion = Cookies.get('buildVersion');

//     if (storedBuildVersion && storedBuildVersion !== currentBuildVersion) {
//       Cookies.set('buildVersion', currentBuildVersion, { expires: 7 });
//       const event = new CustomEvent('buildVersionChanged', {
//         detail: { buildVersion: currentBuildVersion }
//       });
//       window.dispatchEvent(event);
//     } else if (!storedBuildVersion) {
//       Cookies.set('buildVersion', currentBuildVersion, { expires: 7 });

//       const event = new CustomEvent('buildVersionChanged', {
//         detail: { buildVersion: currentBuildVersion }
//       });
//       window.dispatchEvent(event);
//     }

//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
