import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const vehiclesService = {
  getVehiclesForGuidance
};

function getVehiclesForGuidance() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.vehicle_server}/guidance`, requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
