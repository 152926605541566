import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '../../../ui/forms/fields/input';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const Step3ClientDetails = ({ onNext, onBack, onReset }) => {
  const theme = useTheme();

  // Validation schema for form fields
  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Please enter the first name.'),
    lastName: yup.string().required('Please enter the last name.'),
    consultantName: yup.string().required('Please enter the consultant name.'),
    clientBackground: yup.string().max(1120, 'Client background must be at most 1120 characters.'),
    additionalComments: yup
      .string()
      .nullable()
      .max(1120, 'Comments must be at most 1120 characters.')
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      consultantName: '',
      clientBackground: '',
      additionalComments: ''
    }
  });

  const fundFilterData = useSelector((state) => state.fundFilter);
  const vehicle = fundFilterData.vehicle;

  // Determine rows and maxLength based on vehicle
  const isRetirementOrBasicInvestment =
    vehicle === 'Retirement Annuity' || vehicle === 'Basic Investment';

  const clientBackgroundRows = isRetirementOrBasicInvestment ? 8 : 10;
  const clientBackgroundMaxLength = isRetirementOrBasicInvestment ? 750 : 950;

  const additionalCommentsRows = 12;
  const additionalCommentsMaxLength = 1120;

  const clientBackgroundValue = watch('clientBackground', '');
  const additionalCommentsValue = watch('additionalComments', '');

  const onSubmit = (data) => {
    const dataToSubmit = { ...data, ...fundFilterData };
    onNext(dataToSubmit);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const NavButtons = () => (
    <Box
      sx={{
        position: 'sticky',
        bottom: 60,
        display: 'flex',
        alignItems: 'center',
        m: 2,
        mb: 4,
        width: 'fit-content',
        mx: 'auto',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        zIndex: 1000
      }}>
      <Button onClick={onBack}>Back</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button onClick={onReset}>Start Over</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button
        onClick={handleSubmit(onSubmit)}
        sx={{
          mr: 1,
          '&.Mui-disabled': {
            background: '#CC6323',
            color: '#fff'
          }
        }}>
        Next
      </Button>
    </Box>
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6">Complete the Client Details</Typography>

      <Input
        {...register('firstName')}
        label="Client First Name"
        fullWidth
        margin="normal"
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />

      <Input
        {...register('lastName')}
        label="Client Last Name"
        fullWidth
        margin="normal"
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />

      <Input
        {...register('consultantName')}
        label="Consultant Name"
        fullWidth
        margin="normal"
        error={!!errors.consultantName}
        helperText={errors.consultantName?.message}
      />

      <Input
        {...register('clientBackground')}
        label="Client Background"
        fullWidth
        margin="normal"
        multiline
        rows={clientBackgroundRows}
        inputProps={{ maxLength: clientBackgroundMaxLength }}
        error={!!errors.clientBackground}
        helperText={
          errors.clientBackground?.message ||
          `${clientBackgroundValue.length}/${clientBackgroundMaxLength} characters`
        }
      />

      <Input
        {...register('additionalComments')}
        label="Closing / Additional Comments"
        fullWidth
        margin="normal"
        multiline
        rows={additionalCommentsRows}
        inputProps={{ maxLength: additionalCommentsMaxLength }}
        error={!!errors.additionalComments}
        helperText={
          errors.additionalComments?.message ||
          `${additionalCommentsValue.length}/${additionalCommentsMaxLength} characters`
        }
      />

      <NavButtons />
    </Box>
  );
};

export default Step3ClientDetails;
