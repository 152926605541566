import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import GreatDatePicker from '../../../ui/forms/fields/datePicker';
import Select from '../../../ui/forms/fields/select';
import { MenuItem, useTheme } from '@mui/material';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import { useDispatch } from 'react-redux';
import { getReport, getProgressReport } from '../../../../features/reports/report.slice';

const schema = yup.object().shape({
  startDate: yup.string().when('type', {
    is: (value) => value !== 'progress',
    then: yup.string().required('Please enter Start Date')
  }),
  endDate: yup.string().when('type', {
    is: (value) => value !== 'progress',
    then: yup.string().required('Please enter End Date')
  }),
  type: yup.string().required('Please select a Report')
});

function Reports() {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (data.type === 'progress') {
      const progressData = {
        startDate: data.startDate,
        endDate: data.endDate
      };

      dispatch(getProgressReport(progressData));
      return;
    }
    const requestData = JSON.stringify(data);
    dispatch(getReport(requestData));
  };

  const errorMessage = Object.values(errors)
    .map((error) => error?.message)
    .join(', ');

  return (
    <div
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          padding: 'auto',
          maxWidth: '700px'
        }}>
        <Select
          name="type"
          label="Report"
          control={control}
          sx={{
            marginRight: theme.spacing(2),
            width: '25%'
          }}>
          <MenuItem value={'speakToAConsultant'}>Speak to consultant</MenuItem>
          <MenuItem value={'ratings'}>Rating</MenuItem>
          <MenuItem value={'submissions'}>Submissions</MenuItem>
          <MenuItem value={'progress'}>Progress Report</MenuItem>
        </Select>
        <GreatDatePicker
          label="Start Date"
          name="startDate"
          control={control}
          sx={{
            marginRight: theme.spacing(2),
            width: '25%'
          }}
        />
        <GreatDatePicker
          label="End Date"
          name="endDate"
          control={control}
          sx={{
            marginRight: theme.spacing(2),
            width: '25%'
          }}
        />

        <PrimaryButton
          type="submit"
          sx={{
            marginTop: theme.spacing(1)
          }}>
          Download
        </PrimaryButton>

        <br />
        {errorMessage && (
          <ul
            sx={{
              color: 'red',
              marginTop: theme.spacing(1),
              listStyle: 'disc',
              paddingLeft: theme.spacing(2)
            }}>
            {errors &&
              Object.values(errors).map((error, index) => <li key={index}>{error?.message}</li>)}
          </ul>
        )}
      </form>
    </div>
  );
}

export default Reports;
