import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CardActions, CardContent, Typography, useMediaQuery } from '@mui/material';
import PasswordResetDialog from '../dialogs/passwordResetDialog';
import ClientAccountCard from '../partials/clientAccountCard';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { useTitle } from '../../../hooks/titleProvider';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import Input from '../../ui/forms/fields/input';
import { useNavigate } from 'react-router-dom';

import FamilyIcon from '@mui/icons-material/Diversity1';
import EntityIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';

import BowlingMember from '../../../resources/images/bowlingMember.png';

import BSAMemberLocked from '../../../resources/images/badges/BSAMemberLocked.png';
import BSAMemberUnlocked from '../../../resources/images/badges/BSAMemberUnlocked.png';

import PBAMemberLocked from '../../../resources/images/badges/PBAMemberLocked.png';
import PBAMemberUnlocked from '../../../resources/images/badges/PBAMemberUnlocked.png';
import FairtreeClientUnlocked from '../../../resources/images/badges/FairtreeClientUnlocked.png';
import FairtreePortalUnlocked from '../../../resources/images/badges/FairtreePortalUnlocked.png';
import { getClientAffiliations } from '../../../features/client/client.slice';
import ImageBadge from '../../ui/badge/imageBadge';

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const affiliations = useSelector((state) => state.client.clientAffiliations);
  const loadingAffiliations = useSelector((state) => state.client.loadingClientAffiliations);

  const { setTitle } = useTitle();
  setTitle('My Profile');

  useEffect(() => {
    dispatch(getClientAffiliations());
  }, [dispatch]);

  const handleAccountLinkFamily = () => {
    navigate('/onboarding/family');
  };

  const handleAccountLinkEntity = () => {
    navigate('/onboarding/entity');
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        mb={3}
        width={'100%'}
        maxWidth={'700px'}>
        <Box display="flex" gap={1}>
          <FCard>
            <FCardHeader title={'Personal Details'} />
            <CardContent>
              <Input label={'First Name'} value={user.firstName} disabled />
              <Input label={'Last Name'} value={user.lastName} disabled />
              <Input label={'Email Address'} type="email" value={user.email} disabled />
              <Input
                label={'Phone Number'}
                type="tel"
                value={user.phoneNumber ? user.phoneNumber : ''}
                disabled
              />
            </CardContent>
            <CardActions>
              <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="flex-end"
                gap={2}
                width={'100%'}>
                <PasswordResetDialog />
                <PrimaryButton
                  disabled
                  variant="outlined"
                  fullWidth={false}
                  toolTip={'Coming Soon'}>
                  Update Email Address
                </PrimaryButton>
                <PrimaryButton
                  disabled
                  variant="outlined"
                  fullWidth={false}
                  toolTip={'Coming Soon'}>
                  Update Details
                </PrimaryButton>
              </Box>
            </CardActions>
          </FCard>

          <FCard>
            <FCardHeader title={'Badges'} />
            <CardContent>
              <Box display="flex" justifyContent="flex-start" mb={1}>
                {loadingAffiliations && <Typography variant="body1">Loading...</Typography>}

                <ImageBadge src={FairtreePortalUnlocked} altText={'Fairtree Portal Unlocked'} />
                <ImageBadge src={FairtreeClientUnlocked} altText={'Fairtree Client Unlocked'} />

                {affiliations &&
                affiliations.find((affiliation) => affiliation.name === 'bsa-member')?.length >
                  0 ? (
                  <ImageBadge
                    src={BSAMemberUnlocked}
                    altText={'BSA Member Unlocked'}
                    dialog={true}
                    dialogContent={
                      <Typography>
                        You are a member of your local bowls club and have registered Bowls South
                        Africa membership number.
                      </Typography>
                    }
                  />
                ) : (
                  <ImageBadge src={BSAMemberLocked} altText={'BSA Member Locked'} />
                )}

                {affiliations &&
                affiliations.find((affiliation) => affiliation.name === 'pba-member') ? (
                  <ImageBadge
                    src={PBAMemberUnlocked}
                    altText={'PBA Member Unlocked'}
                    dialog={true}
                    dialogContent={
                      <Typography>
                        You are a member of the Professional Bowls Association (PBA) and have a
                        registered PBA membership number.
                      </Typography>
                    }
                  />
                ) : (
                  <ImageBadge src={PBAMemberLocked} altText={'PBA Member Locked'} />
                )}
              </Box>
            </CardContent>
          </FCard>
        </Box>

        <FCard>
          <FCardHeader title={'Linked Accounts'} subheader={'Link Multiple Accounts'} />
          <CardContent>
            {user.client && user.client.length > 0 ? (
              <ClientAccountCard />
            ) : (
              <Fragment>
                <Typography variant="body1">
                  Welcome to the Fairtree Investment Portal. You have not linked accounts yet, let's
                  get you started.
                </Typography>
              </Fragment>
            )}
          </CardContent>
          <CardActions>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="flex-end"
              gap={2}
              width={'100%'}
              pr={1}>
              <PrimaryButton onClick={() => handleAccountLinkFamily()}>
                <PersonIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Personal Account
                </Typography>
              </PrimaryButton>
              <PrimaryButton onClick={() => handleAccountLinkFamily()}>
                <FamilyIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Family Account
                </Typography>
              </PrimaryButton>
              <PrimaryButton onClick={() => handleAccountLinkEntity()}>
                <EntityIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Organization/Trust Account
                </Typography>
              </PrimaryButton>
            </Box>
          </CardActions>
        </FCard>
      </Box>
    </Box>
  );
}

export default Profile;
