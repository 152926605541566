export const authConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  VERIFYID_REQUEST: "USERS_VERIFYID_REQUEST",
  VERIFYID_SUCCESS: "USERS_VERIFYID_SUCCESS",
  VERIFYID_FAILURE: "USERS_VERIFYID_FAILURE",

  SENDOTP_REQUEST: "USERS_SENDOTP_REQUEST",
  SENDOTP_SUCCESS: "USERS_SENDOTP_SUCCESS",
  SENDOTP_FAILURE: "USERS_SENDOTP_FAILURE",

  VERIFYOTP_REQUEST: "USERS_VERIFYOTP_REQUEST",
  VERIFYOTP_SUCCESS: "USERS_VERIFYOTP_SUCCESS",
  VERIFYOTP_FAILURE: "USERS_VERIFYOTP_FAILURE",

  SEND_ENAILVERIFICATION_REQUEST: "USERS_SEND_ENAILVERIFICATION_REQUEST",
  SEND_ENAILVERIFICATION_SUCCESS: "USERS_SEND_ENAILVERIFICATION_SUCCESS",
  SEND_ENAILVERIFICATION_FAILURE: "USERS_SEND_ENAILVERIFICATION_FAILURE",

  VERIFY_ENAILVERIFICATION_REQUEST: "USERS_VERIFY_ENAILVERIFICATION_REQUEST",
  VERIFY_ENAILVERIFICATION_SUCCESS: "USERS_VERIFY_ENAILVERIFICATION_SUCCESS",
  VERIFY_ENAILVERIFICATION_FAILURE: "USERS_VERIFY_ENAILVERIFICATION_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  AUTH_REQUEST: "USERS_AUTH_REQUEST",
  AUTH_SUCCESS: "USERS_AUTH_SUCCESS",
  AUTH_FAILURE: "USERS_AUTH_FAILURE",

  FORGOT_PASSWORD_REQUEST: "USERS_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "USERS_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "USERS_FORGOT_PASSWORD_FAILURE",

  UPDATE_PASSWORD_REQUEST: "USERS_UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "USERS_UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "USERS_UPDATE_PASSWORD_FAILURE",

  LOGOUT: "USERS_LOGOUT",
};
