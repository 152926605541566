import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';

const RoundedIconTile = ({ title, icon, image, onClick, className, ...rest }) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Button
        variant="outlined"
        sx={{
          margin: '15px',
          width: '60px',
          height: '60px',
          background: theme.palette.background.paper,
          border: 'none',
          borderRadius: '14px',
          boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.7)',
          color: theme.palette.text.main,
          fontSize: '2rem',
          '&:active': {
            background: theme.palette.background.paper
          }
        }}
        onClick={onClick}
        {...rest}>
        {image ? (
          <Box
            component={'img'}
            src={image}
            sx={{
              width: '40px',
              height: '40px'
            }}
          />
        ) : (
          icon
        )}
      </Button>
      <Typography variant="caption">{title}</Typography>
    </Box>
  );
};

export default RoundedIconTile;
