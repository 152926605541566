import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const serviceProviderService = {
  getServiceProviders,
  getServiceProviderById,
  updateServiceProvider,
  resetServiceProvider,
  updateSyncStatusServiceProvider
};

function getServiceProviders() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.serviceprovider_server}`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getServiceProviderById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.serviceprovider_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function updateServiceProvider(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.serviceprovider_server}/${id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function updateSyncStatusServiceProvider(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.serviceprovider_server}/status/${id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function resetServiceProvider(id) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.serviceprovider_server}/reset/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
