import { mddConstants } from '../constants';

export function mdd(state = { file: { file: '', success: false, uploading: false } }, action) {
  switch (action.type) {
    case mddConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case mddConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        mdds: action.result.mdds,
        success: action.result.success,
        loading: false
      };
    case mddConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case mddConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case mddConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        entity: action.result.entity,
        success: action.result.success,
        loading: false
      };
    case mddConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case mddConstants.CREATE_REQUEST:
      return { ...state, loading: true, success: false };
    case mddConstants.CREATE_SUCCESS:
      return {
        ...state,
        mdd: action.result.mdd,
        success: action.result.success,
        loading: false
      };
    case mddConstants.CREATE_FAILURE:
      return { ...state, error: action.error, saving: false, success: action.result.success };

    case mddConstants.UPLOAD_REQUEST:
      return { ...state, uploading: true, success: false };
    case mddConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        file: { file: action.result.mdd, success: action.result.success, uploading: false }
      };
    case mddConstants.UPLOAD_FAILURE:
      return { ...state, error: action.error, uploading: false, success: false };

    case mddConstants.UPDATE_REQUEST:
      return { ...state, saving: true };
    case mddConstants.UPDATE_SUCCESS:
      return {
        ...state,
        mdd: action.result.mdd,
        success: action.result.success,
        saving: false
      };
    case mddConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case mddConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case mddConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.result.success,
        loading: false
      };
    case mddConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case mddConstants.RESET_REQUEST:
      return { ...state };
    case mddConstants.RESET_SUCCESS:
      return {
        ...state,
        file: { file: '', success: false, uploading: false }
      };

    default:
      return state;
  }
}
