import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMdds,
  createMdd,
  updateMdd,
  uploadMdd,
  resetMdd
} from '../../../features/mdd/mdd.slice';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CardActions, CardContent } from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Form from '../../ui/forms';
import moment from 'moment';
import CopyInput from '../../ui/controls/copyInput';
import FileDropzone from '../../ui/forms/fields/fileDropzone';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import FDialog from '../../ui/fairtreeComponents/dialog/fDialog';

function MDDs() {
  const schema = yup.object().shape({
    file: yup.object().required('File is required')
  });

  const [open, setOpen] = useState(false);
  const [selectedMDD, setSelectedMDD] = useState();
  // const [file, setFile] = useState();
  const [formType, setFormType] = useState('create');

  const dispatch = useDispatch();
  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = formMethods;

  const { mdds } = useSelector((state) => state.mdd);
  const { saving } = useSelector((state) => state.mdd);
  const { success } = useSelector((state) => state.mdd);
  const { uploading = false, progress, file } = useSelector((state) => state.mdd.file);

  useEffect(() => {
    dispatch(getMdds());
  }, [dispatch]);

  useEffect(() => {
    if (file) {
      setSelectedMDD(null);
      setOpen(false);
      dispatch(resetMdd());
    }
  }, [file]);

  useEffect(() => {
    if (!saving && success) {
      setOpen(false);
      dispatch(getMdds());
      reset();
    }
  }, [saving, success, dispatch]);

  const onSubmit = (data) => {
    if (formType === 'create') {
      dispatch(createMdd(data));
    } else {
      dispatch(updateMdd(data));
    }
    setOpen(false);
  };

  const handleUpdate = (mdd) => {
    setSelectedMDD(mdd);
    setOpen(true);
    setFormType('update');
  };

  const handleUpload = async (formData) => {
    formData.append('mdd', selectedMDD._id);

    await dispatch(uploadMdd(formData));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '100%', maxWidth: '700px' }}>
          {mdds
            ? mdds.map((mdd) => (
                <FCard key={mdd._id} sx={{ mb: 2 }}>
                  <FCardHeader
                    title={mdd.name}
                    subheader={moment(mdd.lastModified).format('DD/MM/yyyy HH:mm:ss')}
                  />
                  <CardContent>
                    <CopyInput
                      name="url"
                      value={mdd.url}
                      disabled
                      handleCopy={() => copyToClipboard(mdd.url)}
                    />
                  </CardContent>

                  <CardActions
                    disableSpacing
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                    <PrimaryButton size="small" onClick={() => handleUpdate(mdd)}>
                      Upload New
                    </PrimaryButton>
                  </CardActions>
                </FCard>
              ))
            : null}
        </Box>
      </Box>

      <FDialog open={open} onClose={handleCancel}>
        <DialogTitle>Update MDD/One Pager</DialogTitle>
        <DialogContent>
          {saving ? (
            <p>Saving File...</p>
          ) : (
            <FormProvider {...formMethods}>
              <Form>
                <FileDropzone
                  name="file"
                  label="MDD/One Pager"
                  loading={uploading}
                  progress={progress}
                  file={file}
                  onUpload={(e) => handleUpload(e)}
                />
              </Form>
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton variant={'outlined'} onClick={handleCancel}>
            Cancel
          </PrimaryButton>
          {/* <PrimaryButton onClick={handleUpload}>Upload</PrimaryButton> */}
        </DialogActions>
      </FDialog>
    </>
  );
}

export default MDDs;
