import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Table
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';

import PrimaryButton from '../../../ui/buttons/primaryButton';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../../ui/forms';
import Input from '../../../ui/forms/fields/input';
import Switch from '../../../ui/forms/fields/switch';
import ClientFilterTable from '../../clients/components/clientFilterTable';
import { communicationActions } from '../../../../redux/actions';

const schema = yup.object().shape({
  name: yup.string().required('Please select a name for your list.'),
  contacts: yup.array(),
  dynamic: yup.bool()
  //filters: yup.array(),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ListDialog(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { onListCreate } = props;

  const [saving, setSaving] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const isDynamic = watch('dynamic');

  const loading = useSelector((state) => state.communication.loading);
  const list = useSelector((state) => state.communication.list);

  useEffect(() => {
    register('contacts');
  }, [register]);

  useEffect(() => {
    if (saving && !loading && list) {
      setSaving(false);
      setOpen(false);
      onListCreate();
    }
  }, [loading, list, saving]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClientFilterChange = (selectedClients) => {
    setValue('contacts', selectedClients);
  };

  const onSubmit = (data) => {
    setSaving(true);
    dispatch(communicationActions.createList(data));
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Fragment>
      <PrimaryButton onClick={handleOpen} type={'button'}>
        Create new List
      </PrimaryButton>

      <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Users/Clients to add to your email list.
            </Typography>
            <PrimaryButton type="button" onClick={handleSave}>
              Save
            </PrimaryButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          <Form>
            <Input
              id="name"
              label="List Name"
              {...register('name')}
              autoFocus
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
            <Switch control={control} name="dynamic" label="Make Dynamic" />
          </Form>
          <ClientFilterTable
            onChange={(selectedClients) => handleClientFilterChange(selectedClients)}
            hasCheckbox={!isDynamic}
          />
          {errors && errors.length > 0 && errors.map((err) => <Typography>error.msg</Typography>)}
        </Box>
      </Dialog>
    </Fragment>
  );
}

export default ListDialog;
