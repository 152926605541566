import { roleConstants } from "../constants";

export function role(state = {}, action) {
  switch (action.type) {
    case roleConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case roleConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        roles: action.result.roles,
        success: action.result.success,
        loading: false,
      };
    case roleConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case roleConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case roleConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        role: action.result.role,
        success: action.result.success,
        loading: false,
      };
    case roleConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case roleConstants.CREATE_REQUEST:
      return { ...state, loading: true };
    case roleConstants.CREATE_SUCCESS:
      return {
        ...state,
        role: action.result.role,
        success: action.result.success,
        loading: false,
      };
    case roleConstants.CREATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case roleConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case roleConstants.UPDATE_SUCCESS:
      const updatedRole = action.result.role;
      return {
        ...state,
        role: updatedRole,
        success: action.result.success,
        loading: false,
      };
    case roleConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case roleConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case roleConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.result.success,
        loading: false,
      };
    case roleConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
