import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../../../redux/actions";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  Checkbox,
  MenuItem,
  Box,
} from "@mui/material";
import { useParams } from "react-router";

function MappingRow({ serviceProvider, spProduct, ...props }) {
  const [selectedItem, setSelectedItem] = useState("None");
  const [isHidden, setIsHidden] = useState(false);

  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    if (props.savedValues) {
      setSelectedItem(props.savedValues.product);
      setIsHidden(props.savedValues.isHidden);
    }
  }, [props.savedValues]);

  const handleSelect = (event) => {
    setSelectedItem(event.target.value);
    props.typeChange(event);
  };

  const handleHidden = (event) => {
    setIsHidden(event.target.checked);
    props.hiddenChange(event);
  };

  console.log(selectedItem);
  return (
    <TableRow>
      <TableCell>{spProduct} </TableCell>
      <TableCell>
        <Select
          name="mapToType"
          label="Select product"
          style={{ width: "250px", marginRight: "60px" }}
          onChange={handleSelect}
          value={selectedItem}
        >
          <MenuItem value={"None"}>None</MenuItem>
          {products &&
            products.map((tt) => (
              <MenuItem key={tt._id} value={tt._id}>
                {tt.name}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
      <TableCell>
        <Checkbox
          name="hide"
          label="Hide"
          color="primary"
          checked={isHidden}
          onChange={handleHidden}
        />
      </TableCell>
    </TableRow>
  );
}

function ProductMapping() {
  const dispatch = useDispatch();
  const params = useParams();

  const spProducts = useSelector((state) => state.product.spProducts);
  const mappings = useSelector((state) => state.product.productMappings);

  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if (mappings) {
      setUpdatedData(mappings);
    }
  }, [mappings]);

  useEffect(() => {
    dispatch(productActions.getAll());
    dispatch(productActions.getProductMappings(params.id));
    dispatch(productActions.getDestinctProductsbyServiceProvider(params.id));
  }, []);

  const update = (data) => {
    let dataArray = updatedData;
    if (data.product != null) {
      const foundIndex = dataArray.findIndex(
        (x) =>
          x.serviceProvider === data.serviceProvider &&
          x.spProduct === data.spProduct
      );

      if (foundIndex !== -1) {
        const item = dataArray[foundIndex];
        item.product = data.product === "None" ? null : data.product;

        dispatch(productActions.updateProductMapping(item));

        dataArray[foundIndex] = item;
      } else {
        data.product = data.product === "None" ? null : data.product;
        dataArray.push(data);

        dispatch(productActions.updateProductMapping(data));
      }
    } else if (data.isHidden != null) {
      const foundIndex = dataArray.findIndex(
        (x) =>
          x.serviceProvider === data.serviceProvider &&
          x.spProduct === data.spProduct
      );

      if (foundIndex !== -1) {
        const item = dataArray[foundIndex];
        item.isHidden = data.isHidden;

        dispatch(productActions.updateProductMapping(item));

        dataArray[foundIndex] = item;
      } else {
        dataArray.push(data);

        dispatch(productActions.updateProductMapping(data));
      }
    }

    setUpdatedData(dataArray);
  };

  const getSavedValue = (serviceProvider, spProduct) => {
    const foundIndex = updatedData.findIndex(
      (x) => x.serviceProvider === serviceProvider && x.spProduct === spProduct
    );

    if (foundIndex !== -1) {
      return updatedData[foundIndex];
    }
  };

  const hanldeTypeChange = (event, serviceProvider, spProduct) => {
    const newProduct = event.target.value;

    update({ product: newProduct, serviceProvider, spProduct });
  };

  const hanldeHiddenChange = (event, serviceProvider, spProduct) => {
    const isHidden = event.target.checked;

    update({ isHidden, serviceProvider, spProduct });
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Map to</TableCell>
            <TableCell>Hidden</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spProducts
            ? spProducts.map((spp, index) => (
                <MappingRow
                  key={index}
                  spProduct={spp}
                  serviceProvider={params.id}
                  typeChange={(event) =>
                    hanldeTypeChange(event, params.id, spp)
                  }
                  hiddenChange={(event) =>
                    hanldeHiddenChange(event, params.id, spp)
                  }
                  savedValues={getSavedValue(params.id, spp)}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ProductMapping;
