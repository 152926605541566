import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';

const Fees = () => {
  const fees = useSelector((state) => state.transaction.fees);

  return (
    <div>
      <Table size="small" aria-label="purchases">
        <TableBody>
          <TableRow>
            <TableCell colSpan="1" width="100%" component="th" scope="row">
              <Typography variant="h6">{fees.name}</Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="right" component="th" scope="row" style={{ whiteSpace: 'nowrap' }}>
              Opening
            </TableCell>
            <TableCell align="right" component="th" scope="row" style={{ whiteSpace: 'nowrap' }}>
              Closing
            </TableCell>
          </TableRow>

          {fees.items.map((item) => (
            <TableRow key={item.name}>
              <TableCell width="100%" component="th" scope="row">
                {item.name}
              </TableCell>
              <TableCell align="right">
                <Typography
                  style={{ fontSize: '10px' }}
                  color="primary"
                  variant="p"
                  component="small">
                  {moment(item.closingDate).format('DD/MM/YYYY')}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                {item.opening}
              </TableCell>
              <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                {item.closing}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Fees;
