import { entityConstants } from "../constants";

export function entity(state = {}, action) {
  switch (action.type) {
    case entityConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case entityConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        entities: action.result.entities,
        success: action.result.success,
        loading: false,
      };
    case entityConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case entityConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case entityConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        entity: action.result.entity,
        success: action.result.success,
        loading: false,
      };
    case entityConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case entityConstants.CREATE_REQUEST:
      return { ...state, loading: true };
    case entityConstants.CREATE_SUCCESS:
      return {
        ...state,
        entity: action.result.entity,
        success: action.result.success,
        loading: false,
      };
    case entityConstants.CREATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case entityConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case entityConstants.UPDATE_SUCCESS:
      return {
        ...state,
        entity: action.result.entity,
        success: action.result.success,
        loading: false,
      };
    case entityConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case entityConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case entityConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.result.success,
        loading: false,
      };
    case entityConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
