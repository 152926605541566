import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import DefaultBanner from '../resources/images/banners/Login Portal Banner.png';
import DefaultSkinnyBanner from '../resources/images/banners/Skinny_LoginPortal_Banner.png';

const BannerContext = React.createContext();
export const useBanner = () => React.useContext(BannerContext);

export const banners = {
  default: {
    desktop: DefaultSkinnyBanner,
    mobile: DefaultBanner
  }
};

const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState({
    desktop: DefaultSkinnyBanner,
    mobile: DefaultBanner
  });

  // Use MUI's useMediaQuery to determine mobile status
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const clearBanner = () => {
    setBanner(null); // Set banner to null or a default value
  };

  return (
    <BannerContext.Provider value={{ banner, setBanner, isMobile, clearBanner }}>
      {children}
    </BannerContext.Provider>
  );
};

export default BannerProvider;
