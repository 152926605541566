import { houseModelConstants } from "../constants";

export function houseModel(state = {}, action) {
  switch (action.type) {
    case houseModelConstants.GET_ALL_REQUEST:
      return { ...state, loading: true };
    case houseModelConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        houseModels: action.houseModels.houseModels,
        success: action.houseModels.success,
        loading: false,
      };
    case houseModelConstants.GET_ALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case houseModelConstants.GET_BYID_REQUEST:
      return { ...state, loading: true };
    case houseModelConstants.GET_BYID_SUCCESS:
      return {
        ...state,
        houseModel: action.houseModels.houseModel,
        success: action.houseModels.success,
        loading: false,
      };
    case houseModelConstants.GET_BYID_FAILURE:
      return { ...state, error: action.error, loading: false };

    case houseModelConstants.CREATE_REQUEST:
      return { ...state, loading: true };
    case houseModelConstants.CREATE_SUCCESS:
      return {
        ...state,
        houseModel: action.houseModel.houseModel,
        success: action.houseModel.success,
        loading: false,
      };
    case houseModelConstants.CREATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case houseModelConstants.UPDATE_REQUEST:
      return { ...state, loading: true };
    case houseModelConstants.UPDATE_SUCCESS:
      return {
        ...state,
        houseModel: action.houseModel.houseModel,
        success: action.houseModel.success,
        loading: false,
      };
    case houseModelConstants.UPDATE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case houseModelConstants.DELETE_REQUEST:
      return { ...state, loading: true };
    case houseModelConstants.DELETE_SUCCESS:
      return {
        ...state,
        success: action.response.success,
        loading: false,
      };
    case houseModelConstants.DELETE_FAILURE:
      return { ...state, error: action.error, loading: false };

    case houseModelConstants.GET_BYSERVICEPROVIDER_REQUEST:
      return { ...state };
    case houseModelConstants.GET_BYSERVICEPROVIDER_SUCCESS:
      return {
        ...state,
        accountModels: action.result.accountModels,
        success: action.result.success,
      };
    case houseModelConstants.GET_BYSERVICEPROVIDER_FAILURE:
      return { ...state, error: action.error };

    case houseModelConstants.GET_MAPPING_REQUEST:
      return { ...state };
    case houseModelConstants.GET_MAPPING_SUCCESS:
      return {
        ...state,
        modelMappings: action.result.modelMappings,
        success: action.result.success,
      };
    case houseModelConstants.GET_MAPPING_FAILURE:
      return { ...state, error: action.error };

    case houseModelConstants.UPDATE_MAPPING_REQUEST:
      return { ...state };
    case houseModelConstants.UPDATE_MAPPING_SUCCESS:
      return {
        ...state,
        modelMapping: action.result.modelMapping,
        success: action.result.success,
      };
    case houseModelConstants.UPDATE_MAPPING_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
