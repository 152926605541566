import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PrivateLayout from './privateLayout';

const PrivateRoute = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  if (!user) {
    return <Navigate to={'/signin'} />;
  }

  if (user && user.twoFactorAuthEnabled && !user.otpVerified) {
    return <Navigate to={'/verify/otp'} />;
  }

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default PrivateRoute;
