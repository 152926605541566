import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import PrimarySelect from '../../ui/forms/fields/select';
import { Container, Grid, MenuItem, Paper, Typography, useTheme } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/actions';
import OTPForm from '../../account/identityOTPForm';

const schema = yup.object().shape({
  idNumber: yup.string().required('This is a required field.'),
  entityType: yup.string().required('Please select a type.')
});

function Settings() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const userInfo = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    if (userInfo) {
      setOpen(true);
    }
  }, [userInfo]);

  const onIDSubmit = (data) => {
    dispatch(authActions.verifyId(data));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const otpDialog = () => (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="VerifyID-dialog-title">
      <DialogTitle id="VerifyID-dialog-title">Verify ID</DialogTitle>
      <DialogContent>
        <Container>
          <OTPForm
            phoneNumber={userInfo.phoneNumber}
            idNumber={userInfo.idNumber}
            entityType={userInfo.entityType}
            email={userInfo.email}
          />
        </Container>
      </DialogContent>
    </Dialog>
  );

  return (
    <Paper
      sx={{
        margin: theme.spacing(1),
        padding: theme.spacing(1)
      }}>
      <Grid container direction="row">
        <Grid item xs={12} md={4}>
          <Form onSubmit={handleSubmit(onIDSubmit)}>
            <Typography variant="body1">Change your client by using thier ID here.</Typography>

            <PrimarySelect
              sx={{
                margin: theme.spacing(2, 0, 2)
              }}
              defaultValue={'Person'}
              id="entityType"
              name="entityType"
              label="Identity Type"
              control={control}
              error={errors?.entityType}>
              <MenuItem key="Person" value="Person">
                ID Number / Passport
              </MenuItem>
              <MenuItem key="Company" value="Company">
                Company Registration Number
              </MenuItem>
              <MenuItem key="Organization" value="Organization">
                Organization Registration Number
              </MenuItem>
              <MenuItem key="Trust" value="Trust">
                Trust Number
              </MenuItem>
              <MenuItem key="Other" value="Other">
                Other
              </MenuItem>
            </PrimarySelect>

            <Input
              sx={{
                margin: theme.spacing(2, 0, 2)
              }}
              id="idNumber"
              label="Identification Number"
              {...register('idNumber')}
              error={!!errors.idNumber}
              helperText={errors?.idNumber?.message}
              autoFocus
            />

            <PrimaryButton>Change Client</PrimaryButton>
          </Form>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      {userInfo ? otpDialog() : null}
    </Paper>
  );
}

export default Settings;
