import { userFunctionConstants } from "../constants";
import { userFunctionService } from "../services";
import { alertActions } from "./";

export const userFunctionActions = {
  getAll,
  getById,
  create,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return userFunctionService.getAll().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: userFunctionConstants.GET_ALL_REQUEST };
  }
  function success(result) {
    return { type: userFunctionConstants.GET_ALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: userFunctionConstants.GET_ALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return userFunctionService.getById(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: userFunctionConstants.GET_BYID_REQUEST };
  }
  function success(result) {
    return { type: userFunctionConstants.GET_BYID_SUCCESS, result };
  }
  function failure(error) {
    return { type: userFunctionConstants.GET_BYID_FAILURE, error };
  }
}

function create(newRole) {
  return (dispatch) => {
    dispatch(request());

    return userFunctionService.create(newRole).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: userFunctionConstants.CREATE_REQUEST };
  }
  function success(result) {
    return { type: userFunctionConstants.CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: userFunctionConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return userFunctionService.update(id, data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: userFunctionConstants.UPDATE_REQUEST };
  }
  function success(result) {
    return { type: userFunctionConstants.UPDATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: userFunctionConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return userFunctionService.remove(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: userFunctionConstants.DELETE_REQUEST };
  }
  function success(result) {
    return { type: userFunctionConstants.DELETE_SUCCESS, result };
  }
  function failure(error) {
    return { type: userFunctionConstants.UPDATE_FAILURE, error };
  }
}
