import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import OTPInput from '../ui/forms/fields/otpInput';

import { TextField, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { logout, resendOTP, verifyOTP } from '../../features/auth/auth.slice';
import GeneralButton from '../ui/buttons/generalButton';
import Form from '../ui/forms';
import PrimaryButton from '../ui/buttons/primaryButton';

import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  otp: yup
    .string()
    .min(4, 'OTP must be 4 digits')
    .required('OTP is required. Check your email Inbox or Spam folder.')
});

const OTPVerify = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(180);

  useEffect(() => {
    let countdown = null;
    if (seconds > 0) {
      countdown = setInterval(() => setSeconds(seconds - 1), 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [seconds]);

  let time;
  seconds > 0 ? (time = `(${seconds})`) : (time = '');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const onSubmit = (data) => {
    dispatch(verifyOTP(data));
  };

  const handleResend = () => {
    dispatch(resendOTP())
      .unwrap()
      .then(() => {
        setSeconds(60);
      });
  };

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate('/signin');
      });
  };

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'center'}>
      <Box
        display={'flex'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          minWidth: '30vw'
        }}
        p={5}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          sx={{
            maxWidth: '250px',
            height: '50%'
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={'center'}>
          <Typography variant="h5" gutterBottom textAlign={'center'} mb={3}>
            Please keep an eye out for the OTP in your email inbox.
          </Typography>

          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <OTPInput name="otp" label="OTP" control={control} /> */}
            <TextField
              label="OTP"
              fullWidth
              autoComplete={'off'}
              type="tel"
              autoFocus={true}
              {...register('otp')}
            />
          </Form>

          <PrimaryButton onClick={handleSubmit(onSubmit)}>Continue</PrimaryButton>

          <PrimaryButton
            size={'sm'}
            disabled={seconds > 0}
            color={'secondary'}
            sx={{ display: seconds > 0 ? 'none' : 'inherit' }}
            onClick={() => handleResend()}>
            {`Resend OTP`}
          </PrimaryButton>

          <GeneralButton
            variant={'text'}
            onClick={() => handleLogout()}
            sx={{ fontSize: '10px', mt: 0 }}>
            Logout
          </GeneralButton>

          <ErrorMessage errors={errors} name="otp" />
        </Box>
      </Box>
    </Box>
  );
};

export default OTPVerify;
