import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import EducationIcon from '@mui/icons-material/CastForEducation';
import PhoneIcon from '@mui/icons-material/Phone';
import { NavLink } from 'react-router-dom';

const MainBottomNavigation = ({ children }) => {
  const [value, setValue] = React.useState(0);

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        paddingBottom: 'env(safe-area-inset-bottom)'
      }}
      elevation={3}>
      <BottomNavigation
        position="fixed"
        display="flex"
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}>
        <BottomNavigationAction href={'/home'} label="Home" icon={<HomeIcon />} />
        {/* <BottomNavigationAction href={'/balances'} label="Balances" icon={<ListIcon />} />
        <BottomNavigationAction disabled={true} label="Education" icon={<EducationIcon />} /> */}
        <BottomNavigationAction href={'/contact'} label="Contact" icon={<PhoneIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default MainBottomNavigation;
