import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/system';
import { IconButton, Paper } from '@mui/material';
import { Collapse, Typography } from '@mui/material';

const CollapsibleInfoCard = ({ children, defaultState }) => {
  const [open, setOpen] = useState(defaultState);

  return (
    <Box
      m={2}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <IconButton onClick={() => setOpen(!open)} color={'accent'}>
        <InfoOutlinedIcon sx={{ fontSize: '22px', fontWeight: 'bold', mb: 1 }} />
      </IconButton>

      <Collapse in={open}>
        <Paper sx={{ boxShadow: 'none', p: 2, width: '100%' }}>
          <Typography>{children}</Typography>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default CollapsibleInfoCard;
