import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHouseModels, deleteHouseModel } from '../../../../features/houseModel/houseModel.slice';
import { Box, CardActions } from '@mui/material';

import OrangeCircularProgress from '../../../ui/controls/circularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import HouseModelAddEdit from './addEditDialog';
import FDialog from '../../../ui/fairtreeComponents/dialog/fDialog';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import FCard from '../../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../../ui/fairtreeComponents/card/fCardHeader';

function HouseModels() {
  const dispatch = useDispatch();

  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [openConfirmDelete, setopenConfirmDelete] = useState(false);
  const [formType, setFormType] = useState('Add');
  const [selectedItem, setSelectedItem] = useState(null);

  const loading = useSelector((state) => state.houseModel.loading);
  const houseModels = useSelector((state) => state.houseModel.houseModels);

  useEffect(() => {
    dispatch(getHouseModels());
  }, [dispatch]);

  const openAdd = (formtype, item) => {
    setOpenAddEdit(true);
    setFormType(formtype);

    if (item) {
      setSelectedItem(item);
    }
  };

  const handleSave = () => {
    dispatch(getHouseModels());
    setOpenAddEdit(false);
  };

  const handleClose = () => {
    setSelectedItem({});
    setOpenAddEdit(false);
  };

  const hanldeDelete = (model) => {
    setSelectedItem(model);

    setopenConfirmDelete(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteHouseModel(selectedItem._id));

    setopenConfirmDelete(false);

    setSelectedItem({});
  };

  const handleDeleteCancel = () => {
    setSelectedItem({});
    setopenConfirmDelete(false);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: '100%', maxWidth: '700px' }}>
        <PrimaryButton fullWidth={false} onClick={() => openAdd('Add')}>
          Add New Model
        </PrimaryButton>

        {!loading ? (
          houseModels &&
          houseModels.map((model) => (
            <FCard key={model._id} sx={{ mb: 2 }}>
              <FCardHeader title={model.name} />
              <CardActions
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                {' '}
                <PrimaryButton onClick={() => openAdd('Edit', model)} sx={{ mr: 2 }} size="small">
                  Edit
                </PrimaryButton>
                <PrimaryButton onClick={() => hanldeDelete(model)} color="warning" size="small">
                  Delete
                </PrimaryButton>
              </CardActions>
            </FCard>
          ))
        ) : (
          <FCard>
            <OrangeCircularProgress caption="Loading House Models..." />
          </FCard>
        )}

        <FDialog
          fullWidth={true}
          maxWidth="lg"
          open={openAddEdit}
          onClose={() => handleClose()}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle>{formType === 'Add' ? 'Add new' : 'Edit'} model</DialogTitle>
          <DialogContent>
            <HouseModelAddEdit
              formType={formType}
              selectedItem={selectedItem}
              onSave={() => handleSave()}
              onCancel={() => handleClose()}
            />
          </DialogContent>
        </FDialog>

        <FDialog
          maxWidth="lg"
          open={openConfirmDelete}
          onClose={() => handleDeleteCancel()}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle>Delete {selectedItem?.name} Model</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the {selectedItem?.name} Model?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              onClick={() => handleDeleteCancel()}
              variant="outlined"
              fullWidth={false}>
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={() => handleDeleteConfirmation()} fullWidth={false}>
              Delete
            </PrimaryButton>
          </DialogActions>
        </FDialog>
      </Box>
    </Box>
  );
}

export default HouseModels;
