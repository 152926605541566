import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { guidanceToolDataService } from './guidanceToolData.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  guidanceToolData: null,
  loading: false,
  error: null
};

export const getGuidanceToolData = createAsyncThunk(
  'guidanceToolData/getGuidanceToolData',
  async (_, thunkAPI) => {
    try {
      const response = await guidanceToolDataService.getGuidanceToolData();
      return response;
    } catch (error) {
      const err = error.response?.data || { msg: 'An error occurred' };
      thunkAPI.dispatch(setAlertError(err.msg));
      return thunkAPI.rejectWithValue(err.msg);
    }
  }
);

export const guidanceToolDataSlice = createSlice({
  name: 'guidanceToolData',
  initialState,
  reducers: {
    clearGuidanceTool: (state) => {
      state.guidanceToolData = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGuidanceToolData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGuidanceToolData.fulfilled, (state, action) => {
        state.guidanceToolData = action.payload;
        state.loading = false;
      })
      .addCase(getGuidanceToolData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// Selector to extract the required fields
export const selectGuidanceToolData = (state) => {
  const { guidanceToolData } = state.guidanceTool;

  if (!guidanceToolData || !guidanceToolData.guidanceCalcData) return null; // Handle case where guidanceToolData or guidanceCalcData is not defined

  const { guidanceCalcData } = guidanceToolData;

  return {
    AchieveByList: guidanceCalcData.achieveByList?.[0]?.AchieveByList || [],
    GoalsList: guidanceCalcData.goalsList?.[0]?.GoalsList || [],
    NeedToWithdrawList: guidanceCalcData.needToWithdrawList?.[0]?.NeedToWithdrawList || [],
    RiskDescriptionsList: guidanceCalcData.riskDescriptionsList?.[0]?.RiskDescriptionsList || [],
    RiskProfileTypes: guidanceCalcData.riskProfileTypes?.[0]?.RiskProfileTypes || [],
    TaxFreeRiskProfileTypes:
      guidanceCalcData.taxFreeRiskProfileTypes?.[0]?.TaxFreeRiskProfileTypes || [],
    TaxFreeRiskDescriptionsList:
      guidanceCalcData.taxFreeRiskDescriptionsList?.[0]?.TaxFreeRiskDescriptionsList || [],
    NeedToWithdrawListTFSA:
      guidanceCalcData.needToWithdrawListTFSA?.[0]?.NeedToWithdrawListTFSA || [],
    NeedToWithdrawListRA: guidanceCalcData.needToWithdrawListRA?.[0]?.NeedToWithdrawListRA || []
  };
};

export const { clearGuidanceTool } = guidanceToolDataSlice.actions;

export default guidanceToolDataSlice.reducer;
