import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDistinctTransactionTypes } from '../../../../features/transaction/transaction.slice';
import {
  getAllTypes,
  getTransactionMappings,
  updateTransactionMapping
} from '../../../../features/transactionType/transactionType.slice';

import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useParams } from 'react-router';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';

function MappingRow({ serviceProvider, transactionType, updatedData, ...props }) {
  const [selectedItem, setSelectedItem] = useState(
    props.savedValues ? props.savedValues.newType : 'None'
  );
  const [isHidden, setIsHidden] = useState(props.savedValues ? props.savedValues.isHidden : false);
  const transactionTypes = useSelector((state) => state.transactionType.transactionTypes);

  useEffect(() => {
    setSelectedItem(props.savedValues ? props.savedValues.newType : 'None');
    setIsHidden(props.savedValues ? props.savedValues.isHidden : false);
  }, [props.savedValues, updatedData]);

  const handleSelect = (event) => {
    setSelectedItem(event.target.value);
    props.typeChange(event);
  };

  const handleHidden = (event) => {
    setIsHidden(event.target.checked);
    props.hiddenChange(event);
  };

  return (
    <TableRow>
      <TableCell>{transactionType} </TableCell>
      <TableCell>
        <Select
          name="mapToType"
          label="Select global type."
          style={{ width: '250px', marginRight: '60px' }}
          onChange={handleSelect}
          value={selectedItem ? selectedItem : 'None'}>
          <MenuItem value={'None'}>None</MenuItem>
          {transactionTypes &&
            transactionTypes.map((tt) => (
              <MenuItem key={tt._id} value={tt._id}>
                {tt.name}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
      <TableCell>
        <Checkbox
          name="hide"
          label="Hide"
          color="primary"
          checked={isHidden}
          onChange={handleHidden}
        />
      </TableCell>
    </TableRow>
  );
}

function TransactioMapping() {
  const dispatch = useDispatch();
  const params = useParams();

  const transactionTypes = useSelector((state) => state.transaction.transactionTypes);
  const loadingTypes = useSelector((state) => state.transaction.loadingTypes);

  const mappings = useSelector((state) => state.transactionType.transactionTypeMappings);
  const loadingMappings = useSelector((state) => state.transactionType.loadingMappings);

  const updatingMapping = useSelector((state) => state.transactionType.updatingMapping);

  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    dispatch(getTransactionMappings(params.id));
    dispatch(getDistinctTransactionTypes(params.id));
    dispatch(getAllTypes());
  }, [dispatch]);

  useEffect(() => {
    if (mappings) {
      setUpdatedData(mappings);
    }
  }, [mappings]);

  const update = (data) => {
    let dataArray = [...updatedData];
    if (data.newType != null) {
      const foundIndex = dataArray.findIndex(
        (x) =>
          x.serviceProvider === data.serviceProvider && x.transactionType === data.transactionType
      );

      if (foundIndex !== -1) {
        let item = { ...dataArray[foundIndex] };

        item.newType = data.newType === 'None' ? null : data.newType;

        dispatch(updateTransactionMapping(item));

        dataArray[foundIndex] = item;
      } else {
        data.newType = data.newType === 'None' ? null : data.newType;
        dataArray.push(data);

        dispatch(updateTransactionMapping(data));
      }
    } else if (data.isHidden != null) {
      const foundIndex = dataArray.findIndex(
        (x) =>
          x.serviceProvider === data.serviceProvider && x.transactionType === data.transactionType
      );

      if (foundIndex !== -1) {
        const item = { ...dataArray[foundIndex] };
        item.isHidden = data.isHidden;

        dispatch(updateTransactionMapping(item));

        dataArray[foundIndex] = item;
      } else {
        dataArray.push(data);
        console.log(data);

        dispatch(updateTransactionMapping(data));
      }
    }

    setUpdatedData(dataArray);
  };

  const getSavedValue = (serviceProvider, transactioType) => {
    if (updatedData.length > 0) {
      const foundIndex = updatedData.findIndex(
        (x) => x.serviceProvider === serviceProvider && x.transactionType === transactioType
      );

      if (foundIndex !== -1) {
        return updatedData[foundIndex];
      }
    }
    return;
  };

  const hanldeTypeChange = (event, serviceProvider, transactionType) => {
    const newTransactionType = event.target.value;

    update({ newType: newTransactionType, serviceProvider, transactionType });
  };

  const hanldeHiddenChange = (event, serviceProvider, transactionType) => {
    const isHidden = event.target.checked;

    update({ isHidden, serviceProvider, transactionType });
  };

  return (
    <Box>
      {loadingTypes || loadingMappings ? (
        <OrangeCircularProgress caption={'Loading Transaction Mappings'} />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Map to</TableCell>
              <TableCell>Hidden</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionTypes
              ? transactionTypes.map((ttype, index) => (
                  <MappingRow
                    key={index}
                    transactionType={ttype}
                    serviceProvider={params.id}
                    typeChange={(event) => hanldeTypeChange(event, params.id, ttype)}
                    hiddenChange={(event) => hanldeHiddenChange(event, params.id, ttype)}
                    savedValues={getSavedValue(params.id, ttype)}
                    updatedData={updatedData}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      )}

      <Snackbar
        open={updatingMapping}
        message="Updating Transaction Mapping..."
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
}

export default TransactioMapping;
