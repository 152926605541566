import React, { useEffect } from 'react';
import { RadioGroup, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomRadioGroup = ({ children, control, name, label, rules, ...props }) => {
  return (
    <FormControl error={props.error}>
      <Controller
        control={control}
        name={name}
        defaultValue={props.value || ''}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <RadioGroup onChange={onChange} value={value}>
            {children}
          </RadioGroup>
        )}
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioGroup;
