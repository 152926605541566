import { communicationConstants } from "../constants";

export function communication(state = {}, action) {
  switch (action.type) {
    case communicationConstants.GET_CONTACTS_REQUEST:
      return {
        ...state,
      };
    case communicationConstants.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.contacts.contacts,
        success: action.contacts.success,
      };
    case communicationConstants.GET_CONTACTS_FAILURE:
      return { ...state, error: action.error };

    case communicationConstants.GET_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case communicationConstants.GET_LISTS_SUCCESS:
      return {
        ...state,
        lists: action.result.lists,
        success: action.result.success,
        loading: false,
      };
    case communicationConstants.GET_LISTS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case communicationConstants.CREATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case communicationConstants.CREATE_LIST_SUCCESS:
      return {
        ...state,
        list: action.result.list,
        success: action.result.success,
        loading: false,
      };
    case communicationConstants.CREATE_LIST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case communicationConstants.GET_EMAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case communicationConstants.GET_EMAILS_SUCCESS:
      return {
        ...state,
        emails: action.result.emails,
        success: action.result.success,
        loading: false,
      };
    case communicationConstants.GET_EMAILS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case communicationConstants.CREATE_EMAIL_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case communicationConstants.CREATE_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.result.email,
        success: action.result.success,
        saving: false,
      };
    case communicationConstants.CREATE_EMAIL_FAILURE:
      return { ...state, error: action.error, saving: false };

    default:
      return state;
  }
}
