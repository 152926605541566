import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBalances, listDailyAccounts } from '../../../features/account/account.slice';

import OrangeCircularProgress from '../../ui/controls/circularProgress';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useBanner } from '../../../hooks/bannerProvider';
import { useTitle } from '../../../hooks/titleProvider';
import Overview from './components/overview';
import Products from './components/products';
import DailyAccount from './components/dailyAccount';
import { useParams } from 'react-router-dom';

import { hasAccess, AccessMap, UserFunctions } from '../../../helpers/access';
import ClientUpdate from '../../admin/clients/clientUpdate';

const Balances = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const { setTitle } = useTitle();
  setTitle('Balances');

  const account = useSelector((state) => state.account);
  const user = useSelector((state) => state.auth.user);
  const accountLoading = useSelector((state) => state.account.loadingBalances);

  const dailyAccountList = useSelector((state) => state.account.dailyList);
  const dailyLoading = useSelector((state) => state.account.dailyLoading);

  useEffect(() => {
    if (clientId) {
      console.log('Getting daily accounts for client:', clientId);
      dispatch(listDailyAccounts(clientId));
    } else {
      console.log('Getting daily accounts for user');
      dispatch(listDailyAccounts());
    }
  }, [clientId, dispatch]);

  const { setBanner } = useBanner();
  setBanner(null);

  useEffect(() => {
    if (clientId) {
      console.log('Getting balances for client:', clientId);
      dispatch(getBalances(clientId));
    } else {
      console.log('Getting balances for user');
      dispatch(getBalances());
    }
  }, [clientId, dispatch]);

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      {!accountLoading ? (
        user?.client && user.client.length > 0 && account?.balances ? (
          <Box width={'100%'} maxWidth={'700px'}>
            {/* <Overview account={account} clientId={clientId} /> */}
            <Products account={account} />
            {/* <DailyAccount accountList={dailyAccountList} loading={dailyLoading} /> */}
          </Box>
        ) : (
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h6" color="textSecondary">
              Your account is not available.
            </Typography>
          </Box>
        )
      ) : (
        <OrangeCircularProgress caption="Fetching your profile balances." />
      )}
    </Box>
  );
};

export default Balances;
