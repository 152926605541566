import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Link } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Form from '../ui/forms';

import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { register as registerNewUser } from '../../features/auth/auth.slice';

import Checkbox from '../ui/forms/fields/checkbox';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required.')
    .email('This does not look like a email address.'),
  firstName: yup.string().required('Please enter your first name.'),
  lastName: yup.string().required('Please enter your last name.'),
  phoneNumber: yup.string().required('Please enter your phone number.'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTerms: yup.bool().oneOf([true], 'Accept Ts & Cs is required')
});

function SignUp() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    dispatch(registerNewUser(data));
  };

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'center'}>
      <Box
        display={'flex'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          minWidth: '30vw'
        }}
        p={3}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          sx={{ maxWidth: '350px' }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={'center'}>
          <Typography variant="h2" gutterBottom sx={{ alignText: 'center' }}>
            Sign Up
          </Typography>

          <Form onSubmit={handleSubmit(onSubmit)} sx={{ textAlign: 'center' }}>
            <Input
              id="firstName"
              label="First Name"
              {...register('firstName')}
              autoComplete="firstName"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
            />
            <Input
              id="lastName"
              label="Last Name"
              {...register('lastName')}
              autoComplete="lastName"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
            />
            <Input
              id="email"
              label="Email Address"
              type="email"
              {...register('email')}
              autoComplete="email"
              error={!!errors.email}
              helperText={errors?.email?.message}
            />
            <Input
              id="phoneNumber"
              label="Phone Number (International Format)"
              type="tel"
              {...register('phoneNumber')}
              autoComplete="phoneNumber"
              error={!!errors.phoneNumber}
              helperText={errors?.phoneNumber?.message}
            />

            {/* <Divider className={classes.divider} /> */}
            <Input
              id="password"
              {...register('password')}
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors?.password?.message}
            />

            <Input
              id="confirmPassword"
              {...register('confirmPassword')}
              label="Confirm Password"
              type="password"
              error={!!errors.confirmPassword}
              helperText={errors?.confirmPassword?.message}
            />

            <Divider sx={{ m: theme.spacing(3, 3, 1, 3) }} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'center'}
              alignItems={'center'}
              m={2}>
              <Link
                href="https://fairtree.com/disclaimer/"
                target="_blank"
                variant="body2"
                color={theme.palette.accent.main}>
                {'Disclaimer | '}
              </Link>
              <Link
                href="https://fairtree.com/wp-content/uploads/2021/01/FAM-PAIA-Manual-202001.pdf"
                target="_blank"
                variant="body2"
                color={theme.palette.accent.main}>
                {'PAIA Manual | '}
              </Link>
              <Link
                href="https://fairtree.com/wp-content/uploads/2021/06/FAM-Privacy-Notice-07-06-2021.pdf"
                target="_blank"
                variant="body2"
                color={theme.palette.accent.main}>
                {'Privacy Notice'}
              </Link>
            </Box>

            <Checkbox
              control={control}
              name="acceptTC"
              label="I have read and accept the Privacy Notice, Disclaimer and PAIA Manual"
              color={'accent'}
            />
          </Form>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>Sign Up</PrimaryButton>
          <Typography>
            Already Signed Up? <Link href="/signin">{'Login'}</Link>
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 4,
              borderColor: '#000',
              width: '80px',
              mt: '60px',
              mb: '30px'
            }}
          />
          <Typography variant="p">{'You are signing up as a Fairtree Client'}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SignUp;
