import * as React from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  width: '100%',
  color: theme.palette.text.main,
  background: `${theme.palette.background.paper}`
}));

export default function FCard(props) {
  return <StyledCard {...props}>{props.children}</StyledCard>;
}
