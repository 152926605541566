import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { transactionService } from './transaction.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  loadingTypes: false
};

// export const getAll = createAsyncThunk('account/getSummary', async (thunkAPI) => {
//   return await accountService
//     .getSummary()
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       const err = error.response.data;
//       thunkAPI.dispatch(setAlertError(err.msg));
//       thunkAPI.rejectWithValue(err.msg);
//     });
// });

export const getAll = createAsyncThunk('transaction/getAll', async (data, thunkAPI) => {
  return await transactionService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getById = createAsyncThunk('transaction/getById', async (data, thunkAPI) => {
  return await transactionService
    .getById(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getByAccount = createAsyncThunk('transaction/getByAccount', async (data, thunkAPI) => {
  return await transactionService
    .getByAccount(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      thunkAPI.dispatch(setAlertError(err.msg));
      thunkAPI.rejectWithValue(err.msg);
    });
});

export const getDistinctTransactionTypes = createAsyncThunk(
  'transaction/getDistinctTransactionTypes',
  async (data, thunkAPI) => {
    return await transactionService
      .getDistictTransactionTypesByServiceProvider(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    reset: (state) => {
      state.transaction = null;
      state.transactions = null;
      state.transactionTypes = null;
    }
  },
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.transactions = action.payload.transactions;
      state.loading = false;
    },
    [getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [getAll.rejected]: (state, action) => {
      state.loading = false;
    },
    [getById.fulfilled]: (state, action) => {
      state.transaction = action.payload.transaction;
      state.loading = false;
    },
    [getById.pending]: (state, action) => {
      state.loading = true;
    },
    [getById.rejected]: (state, action) => {
      state.loading = false;
    },
    [getByAccount.fulfilled]: (state, action) => {
      state.transactions = action.payload.transactions;
      state.fees = action.payload.fees;
      state.account = action.payload.account;
      state.loadingTransactions = false;
    },
    [getByAccount.pending]: (state, action) => {
      state.loadingTransactions = true;
    },
    [getByAccount.rejected]: (state, action) => {
      state.loadingTransactions = false;
    },
    [getDistinctTransactionTypes.fulfilled]: (state, action) => {
      state.transactionTypes = action.payload.transactionTypes;
      state.loadingTypes = false;
    },
    [getDistinctTransactionTypes.pending]: (state, action) => {
      state.loadingTypes = true;
    },
    [getDistinctTransactionTypes.rejected]: (state, action) => {
      state.loadingTypes = false;
    }
  }
});

export const { reset } = transactionSlice.actions;

export default transactionSlice.reducer;
