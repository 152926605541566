const userHasClientAccount = (user) => {
  if (user && user.client) {
    return user.client.length > 0;
  }

  return false;
};

export const AuthHelper = {
  userHasClientAccount
};
