import { createSlice } from '@reduxjs/toolkit';
import { taxFreeDataService } from './taxFreeData.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  guidanceTaxFreeData: [],
  loading: false,
  error: null
};

export const taxFreeDataSlice = createSlice({
  name: 'taxfreedata',
  initialState,
  reducers: {
    setTaxFreeData: (state, action) => {
      state.guidanceTaxFreeData = action.payload;
      state.loading = false;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearTaxFreeData: (state) => {
      state.guidanceTaxFreeData = [];
      state.loading = false;
      state.error = null;
    }
  }
});

// Thunk to fetch texFreeData for guidance
export const getTaxFreeDataForGuidance = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await taxFreeDataService.getTaxFreeDataForGuidance();
    dispatch(setTaxFreeData(response));
  } catch (error) {
    const err = error.response?.data || { msg: 'An error occurred' };
    dispatch(setError(err.msg));
    dispatch(setAlertError(err.msg));
  }
};
export const { setTaxFreeData, setLoading, setError, clearTaxFreeData } = taxFreeDataSlice.actions;

export default taxFreeDataSlice.reducer;
