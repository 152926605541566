import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const formService = {
  submitForm
};

function submitForm(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.form_server}/submit`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
