import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import SingleSendDialog from "./components/singleSendDialog";
import { communicationActions } from "../../../redux/actions";

function Emails() {
  const dispatch = useDispatch();
  const emails = useSelector((state) => state.communication.emails);

  useEffect(() => {
    dispatch(communicationActions.getEmails());
  }, [dispatch]);

  return (
    <Fragment>
      <SingleSendDialog />

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email Name</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails
              ? emails.map((email) => (
                  <TableRow key={email._id}>
                    <TableCell>{email.name}</TableCell>
                    <TableCell>{email.subject}</TableCell>
                    <TableCell>{email.list.name}</TableCell>
                    <TableCell>{email.status}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Emails;
