import { IconButton, Typography } from '@mui/material';
import { Box, maxWidth, useTheme } from '@mui/system';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import BriefcaseIcon from '../../resources/images/icons/other/briefcase.png';
import ManIcon from '../../resources/images/icons/other/man.png';
import RoundedButton from '../ui/buttons/roundedButton';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function SignUpLanding() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = theme.breakpoints.down('md');

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} sx={{ mt: 2 }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        mb={3}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          Your Investment Journey
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'center', width: '70vw' }}>
          Select which investment journey you have with Fairtree.
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            backgroundColor: theme.palette.background.backdrop,
            borderRadius: 10,
            width: '70vw',
            maxWidth: '400px'
          }}
          p={theme.spacing(1, 3)}
          m={3}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => navigate('/client/signup')}>
          <Box
            component="img"
            sx={{ height: 45, display: 'block' }}
            alt="Sign up as a client."
            mr={2}
            src={ManIcon}
          />
          <Typography flexGrow={1} variant="p">
            I'm a client
          </Typography>
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            backgroundColor: theme.palette.background.backdrop,
            borderRadius: 10,
            width: '70vw',
            maxWidth: '400px'
          }}
          p={theme.spacing(1, 3)}
          m={3}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => navigate('/advisor/signup')}>
          <Box
            component="img"
            sx={{ height: 45, display: 'block' }}
            alt="Sign up as an advisor."
            src={BriefcaseIcon}
            mr={2}
          />
          <Typography flexGrow={1}>I'm an advisor</Typography>
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default SignUpLanding;
