import * as React from 'react';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  marginRight: theme.spacing(1),
  padding: '0px 20px',
  borderRadius: theme.shape.borderRadius * 8,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  fontSize: '0.7rem',
  height: '30px',
  minHeight: '0px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light
  },
  '&.Mui-disabled': {
    opacity: 0.6
  }
}));

export default function FTab(props) {
  const { loading, label } = props;

  function a11yProps() {
    return {
      id: `simple-tab-${props.index}`,
      'aria-controls': `simple-tabpanel-${props.index}`
    };
  }

  return (
    <StyledTab label={label} disabled={loading ? loading : false} {...a11yProps()} {...props} />
  );
}
