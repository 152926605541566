import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProviderService } from './serviceProvider.service';
import { setAlertError } from '../alert/alert.slice';

const initialState = {
  loadingStatus: false,
  loading: false
};

export const getServiceProviders = createAsyncThunk(
  'serviceProvider/getServiceProviders',
  async (_, thunkAPI) => {
    return await serviceProviderService
      .getServiceProviders()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const getServiceProviderById = createAsyncThunk(
  'serviceProvider/getServiceProviderById',
  async (id, thunkAPI) => {
    return await serviceProviderService
      .getServiceProviderById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateServiceProvider = createAsyncThunk(
  'serviceProvider/updateServiceProvider',
  async (data, thunkAPI) => {
    return await serviceProviderService
      .updateServiceProvider(data.id, data.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateSyncStatusServiceProvider = createAsyncThunk(
  'serviceProvider/updateSyncStatusServiceProvider',
  async (data, thunkAPI) => {
    return await serviceProviderService
      .updateSyncStatusServiceProvider(data.id, data.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const resetServiceProvider = createAsyncThunk(
  'serviceProvider/resetServiceProvider',
  async (id, thunkAPI) => {
    return await serviceProviderService
      .resetServiceProvider(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const serviceProviderSlice = createSlice({
  name: 'serviceProvider',
  initialState,
  reducers: {},
  extraReducers: {
    [getServiceProviders.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceProviders.fulfilled]: (state, action) => {
      state.loading = false;
      state.providers = action.payload?.providers;
    },
    [getServiceProviders.rejected]: (state, action) => {
      state.loading = false;
    },
    [getServiceProviderById.pending]: (state, action) => {
      state.loading = true;
    },
    [getServiceProviderById.fulfilled]: (state, action) => {
      state.loading = false;
      state.provider = action.payload.provider;
    },
    [getServiceProviderById.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateServiceProvider.pending]: (state, action) => {
      state.loading = true;
    },
    [updateServiceProvider.fulfilled]: (state, action) => {
      state.loading = false;
      state.provider = action.payload.provider;
    },
    [updateServiceProvider.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateSyncStatusServiceProvider.pending]: (state, action) => {
      state.loadingStatus = true;
    },
    [updateSyncStatusServiceProvider.fulfilled]: (state, action) => {
      state.loadingStatus = false;

      //Set provider status where provider id = action.payload.provider.id in providers.
      state.providers.forEach((provider) => {
        if (provider._id === action.payload.provider._id) {
          provider.status = action.payload.provider.status;
        }
      });
    },
    [updateSyncStatusServiceProvider.rejected]: (state, action) => {
      state.loadingStatus = false;
    },
    [resetServiceProvider.pending]: (state, action) => {
      state.loading = true;
    },
    [resetServiceProvider.fulfilled]: (state, action) => {
      state.loading = false;
      state.provider = action.payload.provider;
    },
    [resetServiceProvider.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export default serviceProviderSlice.reducer;
