import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import HelpTaxFreeSavings from '../tools/helpHelpTaxFreeSavings';
import HelpBasicInvestment from '../tools/helpBasicInvestment';
import HelpRetirementAnnuity from '../tools/helpRetirementAnnuity';
import HelpOffshoreInvesment from '../tools/helpOffshoreInvestment';
import {
  updateFundFilter,
  updateSubmission
} from '../../../../features/fundFilter/fundFilter.slice';
import { nextStep, setActiveStep } from '../../../../features/guidance/stepper.slice';

const Step2SelectFund = ({ onNext, onBack, onReset }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const vehicle = useSelector((state) => state.fundFilter.vehicle);
  const fundFilterData = useSelector((state) => state.fundFilter);

  const { guidanceVehicles, loading, error } = useSelector((state) => state.vehicle);
  const selectedVehicle = guidanceVehicles?.find((item) => item.name === vehicle);

  const funds = useSelector((state) => state.fund.guidanceFunds);
  const activeStep = useSelector((state) => state.stepper.activeStep);

  const [minimums, setMinimums] = useState(selectedVehicle?.minimums || null);

  useEffect(() => {
    if (vehicle === 'Offshore Investment') {
      const updatedMinimums = selectedVehicle?.minimums;

      const currency = fundFilterData?.helpDialog?.currency;
      if (currency === 'zar') {
        setMinimums({ ...updatedMinimums?.internal, currency: 'ZAR' } || null);
      } else if (currency === 'usd') {
        setMinimums({ ...updatedMinimums?.external, currency: 'USD' } || null);
      } else {
        setMinimums(updatedMinimums || null);
      }
    }
  }, [vehicle, selectedVehicle, fundFilterData]);

  const onHelpNext = async (data) => {
    try {
      await dispatch(
        updateFundFilter({
          ...fundFilterData,
          helpDialog: data
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onUnitTrustRedirect = async (data) => {
    try {
      dispatch(
        updateFundFilter({
          vehicle: 'Basic Investment'
        })
      );
      dispatch(setActiveStep(activeStep));
    } catch (err) {
      console.log(err);
    }
  };

  const onHelpSubmit = (data) => {
    const splitFundIndex = data.funds.findIndex((f) => f.fund.split);

    let f = [];
    if (splitFundIndex !== -1) {
      let splitFund = data.funds[splitFundIndex];

      splitFund.fund.split.map((comp) => {
        const getFund = funds.find((f) => f.id === comp.id);

        f.push({
          fund: getFund,
          lumpsum: splitFund.lumpsum > 0 ? splitFund.lumpsum * (comp.percent / 100) : 0,
          monthly: splitFund.monthly > 0 ? splitFund.monthly * (comp.percent / 100) : 0,
          isHelp: true
        });
      });
    } else {
      f.push(...data.funds);
    }

    dispatch(updateFundFilter({ funds: f, helpDialog: data }));
    dispatch(nextStep());
  };

  const NavButtons = () => (
    <Box
      sx={{
        position: 'sticky',
        bottom: 60,
        display: 'flex',
        alignItems: 'center',
        m: 2,
        mb: 4,
        width: 'fit-content', // Ensures the box width fits the content
        mx: 'auto', // Centers the box horizontally
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        zIndex: 1000
      }}>
      <Button onClick={onBack}>Back</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} /> {/* Vertical divider */}
      <Button onClick={onReset}>Start Over</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} /> {/* Vertical divider */}
    </Box>
  );

  const getVehicleName = (vehicle) => {
    const vehicleNames = {
      'Retirement Annuity': (
        <HelpRetirementAnnuity
          minimums={selectedVehicle?.minimums || null}
          onDataSubmit={(data) => onHelpSubmit(data)}
          onHelpNext={(data) => onHelpNext(data)}
        />
      ),
      'Tax-Free Savings Account': (
        <HelpTaxFreeSavings
          minimums={selectedVehicle?.minimums || null}
          handleRedirectToUnitTrust={() => onUnitTrustRedirect()}
          onDataSubmit={(data) => onHelpSubmit(data)}
          onHelpNext={(data) => onHelpNext(data)}
        />
      ),
      'Basic Investment': (
        <HelpBasicInvestment
          minimums={selectedVehicle?.minimums || null}
          onDataSubmit={(data) => onHelpSubmit(data)}
          onHelpNext={(data) => onHelpNext(data)}
        />
      ),
      'Offshore Investment': (
        <HelpOffshoreInvesment
          minimums={minimums} // Dynamic minimums based on currency
          onDataSubmit={(data) => onHelpSubmit(data)}
          onHelpNext={(data) => onHelpNext(data)}
        />
      )
    };

    return vehicleNames[vehicle] || 'No vehicle selected';
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6">{getVehicleName(vehicle)}</Typography>
      <NavButtons />
    </Box>
  );
};

export default Step2SelectFund;
