import { FormControl, IconButton, InputAdornment, InputLabel, Input } from '@mui/material';
import CopyAll from '@mui/icons-material/CopyAll';

import React from 'react';

const CopyInput = ({ name, label, value, disabled, ...props }) => {
  return (
    <FormControl sx={{ m: 0 }}>
      <InputLabel>{label}</InputLabel>
      <Input
        id="filled-adornment-copy"
        value={value}
        disabled={disabled}
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => props.handleCopy()} edge="end">
              <CopyAll />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default CopyInput;
