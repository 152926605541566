import React from "react";
import { Button, useTheme } from "@mui/material";

const SecondaryButton = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{ margin: theme.spacing(2, 1, 2), fontWeight: 400, borderRadius: 5 }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
