import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  Badge,
  IconButton,
  Typography,
  Paper,
  Box,
  Avatar,
  Stack,
  Chip
} from '@mui/material';
import { styled } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationIcon from '@mui/icons-material/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../features/auth/auth.slice';
import {
  markNotificationAsSeen,
  getUnseenCount,
  dismissNotification,
  getNotifications
} from '../../../features/notification/notification.slice';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import NotificationsList from '../notifications/notificationsList';

const settings = [{ title: 'My Profile', link: '/user/profile' }, { title: 'Sign out' }];

const ProfileMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const host =
    window.location.host.indexOf(':') === -1
      ? window.location.host
      : window.location.host.substring(0, window.location.host.indexOf(':'));

  useEffect(() => {
    dispatch(getNotifications()).unwrap().then(dispatch(getUnseenCount()));
  }, [dispatch]);

  const handleOpenMenu = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenus = () => {
    setAnchorElUser(null);
  };

  const handleNavigateOrLogout = (setting) => {
    if (setting.title === 'Sign out') {
      dispatch(logout());
    } else {
      navigate(setting.link);
    }
    handleCloseMenus();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      {host === 'localhost' && <Chip label="Development" color="info" sx={{ mx: 2 }} />}
      {host === 'staging.fairtreewealth.co.za' && (
        <Chip label="Staging" color="warning" sx={{ mx: 2 }} />
      )}

      <IconButton onClick={handleOpenMenu(setAnchorElUser)} sx={{ p: 1 }}>
        <PersonIcon />
      </IconButton>
      <NotificationsList />
      <Menu
        sx={{ mt: '45px' }}
        id="profile-menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenus}>
        {settings.map((setting, index) => (
          <MenuItem key={index} onClick={() => handleNavigateOrLogout(setting)}>
            <Typography textAlign="center">{setting.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
