import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Link } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Form from '../ui/forms';

import { useDispatch, useSelector } from 'react-redux';
import { sendPasswordReset } from '../../features/auth/auth.slice';

import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import OrangeCircularProgress from '../ui/controls/circularProgress';
import PrimaryButton from '../ui/buttons/primaryButton';
import Input from '../ui/forms/fields/input';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter your email address')
    .required('Your email address is required.')
});

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const loading = useSelector((state) => state.auth.loading);
  const success = useSelector((state) => state.auth.success);

  const onSubmit = (data) => {
    dispatch(sendPasswordReset(data))
      .unwrap()
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'center'}>
      <Box
        display={'flex'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          minWidth: '30vw'
        }}
        p={3}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          sx={{
            maxWidth: '350px'
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={'center'}>
          <Typography variant="h2" gutterBottom textAlign="center">
            Forgot your password?
          </Typography>

          {loading ? (
            <OrangeCircularProgress />
          ) : !success ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} sx={{ textAlign: 'center' }}>
                <Input
                  id="email"
                  label="Email Address"
                  type="email"
                  {...register('email')}
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              </Form>
              <PrimaryButton onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
            </>
          ) : (
            <>
              <Typography m={3} textAlign="center">
                Please check your inbox for a recovery email. Please make sure to check your SPAM
                box also.
              </Typography>
              <PrimaryButton onClick={() => navigate('/signin')}>Back to sign in</PrimaryButton>
            </>
          )}

          <Divider
            sx={{
              borderBottomWidth: 4,
              borderColor: '#000',
              width: '80px',
              mt: '80px',
              mb: '30px'
            }}
          />
          <Typography variant="p">
            {'Remembered your password click '}
            <Link href="/signin">{'here'}</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
