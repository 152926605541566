import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material';

const Input = forwardRef((props, ref) => {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1.5px solid',
          borderColor: theme.palette.text.main,
          borderRadius: '7px'
        },
        '& .MuiInputBase-input': {
          color: theme.palette.text.main
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.text.main
        },
        '&:focus-within .MuiInputLabel-root': {
          color: theme.palette.text.main,
          fontWeight: 'bold'
        }
      }}
      variant="outlined"
      margin="dense"
      inputRef={ref}
      fullWidth
      {...props}
      size="small"
    />
  );
});
export default Input;
