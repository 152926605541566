import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../../redux/actions';
import { Box, CardActions } from '@mui/material';

import OrangeCircularProgress from '../../../ui/controls/circularProgress';
import GeneralButton from '../../../ui/buttons/generalButton';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import ProductAddEdit from './addEditDialog';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import FCard from '../../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../../ui/fairtreeComponents/card/fCardHeader';
import FDialog from '../../../ui/fairtreeComponents/dialog/fDialog';

function Products() {
  const dispatch = useDispatch();

  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [openConfirmDelete, setopenConfirmDelete] = useState(false);

  const [formType, setFormType] = useState('Add');
  const [selectedItem, setSelectedItem] = useState(null);

  const loading = useSelector((state) => state.product.loading);
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    dispatch(productActions.getAll());
  }, [dispatch]);

  const openAdd = (formType, item) => {
    setOpenAddEdit(true);
    setFormType(formType);

    if (item) {
      setSelectedItem(item);
    }
  };

  const handleSave = () => {
    dispatch(productActions.getAll());
    setOpenAddEdit(false);
  };

  const handleClose = () => {
    setOpenAddEdit(false);
  };

  const hanldeDelete = (product) => {
    setSelectedItem(product);
    setopenConfirmDelete(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(productActions.remove(selectedItem._id));
    setopenConfirmDelete(false);
    setSelectedItem({});
  };

  const handleDeleteCancel = () => {
    setSelectedItem({});
    setopenConfirmDelete(false);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: '100%', maxWidth: '700px' }}>
        <PrimaryButton fullWidth={false} onClick={() => openAdd('Add')}>
          Add New Product
        </PrimaryButton>
        {!loading ? (
          products &&
          products.map((product) => (
            <FCard key={product._id} sx={{ mb: 2 }}>
              <FCardHeader title={product.name} />
              <CardActions
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                {' '}
                <PrimaryButton onClick={() => openAdd('Edit', product)} sx={{ mr: 2 }} size="small">
                  Edit
                </PrimaryButton>
                <PrimaryButton onClick={() => hanldeDelete(product)} color="warning" size="small">
                  Delete
                </PrimaryButton>
              </CardActions>
            </FCard>
          ))
        ) : (
          <FCard>
            <OrangeCircularProgress caption="Loading Products..." />
          </FCard>
        )}

        <FDialog
          fullWidth={true}
          maxWidth="lg"
          open={openAddEdit}
          onClose={() => handleClose()}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle>{formType === 'Add' ? 'Add new' : 'Edit'} product</DialogTitle>
          <DialogContent>
            <ProductAddEdit
              formType={formType}
              selectedItem={selectedItem}
              onSave={() => handleSave()}
              onCancel={() => handleClose()}
            />
          </DialogContent>
        </FDialog>
        <FDialog
          maxWidth="lg"
          open={openConfirmDelete}
          onClose={() => handleDeleteCancel()}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle>Delete {selectedItem?.name} Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the {selectedItem?.name} Product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <GeneralButton
              onClick={() => handleDeleteCancel()}
              variant="outlined"
              fullWidth={false}>
              Cancel
            </GeneralButton>
            <GeneralButton onClick={() => handleDeleteConfirmation()} fullWidth={false}>
              Delete
            </GeneralButton>
          </DialogActions>
        </FDialog>
      </Box>
    </Box>
  );
}

export default Products;
