import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, IconButton, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import moment from 'moment';
import { numberToCurrency } from '../../utils/currency';

import DownloadIcon from '@mui/icons-material/DownloadRounded';
import { fileStorageService } from '../../../features/fileStorage/fileStorage.service';

const DocumentRow = ({ document }) => {
  const handleDownload = (id, fileName) => {
    fileStorageService.downloadFile(id, fileName);
  };

  return (
    <Fragment>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mb={1}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} mb={1}>
          <Typography variant="subtitle2">{document.fileName}</Typography>
          {document.fileDate && document.fileDate !== 'Invalid date' && (
            <>
              <Typography variant="subtitle1">
                {moment.utc(document.fileDate).format('DD MMMM YYYY')}
              </Typography>
            </>
          )}
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <IconButton
            color="primary"
            title="Download"
            onClick={() => handleDownload(document._id, document.fileName)}>
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 1, mb: 1 }} />
    </Fragment>
  );
};

DocumentRow.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentRow;
