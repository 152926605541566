import { config } from "../config";
import { authHeader } from "../helpers";
import axios from "axios";

export const transactionTypeService = {
  getAll,
  getById,
  create,
  update,
  remove,
  getTransactionMappings,
  updateTransactionMapping,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.transactiontype_server}/`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(`${config.transactiontype_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.transactiontype_server}/`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function update(id, data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .put(`${config.transactiontype_server}/${id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .delete(`${config.transactiontype_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getTransactionMappings(serviceProvider) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .get(
      `${config.transactiontype_server}/mapping/${serviceProvider}`,
      requestOptions
    )
    .then((responce) => {
      return responce.data;
    });
}

function updateTransactionMapping(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  return axios
    .post(`${config.transactiontype_server}/mapping`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
