import { fundConstants } from '../constants';

export function fund(state = {}, action) {
  switch (action.type) {
      case fundConstants.GET_FUNDS_REQUEST:
            return { loading: true };
      case fundConstants.GET_FUNDS_SUCCESS:
            return { success : action.funds.success, loading: false, ...action.funds };
      case fundConstants.GET_FUNDS_FAILURE:
            return { error: action.error, loading: false };

      case fundConstants.GET_FUND_REQUEST:
            return { ...state, loading: true };
      case fundConstants.GET_FUND_SUCCESS:
            return { ...state, fund : action.fund.fund, success : action.fund.success, loading: false };
      case fundConstants.GET_FUND_FAILURE:
            return { ...state, error: action.error, loading: false };

      case fundConstants.CREATE_FUND_REQUEST:
            return { ...state, loading: true };
      case fundConstants.CREATE_FUND_SUCCESS:
            return { ...state, success : action.fund.success, fund: action.fund.fund, loading: false };
      case fundConstants.CREATE_FUND_FAILURE:
            return { ...state, error: action.error, loading: false };
            
    default:
      return state
  }
}