import React, { useEffect, useState } from 'react';
import RoundedIconTile from '../tiles/roundedIconTile';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { generateNavItems } from '../../../lib/menuItems';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useBanner, banners } from '../../../hooks/bannerProvider';

const MainMenuItems = () => {
  const user = useSelector((state) => state.auth.user);

  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    if (user) {
      const navItems = generateNavItems(user);
      setNavItems(navItems);
    }
  }, [user, user?.client]);

  const { setBanner } = useBanner();

  useEffect(() => {
    setBanner(banners.default);
  }, [setBanner]);

  return navItems.map((item, index) =>
    item.hide
      ? null
      : item.access && (
          <Box
            key={index}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'start'}
            alignItems={'start'}
            sx={{
              marginBottom: '30px'
            }}>
            <Box display={'flex'} justifyContent={'start'} alignItems={'start'}>
              {item.icon}
              <Typography ml={1} variant="h5">
                {item.title}
              </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'start'} alignItems={'start'} flexWrap="wrap">
              {item.items.map(
                (subItem, index) =>
                  subItem.access &&
                  !subItem.hide && (
                    <RoundedIconTile
                      key={index}
                      title={subItem.text}
                      icon={subItem.icon}
                      image={subItem.image ? subItem.image : null}
                      component={NavLink}
                      to={subItem.link}
                    />
                  )
              )}
            </Box>
          </Box>
        )
  );
};

export default MainMenuItems;
