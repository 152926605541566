export const fundFilterCalculation = (acheiveBy, withdraw, risk, riskProfile) => {
  const acheiveByResult = acheiveBy.points * acheiveBy.weight;
  const withdrawResult = withdraw.points * withdraw.weight;
  const riskByResult = risk.points * risk.weight;
  const riskProfileResult = riskProfile.points * riskProfile.weight;
  const ans = acheiveByResult + withdrawResult + riskByResult + riskProfileResult;

  return ans / 100;
};

export const fundFilterCalculationTFSA = (withdraw, risk, riskProfile) => {
  const riskByResult = risk.points * risk.weight;
  const riskProfileResult = riskProfile.points * riskProfile.weight;
  const withdrawResult = withdraw.points * withdraw.weight;

  const ans = withdrawResult + riskByResult + riskProfileResult;

  return ans / 100;
};
