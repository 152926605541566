import * as React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const StyledCardCurrencyTypography = styled(Typography)(({ theme }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return {
    fontSize: isSmallScreen ? '0.7rem' : '0.9rem',
    fontWeight: 'bold',
    ml: '2px'
  };
});

export default function FCardCurrencyTypography(props) {
  return <StyledCardCurrencyTypography {...props}>{props.children}</StyledCardCurrencyTypography>;
}
