import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';
import { jobService } from './job.service';

const initialState = {};

export const getJobs = createAsyncThunk('job/getJobs', async (data, { dispatch }) => {
  try {
    const response = await jobService.getAll();
    return response;
  } catch (error) {
    dispatch(setAlertError(error.message));
  }
});

export const getAllJobsByCategory = createAsyncThunk(
  'job/getAllJobsByCategory',
  async (data, { dispatch }) => {
    try {
      const response = await jobService.getAllByCategory(data);
      return response;
    } catch (error) {
      dispatch(setAlertError(error.message));
    }
  }
);

export const getJobStatus = createAsyncThunk('job/getJobStatus', async (data, { dispatch }) => {
  try {
    const response = await jobService.getJobStatus(data);
    return response;
  } catch (error) {
    dispatch(setAlertError(error.message));
  }
});

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJobs.fulfilled, (state, action) => {
        state.jobs = action.payload;
        state.loadingJobs = false;
      })
      .addCase(getAllJobsByCategory.fulfilled, (state, action) => {
        state.jobs = action.payload;
        state.loadingJobs = false;
      })
      .addCase(getJobStatus.fulfilled, (state, action) => {
        state.jobStatus = action.payload;
        state.loadingJobStatus = false;
      });
  }
});

export default jobSlice.reducer;
