import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const transactionService = {
  getAll,
  getById,
  getByAccount,
  getDistictTransactionTypesByServiceProvider
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.transaction_server}/`, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.transaction_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getByAccount(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.transaction_server}/account/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getDistictTransactionTypesByServiceProvider(serviceProvider) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.transaction_server}/types/serviceprovider/${serviceProvider}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}
