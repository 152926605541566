import axiosInstance from '../axiosInstance';
import { config } from '../config';
import { authHeader } from '../helpers';

export const transactionTypeService = {
  getAll,
  getById,
  create,
  update,
  remove,
  getTransactionMappings,
  updateTransactionMapping
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.transactiontype_server}/`, requestOptions).then((response) => {
    return response.data;
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.transactiontype_server}/${id}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.transactiontype_server}/`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function update(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.transactiontype_server}/${id}`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.transactiontype_server}/${id}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function getTransactionMappings(serviceProvider) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.transactiontype_server}/mapping/${serviceProvider}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function updateTransactionMapping(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.transactiontype_server}/mapping`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}
