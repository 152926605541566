import { communicationConstants } from "../constants";
import { communicationService } from "../services";
import { alertActions } from "./";

export const communicationActions = {
  getContacts,
  createList,
  getLists,
  getEmails,
  createEmail,
};

function getContacts() {
  return (dispatch) => {
    dispatch(request());

    return communicationService.getContacts().then(
      (contacts) => {
        dispatch(success(contacts));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: communicationConstants.GET_CONTACTS_REQUEST };
  }
  function success(contacts) {
    return { type: communicationConstants.GET_CONTACTS_SUCCESS, contacts };
  }
  function failure(error) {
    return { type: communicationConstants.GET_CONTACTS_FAILURE, error };
  }
}

function getLists() {
  return (dispatch) => {
    dispatch(request());

    return communicationService.getLists().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: communicationConstants.GET_LISTS_REQUEST };
  }
  function success(result) {
    return { type: communicationConstants.GET_LISTS_SUCCESS, result };
  }
  function failure(error) {
    return { type: communicationConstants.GET_LISTS_FAILURE, error };
  }
}

function createList(data) {
  return (dispatch) => {
    dispatch(request());

    return communicationService.createList(data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: communicationConstants.CREATE_LIST_REQUEST };
  }
  function success(result) {
    return { type: communicationConstants.CREATE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: communicationConstants.CREATE_LIST_FAILURE, error };
  }
}

function getEmails() {
  return (dispatch) => {
    dispatch(request());

    return communicationService.getEmails().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: communicationConstants.GET_EMAILS_REQUEST };
  }
  function success(result) {
    return { type: communicationConstants.GET_EMAILS_SUCCESS, result };
  }
  function failure(error) {
    return { type: communicationConstants.GET_EMAILS_FAILURE, error };
  }
}

function createEmail(data) {
  return (dispatch) => {
    dispatch(request());

    return communicationService.createEmail(data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: communicationConstants.CREATE_EMAIL_REQUEST };
  }
  function success(result) {
    return { type: communicationConstants.CREATE_EMAIL_SUCCESS, result };
  }
  function failure(error) {
    return { type: communicationConstants.CREATE_EMAIL_FAILURE, error };
  }
}
