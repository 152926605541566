import React, { useEffect, useState } from 'react';
import { transactionTypeActions } from '../../../../redux/actions/transactionType.actions';
import { useSelector, useDispatch } from 'react-redux';

import { Box, CardActions } from '@mui/material';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import GeneralButton from '../../../ui/buttons/generalButton';
import TransactionTypeDialog from './dialogs/transactionTypeDialog';
import FDialog from '../../../ui/fairtreeComponents/dialog/fDialog';
import PrimaryButton from '../../../ui/buttons/primaryButton';
import FCard from '../../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../../ui/fairtreeComponents/card/fCardHeader';

function TransactionTypes() {
  const dispatch = useDispatch();

  const [openTransactionTypeDialog, setOpenTransactionTypeDialog] = useState(false);
  const [openConfirmDelete, setopenConfirmDelete] = useState(false);
  const [selectedType, setSelectedType] = useState();

  const transactionTypes = useSelector((state) => state.transactionType.transactionTypes);

  useEffect(() => {
    dispatch(transactionTypeActions.getAll());
  }, [dispatch]);

  const handleEdit = (t_type) => {
    setSelectedType(t_type);
    setOpenTransactionTypeDialog(true);
  };

  const handleAdd = () => {
    setOpenTransactionTypeDialog(true);
  };

  const handleTransactionTypeDialogClose = () => {
    setOpenTransactionTypeDialog(false);
    //dispatch(transactionTypeActions.getAll());
  };

  const hanldeDelete = (t_type) => {
    setSelectedType(t_type);
    setopenConfirmDelete(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(transactionTypeActions.remove(selectedType._id));
    setopenConfirmDelete(false);
    setSelectedType({});
  };

  const handleDeleteCancel = () => {
    setSelectedType({});
    setopenConfirmDelete(false);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: '100%', maxWidth: '700px' }}>
        <PrimaryButton onClick={() => handleAdd()}>Add New Transaction Type</PrimaryButton>

        {transactionTypes &&
          transactionTypes.map((item) => (
            <FCard key={item._id} sx={{ mb: 2 }}>
              <FCardHeader title={item.name} />
              <CardActions
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                {' '}
                <PrimaryButton onClick={() => handleEdit(item)} sx={{ mr: 2 }} size="small">
                  Edit
                </PrimaryButton>
                <PrimaryButton onClick={() => hanldeDelete(item)} color="warning" size="small">
                  Delete
                </PrimaryButton>
              </CardActions>
            </FCard>
          ))}

        <TransactionTypeDialog
          open={openTransactionTypeDialog}
          onClose={() => handleTransactionTypeDialogClose()}
          selectedTransactionType={selectedType}
        />

        <FDialog
          maxWidth="lg"
          open={openConfirmDelete}
          onClose={() => handleDeleteCancel()}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle>Delete {selectedType?.name} Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the {selectedType?.name} Product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <GeneralButton
              onClick={() => handleDeleteCancel()}
              variant="outlined"
              fullWidth={false}>
              Cancel
            </GeneralButton>
            <GeneralButton onClick={() => handleDeleteConfirmation()} fullWidth={false}>
              Delete
            </GeneralButton>
          </DialogActions>
        </FDialog>
      </Box>
    </Box>
  );
}

export default TransactionTypes;
