import { serviceProviderConstants } from '../constants';

export function serviceProvider(state = {}, action) {
  switch (action.type) {
      case serviceProviderConstants.SERVICEPROVIDERS_REQUEST:
            return { loading: true };
      case serviceProviderConstants.SERVICEPROVIDERS_SUCCESS:
            return { providers : action.providers.providers, success : action.providers.success, loading: false };
      case serviceProviderConstants.SERVICEPROVIDERS_FAILURE:
            return { error: action.error, loading: false };

      case serviceProviderConstants.UPDATE_SERVICEPROVIDER_REQUEST:
            return { ...state, loading: true };
      case serviceProviderConstants.UPDATE_SERVICEPROVIDER_SUCCESS:
            return { ...state, provider : action.provider.provider, success : action.provider.success, loading: false };
      case serviceProviderConstants.UPDATE_SERVICEPROVIDER_FAILURE:
            return { ...state, error: action.error, loading: false };

      case serviceProviderConstants.RESET_SERVICEPROVIDER_REQUEST:
            return { ...state, loading: true };
      case serviceProviderConstants.RESET_SERVICEPROVIDER_SUCCESS:
            return { ...state, success : action.result.success, loading: false };
      case serviceProviderConstants.RESET_SERVICEPROVIDER_FAILURE:
            return { ...state, error: action.error, loading: false };
            
    default:
      return state
  }
}