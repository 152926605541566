import { useTheme } from '@emotion/react';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { resetPassword } from '../../features/auth/auth.slice';
import OrangeCircularProgress from '../ui/controls/circularProgress';
import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  token: yup.string().required('A reset token is required for this request.')
});

function UpdatePassword(props) {
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = params.token ? params.token : null;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const loading = useSelector((state) => state.auth.loading);
  const success = useSelector((state) => state.auth.success);

  const onSubmit = (data) => {
    dispatch(resetPassword(data))
      .unwrap()
      .then((result) => {
        if (result) {
          navigate('/signin');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'center'}>
      <Box
        display={'flex'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          minWidth: '30vw'
        }}
        p={3}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          sx={{ maxWidth: '350px' }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={'center'}>
          <Typography variant="h2" gutterBottom textAlign="center">
            Select a new Password.
          </Typography>

          {loading ? (
            <OrangeCircularProgress />
          ) : !success ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} sx={{ textAlign: 'center' }}>
                <Input
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  {...register('password')}
                />
                <Input
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  autoComplete="current-password"
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  {...register('confirmPassword')}
                />
                <input {...register('token')} type="hidden" value={token} />

                {!!errors.token ? (
                  <Typography color="error">{errors?.token?.message}</Typography>
                ) : null}
              </Form>
              <PrimaryButton onClick={handleSubmit(onSubmit)}>Update Password</PrimaryButton>
            </>
          ) : (
            <>
              <Typography>Your password was updated.</Typography>
              <PrimaryButton onClick={() => navigate('/signin')}>Sign In</PrimaryButton>
            </>
          )}

          <Divider
            sx={{
              borderBottomWidth: 4,
              borderColor: '#000',
              width: '80px',
              mt: '80px',
              mb: '30px'
            }}
          />
          <Typography variant="p">
            {'To cancel and go back to login, click '}
            <Link href="/signin">{'here'}</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default UpdatePassword;
