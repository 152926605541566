import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createFairtreeTheme } from '../resources/theme';

import { BrowserRouter } from 'react-router-dom';
import MainRoutes from '../main.routes';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../features/helpers';
import { clearAlert } from '../features/alert/alert.slice';
import { logout } from '../features/auth/auth.slice';
import { Button, Snackbar, SnackbarContent } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import TitleProvider from '../hooks/titleProvider';
//import useTokenExpiration from '../hooks/useTokenExpiration';

import useIdle from '../hooks/useIdleTimeout';
import ActivityCountdownDialog from './ui/activityCountdownDialog';
import moment from 'moment';
import { useServiceWorker } from '../useServiceWorker';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// function CustomAlert(props) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const CountdownDialog = ({
//   open,
//   expiresIn,
//   handleClose,
//   handleRefresh,
//   expiresInPercentage,
//   handleLogOut,
//   refreshTokenLoading
// }) => (
//   <Dialog
//     fullWidth
//     maxWidth={'sm'}
//     open={open}
//     onClose={handleClose}
//     sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
//     <DialogTitle>Session Expiring</DialogTitle>
//     <DialogContent>
//       <Typography variant="body2">Seconds Left: </Typography>
//       <Box
//         sx={{
//           position: 'relative',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           minHeight: '100px',
//           width: '250px'
//         }}>
//         <CircularProgress size={'4rem'} variant="determinate" value={expiresInPercentage} />
//         <Box
//           sx={{
//             position: 'absolute',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             width: '100%',
//             height: '100%',
//             top: 0
//           }}>
//           <Typography variant="h3" color="text.secondary">
//             {expiresIn}
//           </Typography>
//         </Box>
//       </Box>
//     </DialogContent>
//     <DialogActions
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         textAlign: 'center'
//       }}>
//       <PrimaryButton onClick={handleLogOut}>Log Out</PrimaryButton>
//       <PrimaryButton onClick={handleRefresh} sx={{ color: '#CC6323' }}>
//         Continue{' '}
//         {refreshTokenLoading && <CircularProgress size={'1rem'} sx={{ ml: 2, color: '#CC6323' }} />}
//       </PrimaryButton>
//     </DialogActions>
//   </Dialog>
// );

function App() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [showNewVersionPopup, setShowNewVersionPopup] = useState(false);

  const refreshTokenLoading = useSelector((state) => state.auth?.refreshTokenLoading);
  const { message, type, open } = useSelector((state) => state.alert);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const theme = React.useMemo(() => createFairtreeTheme(mode), [mode]);
  const dispatch = useDispatch();

  const [openActivityDialog, setOpenActivityDialog] = useState(false);
  const token = useSelector((state) => state.auth.user?.token);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowNewVersionPopup(true);
    } else setShowNewVersionPopup(false);
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    //setMode(prefersDarkMode ? 'dark' : 'light');
    setMode('light');
  }, [prefersDarkMode]);

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearAlert());
    });
  }, [dispatch]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        setMode('light');
      }
    }),
    []
  );

  const handleClose = (event, reason) => {
    dispatch(clearAlert());
  };

  // const handleDialogClose = () => setOpenDialog(false);

  // const handleRefresh = async () => {
  //   await dispatch(refreshToken())
  //     .unwrap()
  //     .then(() => {
  //       setOpenDialog(false);
  //     });
  // };
  let tokenExpirationTime = null;
  let tokenExpirationTimeInSecondsFromNow = 0;
  //const currentTime = Date.now() / 1000;

  const handleLogout = () => {
    dispatch(logout());
    setOpenActivityDialog(false);
  };

  //Make sure user is logged out when session token expired.
  if (token) {
    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      tokenExpirationTime = tokenPayload.exp;

      if (tokenExpirationTime < moment().unix()) {
        console.log('logout');
        dispatch(logout());
      }
    } catch (error) {
      console.error('Failed to parse token', error);
    }
  }

  // const { expiresIn, expiresInPercentage, openDialog, setOpenDialog } =
  // useSessionCountdown(tokenExpirationTime);

  const handleIdle = () => {
    if (token) {
      setOpenActivityDialog(true);
    } else {
      idleTimer.reset();
    }
  };

  const stay = () => {
    setOpenActivityDialog(false);
    idleTimer.reset();
  };

  const { idleTimer } = useIdle({
    onIdle: handleIdle,
    idleTime: 60 * 5,
    promptBeforeIdle: 90
  });

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={type}>
              {message !== undefined && message.length > 0
                ? message
                : type === 'error'
                ? 'An unknown error accrued please report this to the administrator.'
                : ''}
            </Alert>
          </Snackbar>
          {openActivityDialog && (
            <ActivityCountdownDialog
              handleLogOut={() => handleLogout()}
              handleRefresh={stay}
              open={openActivityDialog}
              remainingTime={idleTimer.getRemainingTime()}
              timeLimit={60}
            />
          )}

          <Snackbar
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={showNewVersionPopup}
            title="New Update">
            <SnackbarContent
              message={'A new version is available.'}
              action={
                <Button onClick={() => reloadPage()} sx={{ color: theme.palette.accent.main }}>
                  Update
                </Button>
              }
            />
          </Snackbar>

          <TitleProvider>
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
          </TitleProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

export { App };
