import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from './layout';

import { AccessMap, UserFunctions, hasAccess } from '../helpers/access';
import PrivateLayout from './privateLayout';

const OnboardingRoute = (props) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  if (!user) {
    return <Navigate to={'/signin'} />;
  }

  if (user && user.twoFactorAuthEnabled && !user.otpVerified) {
    return <Navigate to={'/verify/otp'} />;
  }

  // if (
  //   hasAccess(user, UserFunctions.AdminView, AccessMap.Read) ||
  //   hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Read)
  // )
  //   return <Navigate to={'/admin'} />;

  // if (hasAccess(user, UserFunctions.AdvisorAdmin, AccessMap.Read))
  //   return <Navigate to={'/advisor/clients'} />;

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default OnboardingRoute;
