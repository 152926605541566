import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AccessMap, hasAccess } from '../helpers/access';
import AdvisorLayout from './advisorLayout';
import PrivateLayout from './privateLayout';

const AdvisorRoute = (props) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  let userAccess = false;

  if (props.userFunction) {
    if (hasAccess(user, props.userFunction, AccessMap.Read)) {
      userAccess = true;
    }

    if (!userAccess) {
      return <Navigate to={{ pathname: '/home' }} />;
    }
  } else {
    return <Navigate to={{ pathname: '/home' }} />;
  }

  return localStorage.getItem('user') ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={{ pathname: '/signin', state: { from: props.location } }} />
  );
};

export default AdvisorRoute;
