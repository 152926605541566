import React, { useEffect } from 'react';
import { Box, CardContent, CardActions, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../redux/actions';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { AccessMap, hasAccess, UserFunctions } from '../../../helpers/access';

import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import AddUpdateDialog from './addUpdateDialog';

function Entities() {
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.entity.entities);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(entityActions.getAll());
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxWidth={'700px'}>
        <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Entities
          </Typography>
          <AddUpdateDialog />
        </Box>
        {entities
          ? entities.map((entity, index) => (
              <FCard title="Entities">
                <FCardHeader title={entity.name} subheader={entity.entitySystemType} />
                <CardContent>
                  <Box>
                    <Typography variant="h5" gutterBottom>
                      Primary Contact
                    </Typography>
                    <Box>{entity.contactPrimaryNumber}</Box>
                    <Box>{entity.contactEmailAddress}</Box>
                  </Box>
                </CardContent>
                <CardActions sx={{ width: '100%', justifyContent: 'flex-end' }}>
                  {hasAccess(user, UserFunctions.Entities, AccessMap.Update) && (
                    <AddUpdateDialog entity={entity} />
                  )}

                  {hasAccess(user, UserFunctions.Entities, AccessMap.Execute) && (
                    <PrimaryButton disabled>Import</PrimaryButton>
                  )}

                  {hasAccess(user, UserFunctions.Entities, AccessMap.Execute) && (
                    <PrimaryButton disabled>Invite</PrimaryButton>
                  )}
                </CardActions>
              </FCard>
            ))
          : null}
      </Box>
    </Box>
  );
}

export default Entities;
