import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { client } from './client.reducer';
import { account } from './account.reducer';
import { serviceProvider } from './serviceProvider.reducer';
import { fund } from './fund.reducer';
import { transaction } from './transactions.reducer';
import { transactionType } from './transactionType.reducer';
import { houseModel } from './houseModel.reducer';
import { product } from './product.reducer';
import { role } from './role.reducer';
import { userFunction } from './userFunction.reducer';
import { entity } from './entity.reducer';
import { calculation } from './calculation.reducer';
import { communication } from './communication.reducer';
import { fileStorage } from './fileStorage.reducer';
import { mdd } from './mdd.reducer';

const rootReducer = combineReducers({
  alert,
  auth,
  client,
  account,
  serviceProvider,
  fund,
  transaction,
  transactionType,
  houseModel,
  product,
  role,
  userFunction,
  entity,
  calculation,
  communication,
  fileStorage,
  mdd
});

export default rootReducer;
