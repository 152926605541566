export const clientConstants = {
  GET_ALL_REQUEST: 'GET_ALL_CLIENT_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_CLIENT_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_CLIENT_FAILURE',

  GET_BYID_REQUEST: 'GET_BYID_CLIENT_REQUEST',
  GET_BYID_SUCCESS: 'GET_BYID_CLIENT_SUCCESS',
  GET_BYID_FAILURE: 'GET_BYID_CLIENT_FAILURE',

  GET_BALANCES_REQUEST: 'GET_BALANCES_CLIENT_REQUEST',
  GET_BALANCES_SUCCESS: 'GET_BALANCES_CLIENT_SUCCESS',
  GET_BALANCES_FAILURE: 'GET_BALANCES_CLIENT_FAILURE'
};
