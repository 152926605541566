import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../ui/buttons/primaryButton';
import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import { Typography, Divider } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { cancelOnBoarding, verifyId } from '../../features/auth/auth.slice';
import OTPForm from './identityOTPForm';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/titleProvider';
import CollapsibleInfoCard from '../ui/cards/collapsibleInfoCard';

const schema = yup.object().shape({
  idNumber: yup.string().required('This is a required field.'),
  entityType: yup.string()
});

const FamilyMemberOnboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  setTitle('Lets get you started.');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const userInfo = useSelector((state) => state.auth.userInfo);

  const onIDSubmit = (data) => {
    data.entityType = 'Person';
    dispatch(verifyId(data));
  };

  const handleCancel = () => {
    dispatch(cancelOnBoarding());
    navigate('/');
  };

  const idInputForm = () => (
    <>
      <Form>
        <Input
          id="idNumber"
          label="ID Number / Passport"
          error={!!errors.idNumber}
          helperText={errors?.idNumber?.message}
          {...register('idNumber')}
        />
      </Form>
      <PrimaryButton onClick={handleSubmit(onIDSubmit)}>Next</PrimaryButton>
    </>
  );

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Typography variant="h3" gutterBottom>
          Let's get you started.
        </Typography>
        <Typography variant="p" textAlign={'center'}>
          For security purposes, we need to verify the profile you wish to link with. Please provide
          us with the ID Number/Passport.
        </Typography>
        <Divider
          sx={{ borderBottomWidth: 4, borderColor: '#000', width: '80px', mt: '30px', mb: '30px' }}
        />
      </Box>

      <Box
        mt={2}
        sx={{
          maxWidth: '350px'
        }}
        display="flex"
        flexDirection="column"
        justifyContent={'center'}
        alignItems={'center'}>
        {!userInfo ? (
          idInputForm()
        ) : (
          <OTPForm
            phoneNumber={userInfo.phoneNumber}
            idNumber={userInfo.idNumber}
            email={userInfo.email}
            caption={'Please keep an eye out for the OTP sent to the account owners email inbox'}
          />
        )}

        <CollapsibleInfoCard>
          The OTP will be sent to the account owners' email address which Fairtree has on record.
          The account owner will need to authorise your access, by providing you with the OTP.
        </CollapsibleInfoCard>
      </Box>

      <Typography variant="p">
        {'If you want to cancel, click '}
        <Typography
          onClick={() => handleCancel()}
          sx={{ display: 'inline', textDecoration: 'underline', cursor: 'pointer' }}>
          {'here'}
        </Typography>
      </Typography>
    </Box>
  );
};

export default FamilyMemberOnboarding;
