import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fundService } from './fund.service';

export const getFundsForGuidance = createAsyncThunk(
  'funds/getFundsForGuidance',
  async (query, { rejectWithValue }) => {
    try {
      const response = await fundService.getFundsForGuidance(query);
      return response;
    } catch (error) {
      const err = error.response?.funds || { msg: 'An error occurred' };
      return rejectWithValue(err.msg);
    }
  }
);

export const getAllFunds = createAsyncThunk(
  'funds/getAllFunds',
  async (query, { rejectWithValue }) => {
    try {
      const response = await fundService.getAllFunds(query);
      return response;
    } catch (error) {
      const err = error.response?.funds || { msg: 'An error occurred' };
      return rejectWithValue(err.msg);
    }
  }
);

export const getFund = createAsyncThunk('funds/getFund', async (id, { rejectWithValue }) => {
  try {
    const response = await fundService.getFund(id);
    return response;
  } catch (error) {
    const err = error.response?.data || { msg: 'An error occurred' };
    return rejectWithValue(err.msg);
  }
});

export const createFund = createAsyncThunk(
  'funds/createFund',
  async (data, { rejectWithValue }) => {
    try {
      const response = await fundService.createFund(data);
      return response;
    } catch (error) {
      const err = error.response?.data || { msg: 'An error occurred' };
      return rejectWithValue(err.msg);
    }
  }
);

const initialState = {
  guidanceFunds: [],
  funds: [],
  selectedFund: null,
  loading: false,
  error: null,
  totalPages: 0
};

export const fundSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    clearFunds: (state) => {
      state.guidanceFunds = [];
      state.funds = [];
      state.selectedFund = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFundsForGuidance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFundsForGuidance.fulfilled, (state, action) => {
        state.guidanceFunds = action.payload;
        state.loading = false;
      })
      .addCase(getFundsForGuidance.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder
      .addCase(getAllFunds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFunds.fulfilled, (state, action) => {
        state.funds = action.payload.funds;
        state.totalPages = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getAllFunds.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder
      .addCase(getFund.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFund.fulfilled, (state, action) => {
        state.selectedFund = action.payload;
        state.loading = false;
      })
      .addCase(getFund.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    builder
      .addCase(createFund.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFund.fulfilled, (state, action) => {
        state.funds.push(action.payload);
        state.loading = false;
      })
      .addCase(createFund.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const { clearFunds } = fundSlice.actions;
export default fundSlice.reducer;
