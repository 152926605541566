import { useDispatch, useSelector } from 'react-redux';
import ReportProgress from '../../ui/reportProgress.js';
import {
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { executeClientDataReport } from '../../../features/account/account.slice.js';
import { useEffect } from 'react';
import { getAllJobsByCategory } from '../../../features/job/job.slice.js';
import { jobService } from '../../../features/job/job.service.js';

const GenerateClientDataReport = () => {
  const dispatch = useDispatch();
  const jobId = useSelector((state) => state.account.clientDataReport?.jobId);
  const allJobs = useSelector((state) => state.job.jobs);

  useEffect(() => {
    dispatch(getAllJobsByCategory('client-report'));
  }, [dispatch]);

  const generateClientDataReport = () => {
    dispatch(executeClientDataReport());
  };

  const handleDownload = (job) => {
    dispatch(jobService.downloadJobFile(job._id, job.fileName));
  };

  return (
    <div>
      <h1>Generate Client Data Report</h1>
      <Button variant="contained" color="primary" onClick={generateClientDataReport}>
        Generate Report
      </Button>
      <ReportProgress jobId={jobId} type={'job'} />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Progress</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allJobs &&
            allJobs.map((job, index) => (
              <TableRow>
                <TableCell>{job.jobId}</TableCell>
                <TableCell>{job.createdAt}</TableCell>
                <TableCell>
                  <LinearProgress
                    variant="determinate"
                    color="accent"
                    value={job.progress}
                    style={{ width: '80%' }}
                  />
                  <Typography variant="caption">{job.progress}%</Typography>
                </TableCell>
                <TableCell>
                  {job.fileName && (
                    <Button variant="contained" onClick={() => handleDownload(job)}>
                      Download
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GenerateClientDataReport;
