import { Switch as MuiSwitch, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import React, { forwardRef } from 'react';

const Switch = ({ control, name, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={(props) => (
            <MuiSwitch
              {...props}
              checked={props.value || false}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export default Switch;
