import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import Logo from '../../resources/images/logos/FullColourLogo.png';
import WomanWithTablet from '../../resources/images/stock/WomanWithTablet.jpg';

//Center login Box on screen with background image.
const AuthLayout = (props) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        minHeight: '100vh',
        backgroundImage: `url(${WomanWithTablet})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{ minHeight: '100vh', backgroundColor: theme.palette.background.backdrop }}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          component="img"
          sx={{
            height: 80
          }}
          alt="Fairtree"
          src={Logo}
        />

        <Box width={'100%'}>{props.children}</Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;

//props.children;
