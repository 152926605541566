import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../redux/actions/auth.actions';
import Logo from '../components/ui/horizontalLogoWithSlogan';
import Layout from './layout';

import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Fragment } from 'react';
import { Snackbar } from '@mui/material';

const VerifyRoute = (props) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  return (
    <Fragment>
      {user ? (
        user.otpVerified ? (
          <Navigate to={'/home'} />
        ) : (
          <Layout>
            <Outlet />
          </Layout>
        )
      ) : (
        <Navigate to={'/signin'} />
      )}
    </Fragment>
  );
};

export default VerifyRoute;
