import { roleConstants } from "../constants";
import { roleService } from "../services";
import { alertActions } from "./";

export const roleActions = {
  getAll,
  getById,
  create,
  update,
  remove,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return roleService.getAll().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: roleConstants.GET_ALL_REQUEST };
  }
  function success(result) {
    return { type: roleConstants.GET_ALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: roleConstants.GET_ALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return roleService.getById(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: roleConstants.GET_BYID_REQUEST };
  }
  function success(result) {
    return { type: roleConstants.GET_BYID_SUCCESS, result };
  }
  function failure(error) {
    return { type: roleConstants.GET_BYID_FAILURE, error };
  }
}

function create(newRole) {
  return (dispatch) => {
    dispatch(request());

    return roleService.create(newRole).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: roleConstants.CREATE_REQUEST };
  }
  function success(result) {
    return { type: roleConstants.CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: roleConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return roleService.update(id, data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: roleConstants.UPDATE_REQUEST };
  }
  function success(result) {
    return { type: roleConstants.UPDATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: roleConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return roleService.remove(id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: roleConstants.DELETE_REQUEST };
  }
  function success(result) {
    return { type: roleConstants.DELETE_SUCCESS, result };
  }
  function failure(error) {
    return { type: roleConstants.UPDATE_FAILURE, error };
  }
}
