import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { communicationActions } from '../../../../redux/actions/communication.actions';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  MenuItem,
  DialogActions
} from '@mui/material';

import { Box } from '@mui/system';

import PrimaryButton from '../../../ui/buttons/primaryButton';

import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../../ui/forms';
import Input from '../../../ui/forms/fields/input';
import ListDialog from './listDialog';
import PrimarySelect from '../../../ui/forms/fields/select';
import OrangeCircularProgress from '../../../ui/controls/circularProgress';
// import FileDropzone from '../../../ui/forms/fields/fileDropzone';

const schema = yup.object().shape({
  name: yup.string().required('Please select a name for your email.'),
  sendAt: yup.date(),
  list: yup.string().required('Select a list you want to send the email to.'),
  subject: yup.string().required('Add a subject line.'),
  contentText: yup.string().required('Add some content to your email.'),
  attachments: yup.mixed()
});

function SingleSendDialog() {
  const [openSingle, setOpenSingle] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const { register, control, errors, handleSubmit, setValue } = methods;

  const dispatch = useDispatch();
  const lists = useSelector((state) => state.communication.lists);
  const loading = useSelector((state) => state.communication.loading);
  const saving = useSelector((state) => state.communication.saving);
  const success = useSelector((state) => state.communication.success);
  const list = useSelector((state) => state.communication.list);

  useEffect(() => {
    dispatch(communicationActions.getLists());
  }, [dispatch]);

  const handleOpen = () => {
    setOpenSingle(true);
  };

  const handleClose = () => {
    setOpenSingle(false);
  };

  const onSubmit = (data) => {
    dispatch(communicationActions.createEmail(data)).then((responce) => {
      if (success) setOpenSingle(false);
    });
  };

  const handleListCreate = () => {
    dispatch(communicationActions.getLists());

    if (list) {
      setValue('list', list._id);
    }
  };

  return (
    <Fragment>
      <Box>
        <PrimaryButton onClick={handleOpen}>Create new Email</PrimaryButton>
      </Box>

      <Dialog open={openSingle} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Create new Email</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormProvider {...methods}>
            <Form>
              <Input
                ref={register}
                id="name"
                label="Email Name"
                name="name"
                autoFocus
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
              <Input
                ref={register}
                id="subject"
                label="Subject"
                name="subject"
                error={!!errors.subject}
                helperText={errors?.subject?.message}
              />
              <Input
                ref={register}
                id="contentText"
                label="Email Content"
                name="contentText"
                error={!!errors.contentText}
                helperText={errors?.contentText?.message}
                multiline
              />
              <Divider sx={{ my: 3 }} />
              <Typography variant="h5">
                Select or Create a list you want to send this email to.
              </Typography>

              {!loading && lists ? (
                <PrimarySelect
                  id="list"
                  name="list"
                  label="Select a list you want to send a email to."
                  control={control}
                  error={errors?.list}>
                  {lists.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name} {`(${item.contacts.length})`}
                    </MenuItem>
                  ))}
                </PrimarySelect>
              ) : loading ? (
                <OrangeCircularProgress caption="Loading Lists" />
              ) : (
                <Typography>No lists available.</Typography>
              )}

              <ListDialog onListCreate={handleListCreate} />
              {/* <FileDropzone name={"attachments"} multiple /> */}
            </Form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {saving ? (
            <OrangeCircularProgress />
          ) : (
            <Fragment>
              <PrimaryButton type="button" variant="outlined" onClick={handleClose}>
                Close
              </PrimaryButton>
              <PrimaryButton type="button" onClick={() => handleSubmit(onSubmit)()}>
                Save
              </PrimaryButton>
              <PrimaryButton type="button">Save and Send</PrimaryButton>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SingleSendDialog;
