export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const numberToCurrency = (number, decimals = 2) => {
  return new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: decimals,
  }).format(number);
};

/** Sort array by Order field
 * @items - Give array containing order field.
 */
export const sortArray = (items) => {
  if (!items) {
    return [];
  }

  items.sort(function (a, b) {
    return a.order - b.order;
  });

  return items;
};

export const objectToArray = (item) => {
  let obj_array = [];

  const propertyNames = Object.keys(item);
  const propertyValues = Object.values(item);

  for (let i = 0; i < propertyNames.length; i++) {
    const key = propertyNames[i];
    const value = propertyValues[i];

    obj_array.push({ key, value });
  }

  return obj_array;
};

export const findInArray = (objectKey, myArray) => {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i][objectKey] === objectKey) {
      return myArray[i];
    }
  }
};
