import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import CircularProgress from '../ui/controls/circularProgress';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { Box } from '@mui/system';
import { addCode, getCodes, getProviders, removeCode } from '../../features/advisor/advisor.slice';
import { useTitle } from '../../hooks/titleProvider';

function BrokerCodes() {
  const dispatch = useDispatch();
  const [openAdd, setOpenAdd] = useState(false);
  const advisorCodes = useSelector((state) => state.advisor.codes);
  const providerOptions = useSelector((state) => state.advisor.providers);

  const { setTitle } = useTitle();
  setTitle(`My Clients`);

  const addingCode = useSelector((state) => state.advisor.addingCode);
  const loadingCodes = useSelector((state) => state.advisor.loadingCodes);

  const schema = yup.object().shape({
    code: yup.string().required('Please enter a code'),
    provider: yup.string().required('Please select a provider')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(getCodes());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addCode(data))
      .unwrap()
      .then(() => {
        setOpenAdd(false);
      });
  };

  const handleRemoveCode = (code) => {
    dispatch(removeCode(code));
  };

  const handleAddCode = () => {
    dispatch(getProviders());
    setOpenAdd(true);
  };

  return loadingCodes ? (
    <CircularProgress caption="Loading your codes..." />
  ) : (
    <>
      <Paper sx={{ pl: 3, pr: 3, pb: 3 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Typography variant="h5" component="h1" gutterBottom>
            Broker Codes
          </Typography>
          <PrimaryButton onClick={() => handleAddCode()}>Add Code</PrimaryButton>
        </Box>

        {advisorCodes && advisorCodes.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advisorCodes.map((code) => (
                  <TableRow key={code.code}>
                    <TableCell>{code.code}</TableCell>
                    <TableCell>{code.provider.name}</TableCell>

                    <TableCell>
                      <Box display={'flex'}>
                        <PrimaryButton onClick={() => handleRemoveCode(code.code)}>
                          Remove
                        </PrimaryButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6">No Codes Found</Typography>
        )}
      </Paper>

      <Dialog open={openAdd} onClose={() => setOpenAdd(false)}>
        <DialogTitle>Add Code</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input label="Code" name="code" autoComplete="off" {...register('code')} />

            <Select
              control={control}
              name="provider"
              label="Provider"
              error={errors.provider}
              helperText={errors.provider?.message || 'Please select a service provider / MANCO.'}>
              {providerOptions &&
                providerOptions.map((sp) => (
                  <MenuItem key={sp._id} value={sp._id}>
                    {sp.name}
                  </MenuItem>
                ))}
            </Select>

            <DialogActions>
              {addingCode ? (
                <CircularProgress caption="Adding code..." />
              ) : (
                <PrimaryButton type="submit"> {'Add Code'}</PrimaryButton>
              )}
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BrokerCodes;
