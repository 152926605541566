export const serviceProviderConstants = {
    SERVICEPROVIDERS_REQUEST: 'GET_SERVICEPROVIDERS_REQUEST',
    SERVICEPROVIDERS_SUCCESS: 'GET_SERVICEPROVIDERS_SUCCESS',
    SERVICEPROVIDERS_FAILURE: 'GET_SERVICEPROVIDERS_FAILURE',
    
    UPDATE_SERVICEPROVIDER_REQUEST: 'UPDATE_SERVICEPROVIDER_REQUEST',
    UPDATE_SERVICEPROVIDER_SUCCESS: 'UPDATE_SERVICEPROVIDER_SUCCESS',
    UPDATE_SERVICEPROVIDER_FAILURE: 'UPDATE_SERVICEPROVIDER_FAILURE',

    RESET_SERVICEPROVIDER_REQUEST: 'RESET_SERVICEPROVIDER_REQUEST',
    RESET_SERVICEPROVIDER_SUCCESS: 'RESET_SERVICEPROVIDER_SUCCESS',
    RESET_SERVICEPROVIDER_FAILURE: 'RESET_SERVICEPROVIDER_FAILURE',
};