export const entityConstants = {
  GET_ALL_REQUEST: "GET_ALL_ENTITY_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_ENTITY_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_ENTITY_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_ENTITY_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_ENTITY_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_ENTITY_FAILURE",

  CREATE_REQUEST: "CREATE_ENTITY_REQUEST",
  CREATE_SUCCESS: "CREATE_ENTITY_SUCCESS",
  CREATE_FAILURE: "CREATE_ENTITY_FAILURE",

  UPDATE_REQUEST: "UPDATE_ENTITY_REQUEST",
  UPDATE_SUCCESS: "UPDATE_ENTITY_SUCCESS",
  UPDATE_FAILURE: "UPDATE_ENTITY_FAILURE",

  DELETE_REQUEST: "DELETE_ENTITY_REQUEST",
  DELETE_SUCCESS: "DELETE_ENTITY_SUCCESS",
  DELETE_FAILURE: "DELETE_ENTITY_FAILURE",
};
