//Create Client service for advisorProfile
import { config } from '../config';
import { authHeader } from '../helpers';

import axiosInstance from '../axiosInstance';

export const advisorProfileService = {
  getByUserId,
  getByProviderId,
  getIFACodes,
  register,
  update,
  remove
};

function getByUserId(userId) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.advisorProfile}/user/${userId}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function getByProviderId(providerId) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.advisorProfile}/provider/${providerId}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function getIFACodes() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.advisorProfile}/ifacodes`, requestOptions).then((response) => {
    return response.data;
  });
}

function register(advisorProfile) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  console.log('register advisorProfile', advisorProfile);

  return axiosInstance
    .post(`${config.advisorProfile}/`, advisorProfile, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function update(advisorProfile) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.advisorProfile}/${advisorProfile._id}`, advisorProfile, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function remove(advisorProfileId) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.advisorProfile}/${advisorProfileId}`, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
