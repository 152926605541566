import React, { useEffect, useState, useMemo } from 'react';

const TitleContext = React.createContext();
export const useTitle = () => React.useContext(TitleContext);

const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('Home');

  useEffect(() => {
    document.title = `${title} - Fairtree Portal`;
  }, [title]);

  const contextValue = useMemo(() => ({ title, setTitle }), [title]);

  return <TitleContext.Provider value={contextValue}>{children}</TitleContext.Provider>;
};

export default TitleProvider;
