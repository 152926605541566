import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrimaryButton from '../ui/buttons/primaryButton';
import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import { Button, Divider, Typography } from '@mui/material';
import OrangeCircularProgress from '../ui/controls/circularProgress';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearOTP,
  sendClientVerificationOTP,
  verifyClientVerificationOTP
} from '../../features/auth/auth.slice';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RoundedButton from '../ui/buttons/roundedButton';
import OTPInput from '../ui/forms/fields/otpInput';
import { useTitle } from '../../hooks/titleProvider';

const schema = yup.object().shape({
  otp: yup.string().required('This is required')
});

const OTPForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  setTitle('ID Verification.');

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  useEffect(() => {
    return () => {
      dispatch(clearOTP());
    };
  }, [dispatch]);

  const [preferedOtpMethod, setPreferedOtpMethod] = useState('female');

  const otpSuccess = useSelector((state) => state.auth.otp?.success);
  const loading = useSelector((state) => state.auth.userInfoLoading);
  const otpLoading = useSelector((state) => state.auth.otpLoading);

  const onSubmit = (data) => {
    data.idNumber = props.idNumber;
    data.entityType = props.entityType;

    dispatch(verifyClientVerificationOTP(data))
      .unwrap()
      .then((result) => {
        navigate('/home');
      });
  };

  const handleMethodChange = (event) => {
    setPreferedOtpMethod(event.target.value);
  };

  const onSendOTP = () => {
    dispatch(sendClientVerificationOTP({ preferedOtpMethod }));
  };

  return !loading ? (
    !otpSuccess ? (
      <>
        <Typography variant="h5" gutterBottom textAlign={'center'}>
          {'Select how you want to receive the one-time-pin'}
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <FormControl>
          <RadioGroup
            aria-label="method"
            name="method"
            value={preferedOtpMethod}
            onChange={handleMethodChange}
            style={{ m: 2, mt: 3 }}>
            {props.phoneNumber ? (
              <FormControlLabel
                value="phone"
                control={<Radio color="primary" />}
                label={`SMS OTP to mobile number ending in ${props.phoneNumber}`}
              />
            ) : null}

            {props.email ? (
              <FormControlLabel
                value="email"
                control={<Radio color="primary" />}
                label={`Email OTP to ${props.email}`}
              />
            ) : null}
          </RadioGroup>
        </FormControl>
        {!otpLoading ? (
          <PrimaryButton fullWidth color="primary" onClick={onSendOTP}>
            Send OTP
          </PrimaryButton>
        ) : (
          <OrangeCircularProgress />
        )}
      </>
    ) : (
      <>
        <Form>
          <Typography variant="h5" gutterBottom textAlign={'center'}>
            {props.caption
              ? props.caption
              : 'Please keep an eye out for the one-time-pin in your email inbox.'}
          </Typography>

          <OTPInput name="otp" label="OTP" control={control} />
        </Form>
        {!otpLoading ? (
          <PrimaryButton onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
        ) : (
          <OrangeCircularProgress />
        )}
      </>
    )
  ) : (
    <OrangeCircularProgress />
  );
};

export default OTPForm;
