import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const taxFreeDataService = {
  getTaxFreeDataForGuidance
};

function getTaxFreeDataForGuidance() {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.taxfreedata_server}/all`, requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
