import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { deleteClient } from '../../../features/user/user.slice';
import ConnectAccountDialog from '../dialogs/connectAccountDialog';
import PrimaryButton from '../../ui/buttons/primaryButton';
import DangerButton from '../../ui/buttons/dangerButton';
import { useNavigate } from 'react-router-dom';

const ClientAccountCard = () => {
  const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDisconnectOpen = (id) => {
    setOpenDisconnectDialog(true);
    setSelectedClient(id);
  };

  const handleDisconnectClose = () => {
    setOpenDisconnectDialog(false);
    setSelectedClient('');
  };

  const confirmDisconnect = () => {
    dispatch(deleteClient(selectedClient))
      .unwrap()
      .then(() => {
        navigate('/home');
      });

    setSelectedClient('');
    setOpenDisconnectDialog(false);
  };

  const connectAccountDone = () => {
    //Refresh connect status.
  };

  return (
    <>
      {user.client && user.client.length > 0 ? (
        user.client.map((client, index) => (
          <Fragment>
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography mt={1}>{`${client.firstName} ${client.lastName}`}</Typography>
              <DangerButton
                size="small"
                variant="outlined"
                onClick={() => handleDisconnectOpen(client._id)}>
                Disconnect Account
              </DangerButton>
            </Box>
            <Divider flexItem />
          </Fragment>
        ))
      ) : (
        <ConnectAccountDialog done={() => connectAccountDone()} />
      )}

      <Dialog open={openDisconnectDialog} onClose={handleDisconnectClose}>
        <DialogTitle>Disconnect Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disconnect your account from your profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton variant="text" onClick={handleDisconnectClose}>
            No
          </PrimaryButton>
          <PrimaryButton variant="text" onClick={confirmDisconnect}>
            Yes
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientAccountCard;
