import { authConstants } from '../constants';
import { authService } from '../services';
import { alertActions, accountActions } from './';
import { history } from '../helpers';

export const authActions = {
  auth,
  login,
  logout,
  register,
  verifyId,
  sendOTP,
  verifyOTP,
  forgotPassword,
  updatePassword,
  sendEmailVerification,
  verifyEmailAddress
};

function auth() {
  return (dispatch) => {
    dispatch(request());

    return authService.auth().then(
      (auth_details) => {
        dispatch(success(auth_details));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function login(credentials) {
  return (dispatch) => {
    dispatch(request(credentials));
    dispatch(auth_request(credentials));

    authService.login(credentials).then(
      (user) => {
        authService.auth().then((auth_details) => {
          dispatch(success(auth_details));
          history.push('/balances');
        });
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }

  function auth_request(user) {
    return { type: authConstants.AUTH_REQUEST, user };
  }
  function auth_success(user) {
    return { type: authConstants.AUTH_SUCCESS, user };
  }
  function auth_failure(error) {
    return { type: authConstants.AUTH_FAILURE, error };
  }
}

function logout() {
  authService.logout();
  history.push('/signin');
  return { type: authConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    authService.register(user).then(
      (user) => {
        authService.auth().then((auth_details) => {
          dispatch(success(auth_details));
          history.push('/balances');
        });
        //dispatch(success(user));
        dispatch(alertActions.success('Signed up successfully.'));
        history.push('/balances');
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(user) {
    return { type: authConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.REGISTER_FAILURE, error };
  }
}

function sendEmailVerification() {
  return (dispatch) => {
    dispatch(request());

    authService.sendEmailVerification().then(
      (result) => {
        dispatch(success(result));
        dispatch(alertActions.success('Verification email was sent, please check your inbox.'));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: authConstants.SEND_ENAILVERIFICATION_REQUEST };
  }
  function success(result) {
    return { type: authConstants.SEND_ENAILVERIFICATION_SUCCESS, result };
  }
  function failure(error) {
    return { type: authConstants.SEND_ENAILVERIFICATION_FAILURE, error };
  }
}

function verifyEmailAddress(token) {
  return (dispatch) => {
    dispatch(request(token));

    authService.verifyEmailAddress(token).then(
      (result) => {
        dispatch(success(result));
        dispatch(alertActions.success('Email verified, thank you.'));
      },
      (error) => {
        console.log(error);
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(token) {
    return { type: authConstants.SENDOTP_REQUEST, token };
  }
  function success(result) {
    return { type: authConstants.SENDOTP_SUCCESS, result };
  }
  function failure(error) {
    return { type: authConstants.SENDOTP_FAILURE, error };
  }
}

function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request(email));

    authService.forgotPassword(email).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success('Recovery email sent to your inbox.'));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(email) {
    return { type: authConstants.FORGOT_PASSWORD_REQUEST, email };
  }
  function success(user) {
    return { type: authConstants.FORGOT_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function updatePassword(data) {
  return (dispatch) => {
    dispatch(request());

    authService.updatePassword(data).then(
      (payload) => {
        dispatch(success(payload));
        dispatch(alertActions.success('Password was updated.'));
      },
      (error) => {
        const err = error.response.data;
        console.log(error.response);
        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: authConstants.UPDATE_PASSWORD_REQUEST };
  }
  function success(payload) {
    return { type: authConstants.UPDATE_PASSWORD_SUCCESS, payload };
  }
  function failure(error) {
    return { type: authConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}

function verifyId(data) {
  return (dispatch) => {
    dispatch(request(data));

    authService.verifyId(data).then(
      (userInfo) => {
        dispatch(success(userInfo));
        dispatch(alertActions.success('Please confirm your details'));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(data) {
    return { type: authConstants.VERIFYID_REQUEST, data };
  }
  function success(userInfo) {
    return { type: authConstants.VERIFYID_SUCCESS, userInfo };
  }
  function failure(error) {
    return { type: authConstants.VERIFYID_FAILURE, error };
  }
}

function sendOTP(data) {
  return (dispatch) => {
    dispatch(request(data));

    authService.sendOTP(data).then(
      (otp) => {
        dispatch(success(otp));
        dispatch(alertActions.success('OTP was sent.'));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(data) {
    return { type: authConstants.SENDOTP_REQUEST, data };
  }
  function success(otp) {
    return { type: authConstants.SENDOTP_SUCCESS, otp };
  }
  function failure(error) {
    return { type: authConstants.SENDOTP_FAILURE, error };
  }
}

function verifyOTP(data) {
  return (dispatch) => {
    dispatch(request(data));

    authService.verifyOTP(data).then(
      (otp) => {
        dispatch(success(otp));
        dispatch(alertActions.success('Your are verifeid successfully.'));
        dispatch(auth()).then((responce) => {
          dispatch(accountActions.clear());
          history.push('/');
        });
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request(data) {
    return { type: authConstants.VERIFYOTP_REQUEST, data };
  }
  function success(otp) {
    return { type: authConstants.VERIFYOTP_SUCCESS, otp };
  }
  function failure(error) {
    return { type: authConstants.VERIFYOTP_FAILURE, error };
  }
}
